import { string, array, object, number, boolean } from 'yup';
import { OPERATORS } from './utils';
import { factFamilyVariations } from '../../../../../constants';

export const validationSchema = {
  textAudio: string().notRequired(),
  textAudioKey: string().notRequired(),
  variation: string()
    .oneOf(Object.values(factFamilyVariations.variations), 'required field')
    .required('required field'),
  options: array().of(
    object().shape({
      id: number().required(),
      operator: string().test(
        'operator-required',
        'required field',
        function (value) {
          // Access parent variation through this.parent
          const parentObject =
            (this.from && this.from[1] && this.from[1].value) || {};
          const variation = parentObject.variation;
          const indexMatch = this.path.match(/\[(\d+)\]/);
          const index = indexMatch ? parseInt(indexMatch[1], 10) : -1;

          switch (variation) {
            case factFamilyVariations.variations.PYRAMID:
              if (index === 0) return true;
              return Object.values(OPERATORS).includes(value);

            default:
              if (value.toLowerCase() === 'of') return true; // Allow 'of' for the with first question variation
              return Object.values(OPERATORS).includes(value);
          }
        }
      ),
      numbers: array().of(
        object().shape({
          id: number().required(),
          option: string().required('required field'),
          showValue: boolean().required('required field'),
        })
      ),
    })
  ),
};
