import { useRef } from 'react';
import { BasicInput } from '../../../../../components/Inputs';
import * as T from '../../../../../components/Typography';
import { MediaKeyInput } from '../../../../components/MediaKeyInput';
import { mediaInputLabels, mediaTypes } from '../../../../../constants';
import { useStepForm } from 'CMS/Providers/StepFormProvider';
import MDEditor from '../../../../../components/MDEditor';

export { validationSchema } from './validation';

const initialData = {
  textAudio: '',
  textAudioKey: '',
  question: '',
  questionAudioKey: '',
  rowsLabels: '',
  colsLabels: '',
  colsLabelsValues: [],
};

const BarChart = () => {
  const initialRef = useRef(initialData);
  const { state, updateDataFields, updateFormFields, updateFormMetaData } =
    useStepForm(initialRef.current);
  const { data = initialData, form, validationErrs } = state;

  const handleColsChange = (colsLabels) => {
    const colsLength = colsLabels.split(',').length;
    if (colsLength < 1 || colsLength > 5) {
      updateFormMetaData({
        validationErrs: {
          ...validationErrs,
          colsLabels: 'Cols labels must be between 1 and 5',
        },
      });
      updateDataFields({ colsLabels });

      return;
    }

    const newColsLabelsValues = data.colsLabelsValues.slice(0, colsLength);

    updateDataFields({ colsLabels, colsLabelsValues: newColsLabelsValues });
    updateFormMetaData({
      validationErrs: {
        ...validationErrs,
        colsLabels: '',
      },
    });
  };

  const handleRowsChange = (rowsLabels) => {
    const rowsLength = rowsLabels.split(',').length;
    if (rowsLength < 1 || rowsLength > 9) {
      updateFormMetaData({
        validationErrs: {
          ...validationErrs,
          rowsLabels: 'Rows labels must be between 1 and 9',
        },
      });
      updateDataFields({ rowsLabels });

      return;
    }

    updateDataFields({ rowsLabels });
    updateFormMetaData({
      validationErrs: {
        ...validationErrs,
        rowsLabels: '',
      },
    });
  };

  const handleUpdateColsLabelsValues = (value, index) => {
    const newColsLabelsValues = [...data.colsLabelsValues];
    newColsLabelsValues[index] = Number(value);
    updateDataFields({ colsLabelsValues: newColsLabelsValues });
  };

  return (
    <div>
      <MDEditor
        label="Explainer Text"
        value={data.textAudio}
        onChange={(textAudio) => updateDataFields({ textAudio })}
        mode={'edit'}
        height={80}
        m={{ mt: 6 }}
        error={validationErrs?.textAudio}
      />

      <MediaKeyInput
        type={mediaTypes.TEXT_AUDIO}
        label="Explainer Text file Key"
        value={data.textAudioKey}
        handleChange={(textAudioKey) => updateDataFields({ textAudioKey })}
        m={{ mt: 5 }}
        error={validationErrs?.textAudioKey}
      />

      <BasicInput
        label="Question"
        value={data.question}
        handleChange={(question) => updateDataFields({ question })}
        m={{ mt: 8 }}
        error={validationErrs?.question}
      />

      <MediaKeyInput
        type={mediaTypes.QUESTION_AUDIO}
        label={`Question file Key`}
        value={data.questionAudioKey}
        handleChange={(questionAudioKey) =>
          updateDataFields({ questionAudioKey })
        }
        m={{ mt: 5, mb: 4 }}
        error={validationErrs?.questionAudioKey}
      />

      <MediaKeyInput
        label={mediaInputLabels.IMAGE_LABEL}
        type={mediaTypes.IMAGE}
        value={form.imageKey}
        handleChange={(imageKey) => updateFormFields({ imageKey })}
        m={{ mb: 5, mt: 8 }}
        error={validationErrs?.imageKey}
      />

      <MDEditor
        label="Rows Labels"
        value={data.rowsLabels}
        onChange={(rowsLabels) => handleRowsChange(rowsLabels)}
        mode={'edit'}
        height={80}
        m={{ mt: 6 }}
        error={validationErrs?.rowsLabels}
        helper="Add the rows separated by commas i.e row1, row2, row3"
      />

      <MDEditor
        label="Cols Labels"
        value={data.colsLabels}
        onChange={(colsLabels) => handleColsChange(colsLabels)}
        mode={'edit'}
        height={80}
        m={{ mt: 6 }}
        error={validationErrs?.colsLabels}
        helper="Add the cols separated by commas i.e row1, row2, row3"
      />

      <T.P mt={6} mb={4} size="regular" weight="bold">
        Provide values for each column label, the values should not be more than
        the length of the rows
      </T.P>

      {data?.colsLabels?.split(',').map((label, index) => (
        <BasicInput
          placeholder=""
          value={data.colsLabelsValues?.[index]}
          handleChange={(value) => handleUpdateColsLabelsValues(value, index)}
          bgColor={'neutralSurface'}
          error={validationErrs?.colsLabelsValues?.[index]?.value}
          hideErrorMsg
          textAlign="center"
          label={label}
          type="number"
          m={{ mt: 4 }}
        />
      ))}
    </div>
  );
};

export default BarChart;
