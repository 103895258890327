const hotJarInit = () => {
  try {
    // hotjar
    if (window.location.protocol === 'https:') {
      const hjid = '3101082';
      const h = window;
      const o = document;
      const t = 'https://static.hotjar.com/c/hotjar-';
      const j = '.js?sv=';
      h.hj =
        h.hj ||
        function () {
          (h.hj.q = h.hj.q || []).push(arguments);
        };
      h._hjSettings = { hjid, hjsv: 6 };
      const a = o.getElementsByTagName('head')[0];
      const r = o.createElement('script');
      r.async = 1;
      r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
      a.appendChild(r);
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Hotjar failed to load:', e);
  }
};

export default hotJarInit;
