import { useEffect, useState } from 'react';

import * as T from '../../../../components/Typography';
import { PlayButton } from '../../../../components/Button';
import { useLocation } from 'react-router-dom';
import StepFooterButtons from '../StepFooterButtons';

import * as SharedStyles from '../shared/style';
import * as S from './style';

const FillGaps = ({
  data: { textAudio, textAudioUrl, inputs: originalInputs = [] } = {},
  imageUrl,
  handleNext,
  stuckAtReview,
  disabled,
  preview,
}) => {
  const [enableNext, setEnableNext] = useState(false);
  const [inputs, setInputs] = useState(
    originalInputs?.map((a) => ({ ...a, value: a.showValue ? a.value : null }))
  );

  const location = useLocation();
  useEffect(() => {
    setEnableNext(false);
  }, [location]);

  const onInputChange = ({ value, id }) => {
    const newInputs = inputs?.map((e) => {
      if (e.id !== id) return e;
      return {
        ...e,
        value,
      };
    });
    setInputs(newInputs);
  };

  useEffect(() => {
    if (inputs?.length) {
      const allInputsHaveCorrectAnswers = inputs?.every(
        (input, index) =>
          Number(input.value) === Number(originalInputs[index].value)
      );
      setEnableNext(allInputsHaveCorrectAnswers);
    }
  }, [inputs, originalInputs]);

  return (
    <SharedStyles.Wrapper>
      <SharedStyles.ContentWrapper>
        {!!textAudio && (
          <SharedStyles.TextAudioWrapper mt="2" mb="4">
            {textAudioUrl ? (
              <>
                <PlayButton
                  audioUrl={textAudioUrl}
                  width="56px"
                  height="56px"
                  iconProps={{ width: '32px', height: '32px' }}
                  fallbackText={textAudio}
                />
                <T.P ml={4} weight="regular" size="med">
                  {textAudio}
                </T.P>
              </>
            ) : (
              <T.P weight="regular" size="regular">
                {textAudio}
              </T.P>
            )}
          </SharedStyles.TextAudioWrapper>
        )}

        {imageUrl ? (
          <SharedStyles.ImageWrapper>
            <SharedStyles.Img src={imageUrl} alt={textAudio} />
          </SharedStyles.ImageWrapper>
        ) : null}

        <S.InputsWrapper>
          {inputs.map((input, index) => (
            <SharedStyles.TypeInputBox
              key={input.id}
              id={`answer-${input.id}`}
              name={`answer-${input.id}`}
              type="number"
              aria-labelledby={`answer-${input.id}`}
              showValue={input.showValue}
              value={input.value}
              disabled={input.showValue}
              onChange={(e) => {
                onInputChange({ value: e.target.value, id: input.id });
              }}
              isCorrect={
                Number(input.value) === Number(originalInputs[index].value)
              }
              h="60px"
            />
          ))}
        </S.InputsWrapper>
      </SharedStyles.ContentWrapper>

      <StepFooterButtons
        stuckAtReview={stuckAtReview}
        handleNext={handleNext}
        preview={preview}
        disabled={disabled}
        enableNext={enableNext}
      />
    </SharedStyles.Wrapper>
  );
};

export default FillGaps;
