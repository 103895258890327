import { SiteSettings } from 'api-calls';
import { courseCodeTypes, coursesNames } from '../constants';
import { createContext, useContext } from 'react';
import makeError from 'api-calls/format-error';

const initialState = {
  courseCode: courseCodeTypes.TPD,
  anotherCourseCode: courseCodeTypes.CMID,
  courseName: coursesNames.TPD,
  anotherCourseName: coursesNames.CMID,
  isTpdCourse: true,
  isCmidCourse: false,
  isWorkingInPrisonEnvironment: false,
  loaded: false,
};
const SiteSettingsContext = createContext({
  ...initialState,
});

const SiteSettingsProvider = (props) => {
  const { data, error, isError } = SiteSettings.useGetSiteSettings();

  const value = data
    ? {
        ...data,
        loaded: true,
        anotherCourseCode:
          data.courseCode === courseCodeTypes.TPD
            ? courseCodeTypes.CMID
            : courseCodeTypes.TPD,
        courseName:
          data.courseCode === courseCodeTypes.TPD
            ? coursesNames.TPD
            : coursesNames.CMID,
        anotherCourseName:
          data.courseCode === courseCodeTypes.TPD
            ? coursesNames.CMID
            : coursesNames.TPD,
        isCmidCourse: data.courseCode === courseCodeTypes.CMID,
        isTpdCourse: data.courseCode === courseCodeTypes.TPD,
      }
    : isError
    ? {
        ...initialState,
        error: makeError(error),
        isError,
      }
    : {
        ...initialState,
        loaded: false,
      };

  return <SiteSettingsContext.Provider value={value} {...props} />;
};

const useSiteSettings = () => {
  const value = useContext(SiteSettingsContext);
  return value;
};

export { SiteSettingsProvider, useSiteSettings };
export default SiteSettingsContext;
