import * as S from './style';
import Icon from '../Icon';
import * as T from '../Typography';
import badges from '../../constants/badges';
import Large from './Large';

const Badge = ({ type, levels, large, numberOfOccurrences = 1, ...props }) => {
  if (!type) {
    // eslint-disable-next-line no-console
    console.error('type prop is required ');
    return;
  }
  const color = badges[type]?.color;
  const borderColor = badges[type]?.borderColor;
  const iconColor = badges[type]?.iconColor;
  const icon = badges[type]?.icon;

  if (large) {
    return (
      <Large
        type={type}
        color={color}
        borderColor={borderColor}
        iconColor={iconColor}
        icon={icon}
        {...props}
      />
    );
  }

  return (
    <S.Wrapper>
      <S.IconWrapper
        color={color}
        borderColor={borderColor}
        type={type}
        {...props}
      >
        <Icon
          icon={icon}
          color={iconColor}
          width="100%"
          height="100%"
          numberOfOccurrences={numberOfOccurrences}
        />
      </S.IconWrapper>
      <S.TextWrapper>
        <T.P color="neutralMain" weight="bold">
          {badges[type]?.text}{' '}
        </T.P>

        {levels && (
          <S.LevelsWrapper>
            {levels.map((levelType) => (
              <T.Li color="neutralMain" ml="3">
                {badges[levelType]?.text}
              </T.Li>
            ))}
          </S.LevelsWrapper>
        )}
      </S.TextWrapper>
    </S.Wrapper>
  );
};

export default Badge;
