import { useStepForm } from 'CMS/Providers/StepFormProvider';
import { mediaTypes } from '../../constants';
import { useNavigate, useSearchParams } from 'react-router-dom';

const updateOptionOrAnswerMediaByIndex = ({
  options,
  index,
  mediaItem,
  mediaKey,
}) => {
  const updatedOptions = [...options];
  updatedOptions[index] = {
    ...updatedOptions[index],
    [mediaItem]: mediaKey,
  };
  return updatedOptions;
};

export const useUpdateStepMedia = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { state, updateFormFields, updateDataFields } = useStepForm();
  const prevPath = searchParams.get('prevPath');
  const mediaTypeFromParams = searchParams.get('type');
  const indexFromParams = searchParams.get('index');

  const updateStepMediaType = ({
    mediaType: _mediaType,
    mediaKey,
    index: _index,
  }) => {
    const mediaType = mediaTypeFromParams || _mediaType;
    const index = Number(indexFromParams ?? _index);
    const isValidIndex = !!index || index === 0;

    switch (mediaType) {
      case mediaTypes.AUDIO:
        updateFormFields({ audioKey: mediaKey });
        break;
      case mediaTypes.IMAGE:
        updateFormFields({ imageKey: mediaKey });
        break;
      case mediaTypes.TEXT_AUDIO:
        updateDataFields({ textAudioKey: mediaKey });
        break;
      case mediaTypes.QUESTION_AUDIO:
        if (!isValidIndex) {
          updateDataFields({ questionAudioKey: mediaKey });
        } else {
          const updatedQuestions = updateOptionOrAnswerMediaByIndex({
            options: state.data.questions,
            index,
            mediaItem: 'questionAudioKey',
            mediaKey,
          });
          updateDataFields({ questions: updatedQuestions });
        }
        break;
      case mediaTypes.ANSWER_IMAGE: {
        if (!isValidIndex)
          throw Error(
            `Index was not provided for type: ${mediaTypes.ANSWER_IMAGE}`
          );
        const updatedCorrectOptions = updateOptionOrAnswerMediaByIndex({
          options: state.data.correctOptions,
          index,
          mediaItem: 'imageKey',
          mediaKey,
        });
        updateDataFields({ correctOptions: updatedCorrectOptions });
        break;
      }
      case mediaTypes.ANSWER_AUDIO: {
        if (!isValidIndex)
          throw Error(
            `Index was not provided for type: ${mediaTypes.ANSWER_IMAGE}`
          );
        const updatedCorrectOptions = updateOptionOrAnswerMediaByIndex({
          options: state.data.correctOptions,
          index,
          mediaItem: 'audioKey',
          mediaKey,
        });
        updateDataFields({ correctOptions: updatedCorrectOptions });
        break;
      }
      case mediaTypes.OPTION_AUDIO: {
        if (!isValidIndex)
          throw Error(
            `Index was not provided for type: ${mediaTypes.OPTION_AUDIO}`
          );
        const updatedOptions = updateOptionOrAnswerMediaByIndex({
          options: state.data.options,
          index,
          mediaItem: 'audioKey',
          mediaKey,
        });
        updateDataFields({ options: updatedOptions });
        break;
      }
      case mediaTypes.MULTIPLE_WORDS_OPTION_AUDIO: {
        if (!isValidIndex)
          throw Error(
            `Index was not provided for type: ${mediaTypes.MULTIPLE_WORDS_OPTION_AUDIO}`
          );
        const updatedOptions = updateOptionOrAnswerMediaByIndex({
          options: state.data.multipleWords,
          index,
          mediaItem: 'audioKey',
          mediaKey,
        });
        updateDataFields({ multipleWords: updatedOptions });
        break;
      }
      case mediaTypes.OPTION_IMAGE: {
        if (!isValidIndex)
          throw Error(
            `Index was not provided for type: ${mediaTypes.OPTION_IMAGE}`
          );
        const updatedOptions = updateOptionOrAnswerMediaByIndex({
          options: state.data.options,
          index,
          mediaItem: 'imageKey',
          mediaKey,
        });
        updateDataFields({ options: updatedOptions });
        break;
      }
      default:
        throw Error(`Unsupported media type: ${mediaType}`);
    }

    navigate(prevPath, {
      state: { isRedirected: true },
    });
  };

  return { updateStepMediaType };
};
