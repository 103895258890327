import styled from '@emotion/styled';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem 3rem;
  max-width: 960px;
  margin: 0 auto;
`;

export const LogoWrapper = styled.div``;
