import styled from '@emotion/styled';
import TriangleSVG from 'components/Icon/icons/Triangle';

export const KeyWrapper = styled.div`
  padding: ${({ theme }) => `${theme.spacings[3]} ${theme.spacings[5]}`};
  background: ${({ theme }) => theme.colors.neutralLightNew};
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacings[2]};
`;

export const CirclesWrapper = styled.div`
  display: grid;
  grid-template-columns: ${({ numberOfShapes }) =>
    numberOfShapes <= 16
      ? 'repeat(4, 56px)'
      : numberOfShapes <= 30
      ? 'repeat(6, 48px)'
      : 'repeat(8, 32px)'};
  gap: 22px;
  justify-content: center;
  margin-top: ${({ theme }) => theme.spacings[4]};
`;

export const Circle = styled.div`
  width: ${({ w }) => w ?? '50px'};
  height: ${({ h }) => h ?? '50px'};
  border-radius: 50%;
  border: 1px solid #000;
  cursor: ${({ disabled }) => !disabled && 'pointer'};
  background: ${({ isSelected, theme }) =>
    isSelected ? theme.colors.primaryMain : 'unset'};
`;

export const Square = styled.div`
  width: ${({ w }) => w ?? '50px'};
  height: ${({ h }) => h ?? '50px'};
  border: 1px solid #000;
  cursor: ${({ disabled }) => !disabled && 'pointer'};
  background: ${({ isSelected, theme }) =>
    isSelected ? theme.colors.primaryMain : 'unset'};
`;

export const Triangle = styled(TriangleSVG)`
  width: ${({ w }) => w ?? '40px'};
  height: ${({ h }) => h ?? '35px'};
  cursor: ${({ disabled }) => !disabled && 'pointer'};
  fill: ${({ isSelected, theme }) =>
    isSelected ? theme.colors.primaryMain : 'transparent'};
`;

export const Divider = styled.div`
  width: 100%;
  border: 1px solid #000;
  margin: ${({ theme }) => theme.spacings[1]} 0;
`;

export const QuestionWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: ${({ flexDir }) => flexDir ?? 'row'};
  align-items: ${({ ai }) => ai ?? 'center'};
  justify-content: ${({ jc }) => jc ?? 'center'};
  gap: ${({ theme }) => theme.spacings[3]};
  margin-top: ${({ theme }) => theme.spacings[7]};
  margin-bottom: ${({ theme }) => theme.spacings[7]};
`;

export const FractionWrapper = styled.div`
  width: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const TallyOption = styled.button`
  width: ${({ grid }) => (grid ? '48%' : '100%')};
  padding: ${({ theme }) => `${theme.spacings[3]} ${theme.spacings[2]}`};
  overflow: hidden;
  background: none;
  border: none;
  border-radius: ${({ find }) => (find ? '16px' : '9px')};
  cursor: pointer;
  opacity: ${({ selected, option }) => {
    if (selected !== option) return 0.5;
    return 1;
  }};
  background-color: ${({ theme, selected, option }) => {
    if (selected === option) return theme.colors.neutralMain;
    return theme.colors.neutralLight;
  }};
  box-shadow: 0px 1px 3px -1px rgba(4, 13, 38, 0.1),
    0px 2px 5px -1px rgba(4, 13, 38, 0.11);
  margin-bottom: ${({ theme, mb }) => theme.spacings[mb || 0]};
`;
