import React, { useEffect, useState } from 'react';
import {
  Container,
  RowLabel,
  Cell,
  BottomLabelContainer,
  ColumnLabel,
} from './style';

const GridChartWithLabels = ({
  rowsLabels,
  colsLabels,
  originalColsLabelsValues = [],
  setEnableNext,
}) => {
  const rows = rowsLabels?.split(',')?.length;
  const cols = colsLabels?.split(',')?.length;

  const [grid, setGrid] = useState(
    Array.from({ length: rows }, () => Array(cols).fill(false))
  );
  const [dragState, setDragState] = useState({
    isDragging: false,
    isTogglingOn: null,
  });
  const [colValues, setColValues] = useState(Array(cols).fill(0));

  const handleMouseAction = (row, col, isMouseDown) => {
    if (isMouseDown) {
      const isCurrentlyHighlighted = grid[row][col];
      setDragState({ isDragging: true, isTogglingOn: !isCurrentlyHighlighted });
      toggleCellHighlight(row, col, !isCurrentlyHighlighted);
    } else if (dragState.isDragging) {
      toggleCellHighlight(row, col, dragState.isTogglingOn);
    }
  };

  const handleMouseUp = () => {
    setDragState({ isDragging: false, isTogglingOn: null });
  };

  const toggleCellHighlight = (row, col, newState) => {
    setGrid((prevGrid) => {
      const newGrid = prevGrid.map((r, rowIndex) =>
        r.map((cell, colIndex) =>
          rowIndex === row && colIndex === col ? newState : cell
        )
      );
      return newGrid;
    });
  };

  useEffect(() => {
    const calculateColValues = () => {
      const values = Array(cols).fill(0);
      grid.forEach((row) => {
        row.forEach((isHighlighted, colIndex) => {
          if (isHighlighted) {
            values[colIndex] += 1;
          }
        });
      });
      setColValues(values);
    };

    calculateColValues();
  }, [grid, cols]);

  useEffect(() => {
    const allCorrect = colValues?.every((val, index) => {
      return val === originalColsLabelsValues[index];
    });

    if (allCorrect) {
      setEnableNext(true);
    }
  }, [colValues, originalColsLabelsValues, setEnableNext]);

  return (
    <Container cols={cols} rows={rows} onMouseUp={handleMouseUp}>
      {grid.map((row, rowIndex) => (
        <React.Fragment key={rowIndex}>
          <RowLabel>{rowsLabels?.split(',').reverse()?.[rowIndex]}</RowLabel>
          {row.map((isHighlighted, colIndex) => (
            <Cell
              key={`${rowIndex}-${colIndex}`}
              isHighlighted={isHighlighted}
              onMouseDown={() => handleMouseAction(rowIndex, colIndex, true)}
              onMouseEnter={() => handleMouseAction(rowIndex, colIndex, false)}
            ></Cell>
          ))}
        </React.Fragment>
      ))}
      <BottomLabelContainer cols={cols}>
        {colsLabels?.split(',').map((label, index) => (
          <ColumnLabel key={`col-label-${index}`}>{label}</ColumnLabel>
        ))}
      </BottomLabelContainer>
    </Container>
  );
};

export default GridChartWithLabels;
