import { useFetch, formatQueryError } from '../utils/queries';
import { SITE_SETTINGS } from '../constants';

function useGetSiteSettings(_, options = {}) {
  const queryKey = ['SITE_SETTINGS'];
  const context = useFetch({
    key: queryKey,
    url: SITE_SETTINGS,
    reactQueryConfig: options,
  });
  return { ...context };
}

function useVerifyNewSubdomain({ subdomain, id }, options = {}) {
  const queryKey = ['VALIDATE_NEW_SUBDOMAIN', subdomain, id];
  const context = useFetch({
    key: queryKey,
    url: `${SITE_SETTINGS}/validate-new-subdomain`,
    axiosParams: { subdomain, organisationId: id },
    reactQueryConfig: options,
  });

  return {
    ...context,
    error: formatQueryError(context),
  };
}

export { useGetSiteSettings, useVerifyNewSubdomain };
