import React from 'react';
import styled from '@emotion/styled';

const TallyContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: center;
`;

const TallyGroup = styled.div`
  position: relative;
  display: inline-block;
  width: 40px;
  height: 50px;
`;

const TallyMark = styled.div`
  position: absolute;
  width: 2px;
  height: 50px;
  background: ${({ bgColor }) => bgColor ?? '#000'};

  &:nth-child(1) {
    left: 0;
  }

  &:nth-child(2) {
    left: 10px;
  }

  &:nth-child(3) {
    left: 20px;
  }

  &:nth-child(4) {
    left: 30px;
  }

  &:nth-child(5) {
    width: 2px;
    height: 70px;
    transform: rotate(-45deg);
    left: 35%;
    bottom: -20%;
  }
`;

const Tally = ({ count, bgColor }) => {
  // Calculate the number of full groups of 5 and remaining tally marks
  const fullGroups = Math.floor(count / 5);
  const remainder = count % 5;

  // Render full groups
  const fullTallyGroups = Array.from(
    { length: fullGroups },
    (_, groupIndex) => (
      <TallyGroup key={`group-${groupIndex}`}>
        {Array.from({ length: 5 }, (_, index) => (
          <TallyMark key={`mark-${groupIndex}-${index}`} bgColor={bgColor} />
        ))}
      </TallyGroup>
    )
  );

  // Render remaining tally marks
  const remainingTallyMarks = (
    <TallyGroup>
      {Array.from({ length: remainder }, (_, index) => (
        <TallyMark key={`remaining-mark-${index}`} bgColor={bgColor} />
      ))}
    </TallyGroup>
  );

  return (
    <TallyContainer>
      {fullTallyGroups}
      {remainder > 0 && remainingTallyMarks}
    </TallyContainer>
  );
};

export default Tally;
