import styled from '@emotion/styled';
import { setMargin } from 'helpers';

export const TextAudioWrapper = styled.div`
  ${setMargin};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: ${({ jc }) => jc || 'center'};
`;

export const OuterWrapper = styled.div`
  ${setMargin}
  width: ${({ w }) => w ?? '100%'};
  padding: 24px;
  background-color: ${({ bg, theme }) => bg && theme.colors[bg]};
  ${({ theme }) => theme.media.mobile} {
    padding: 12px;
  }
`;

export const Divider = styled.div`
  width: ${({ w }) => w ?? '80%'};
  padding-top: ${({ theme }) => theme.spacings[4]};
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  margin: ${({ theme }) => theme.spacings[4]} auto;
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const ContentWrapper = styled.div`
  margin-bottom: auto;
  display: flex;
  flex-direction: column;
  margin-left: ${({ theme }) => theme.spacings[6]};
  margin-right: ${({ theme }) => theme.spacings[6]};
  align-items: ${({ ai }) => ai ?? 'unset'};
  ${setMargin}
  max-height: 100%;
  overflow-y: scroll;
`;

export const TypeInput = styled.input`
  outline: 0;
  border-width: 0 0 2px;
  border-color: ${({ theme, isCorrect }) =>
    isCorrect ? theme.colors.quinaryMain : theme.colors.primaryMain};
  width: ${({ w }) => w || '142px'};
  font-style: normal;
  font-weight: 400;
  font-size: 1.75rem;
  line-height: 150%;
  text-align: center;
  background: transparent;
  margin-left: ${({ theme }) => theme.spacings[2]};
  color: ${({ theme }) => theme.colors.neutralMain};
  transition: 0.3s;
  &:focus {
    border-color: ${({ theme, isCorrect }) =>
      isCorrect ? theme.colors.quinaryMain : theme.colors.neutralMain};
  }
`;

export const ImageWrapper = styled.div`
  margin: 0 auto;
  overflow: hidden;
  width: 100%;
  max-width: 420px;
  max-height: 420px;
`;

export const Img = styled.img`
  width: 100%;
  object-fit: cover;
`;

export const OptionsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: space-between;
  width: 300px;
  margin: 0 auto;
  ${({ applyNewStyle }) =>
    applyNewStyle
      ? `
      flex: 1;
      align-content: center;
    `
      : ``};
`;

export const TypeInputBox = styled.input`
  border-style: solid;
  border-width: ${({ showValue }) => (showValue ? '0' : '2px')};
  border-radius: 12px;
  border-color: ${({ theme, isCorrect }) =>
    isCorrect ? theme.colors.quinaryMain : theme.colors.primaryMain};
  width: ${({ w }) => w ?? '100%'};
  height: ${({ h }) => h ?? '80px'};
  font-style: normal;
  font-weight: 400;
  font-size: 1.75rem;
  line-height: 150%;
  text-align: center;
  color: ${({ theme }) => theme.colors.neutralMain};
  background: ${({ theme, showValue }) =>
    showValue ? theme.colors.white : 'transparent'};
  transition: 0.3s;
  &:focus,
  &:focus-visible {
    border-color: ${({ theme, isCorrect }) =>
      isCorrect ? theme.colors.quinaryMain : theme.colors.neutralMain};
    outline: none;
  }
  &::placeholder {
    text-align: center;
  }
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const ButtonWrapper = styled.div`
  width: 300px;
  margin: 0 auto;
  margin-top: auto;
`;
