const Triangle = ({ width, height, color, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 35"
    fill={color || 'transparent'}
    width={width || '40'}
    height={height || '35'}
    {...props}
  >
    <path d="M0.947441 34L20 0.999999L39.0526 34H0.947441Z" stroke="black" />
  </svg>
);

export default Triangle;
