import styled from '@emotion/styled';
import { Typography } from '../../components';

const { P } = Typography;

export const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;

  padding-right: ${({ theme }) => theme.spacings[11]};
  padding-left: ${({ theme }) => theme.spacings[11]};
  ${({ theme }) => theme.media.tablet} {
    padding-right: ${({ theme }) => theme.spacings[6]};
    padding-left: ${({ theme }) => theme.spacings[6]};
  }
  ${({ theme }) => theme.media.mobile} {
    padding-right: 0;
    padding-left: 0;
  }
`;

export const Link = styled(P)`
  display: inline-block;
  text-decoration: underLine;
  padding-top: 8px;
  padding-left: 8px;
  &:hover {
    cursor: pointer;
  }
`;
