import styled from '@emotion/styled';
import setMargin from '../../helpers/set-margin';

export const ResponsiveYoutube = styled.div`
  ${setMargin};
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
  width: 100%;
  border-radius: ${({ showRadius }) => showRadius && '27px'};
  iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }
`;

export const PlaceHolderContainer = styled.div`
  position: relative;
  width: 100%;
  padding-top: calc(56.25% / 2);
  padding-bottom: calc(56.25% / 2);
  display: flex;
  justify-content: center;
  align-items: center;
`;
