import { useLocation } from 'react-router-dom';
import { Result } from 'antd';
import { useMediaQuery } from 'react-responsive';
import theme from 'theme';
import { Grid, Typography as T, Button } from '../../components';
import { navRoutes } from 'constants';
const { Row, Col } = Grid;

const ErrorPage = () => {
  const { state } = useLocation();
  const isMobile = useMediaQuery({
    query: `(max-width: ${theme.breakpoints.mobile})`,
  });
  if (state?.error) {
    return (
      <Row mb="3">
        <Col w={[4, 12, 12]}>
          <T.P
            size={isMobile ? 'med' : 'medLarge'}
            weight="bold"
            color="neutralMain"
            ta="center"
          >
            {state.error.message}
          </T.P>
        </Col>
      </Row>
    );
  }

  return (
    <Result
      status="404"
      title="404"
      subTitle={
        <T.P color="neutralMain" ta="center">
          Sorry, Something went wrong. contact support
        </T.P>
      }
      extra={
        <Button.BasicButton
          variant="secondary"
          to={navRoutes.GENERAL.LOGIN}
          style={{ maxWidth: '300px' }}
          mx="auto"
          my="0"
        >
          Back Home
        </Button.BasicButton>
      }
      style={{ paddingTop: '100px' }}
    />
  );
};

export default ErrorPage;
