import { types as Ty, comprehensionsKeys } from '../../../constants';
import ListenAndRepeat from './ListenAndRepeat';
import ListenAndRepeatMultiple from './ListenAndRepeatMultiple';
import ListenAndRepeatGrid from './ListenAndRepeatGrid';
import ListenAndRepeatGridHidden from './ListenAndRepeatGridHidden';
import MultipleChoice from './MultipleChoice';
import { TextFinderBasic, TextFinderComprehension } from './TextFinder';
import WordBuild from './WordBuild';
import SpotTheWord from './SpotTheWord';
import {
  DragAndDropV1,
  DragAndDropV2,
  DragAndDropOrderSounds,
  DragAndDropCreateOrder,
} from './DragAndDrop';
import VowelBreak from './VowelBreak';
import TypeNewEndings from './TypeNewEndings';
import CombineWords, { CombineWordsPre } from './CombineWords';
import { WordJoin1, WordJoin2, WordJoin3 } from './WordJoin';
import SentenceFill from './SentenceFill';

import Comprehensions from './Comprehensions';
import MultipleChoiceGrid from './MultipleChoiceGrid';
import Trace from './Trace';
import Draw from './Draw';
import QuestionAndAnswer from './QuestionAndAnswer';
import TypingHorizontal from './TypingHorizontal';
import FillInTheGrid from './FillInTheGrid';
import Pyramid from './Pyramid';
import GridWithTypingAnswer from './GridWithTypingAnswer';
import FactFamily from './FactFamily';
import Formulae from './Formulae';
import ColumnsTyping from './ColumnsTyping';
import ComprehensionSelect from './ComprehensionSelect';
import Fractions from './Fractions';
import MultipleQuestionsWithEndings from './MultipleQuestionsWithEndings';
import Pictogram from './Pictogram';
import Wheel from './Wheel';
import BarModel from './BarModel';
import FillGaps from './FillGaps';
import BarChart from './BarChart';

const ExerciseStep = ({
  exercise,
  handleNext,
  disabled,
  preview,
  step,
  stuckAtReview,
  isLoading,
}) => {
  let Component = () => null;
  let layout = 'general';

  if (comprehensionsKeys.includes(exercise?.key)) {
    Component = (
      <Comprehensions
        exKey={exercise.key}
        handleNext={handleNext}
        disabled={disabled}
        preview={preview}
        stuckAtReview={stuckAtReview}
        {...step}
      />
    );
    layout = 'fullScreenComprehension';

    return {
      Component,
      layout,
    };
  }

  switch (step.type) {
    case Ty.exerciseTypes.LISTEN_AND_REPEAT:
      Component = (
        <ListenAndRepeat
          {...step}
          handleNext={handleNext}
          disabled={disabled}
          preview={preview}
          stuckAtReview={stuckAtReview}
        />
      );
      break;

    case Ty.exerciseTypes.LISTEN_AND_REPEAT_MULTIPLE:
      Component = (
        <ListenAndRepeatMultiple
          {...step}
          handleNext={handleNext}
          disabled={disabled}
          preview={preview}
          stuckAtReview={stuckAtReview}
        />
      );
      break;

    case Ty.exerciseTypes.LISTEN_AND_REPEAT_GRID:
      Component = (
        <ListenAndRepeatGrid
          {...step}
          handleNext={handleNext}
          disabled={disabled}
          preview={preview}
          stuckAtReview={stuckAtReview}
        />
      );
      break;

    case Ty.exerciseTypes.LISTEN_AND_REPEAT_GRID_HIDDEN:
      Component = (
        <ListenAndRepeatGridHidden
          {...step}
          handleNext={handleNext}
          disabled={disabled}
          preview={preview}
          stuckAtReview={stuckAtReview}
        />
      );
      break;

    case Ty.exerciseTypes.MULTIPLE_CHOICE:
      Component = (
        <MultipleChoice
          {...step}
          handleNext={handleNext}
          disabled={disabled}
          preview={preview}
          stuckAtReview={stuckAtReview}
          isLoading={isLoading}
        />
      );
      break;

    case Ty.exerciseTypes.MULTIPLE_CHOICE_BUBBLE:
      Component = (
        <MultipleChoice
          bubble
          {...step}
          handleNext={handleNext}
          disabled={disabled}
          preview={preview}
          stuckAtReview={stuckAtReview}
        />
      );
      break;

    case Ty.exerciseTypes.TEXT_FINDER:
      Component = (
        <TextFinderBasic
          {...step}
          handleNext={handleNext}
          disabled={disabled}
          preview={preview}
          stuckAtReview={stuckAtReview}
        />
      );
      break;

    case Ty.exerciseTypes.COMPREHENSION_TEXT_FINDER:
      Component = (
        <TextFinderComprehension
          {...step}
          handleNext={handleNext}
          disabled={disabled}
          preview={preview}
          stuckAtReview={stuckAtReview}
        />
      );
      layout = 'fullScreenComprehension';

      break;

    case Ty.exerciseTypes.WORD_BUILD:
      Component = (
        <WordBuild
          {...step}
          handleNext={handleNext}
          disabled={disabled}
          preview={preview}
          stuckAtReview={stuckAtReview}
        />
      );
      break;

    case Ty.exerciseTypes.SPOT_THE_WORD:
      Component = (
        <SpotTheWord
          {...step}
          preview={preview}
          handleNext={handleNext}
          stuckAtReview={stuckAtReview}
        />
      );
      layout = 'fullScreenEx';
      break;

    case Ty.exerciseTypes.DRAG_AND_DROP_1:
      Component = (
        <DragAndDropV1
          {...step}
          preview={preview}
          handleNext={handleNext}
          stuckAtReview={stuckAtReview}
        />
      );
      break;
    case Ty.exerciseTypes.DRAG_AND_DROP_2:
      Component = (
        <DragAndDropV2
          {...step}
          preview={preview}
          handleNext={handleNext}
          stuckAtReview={stuckAtReview}
        />
      );
      break;
    case Ty.exerciseTypes.DRAG_AND_DROP_ORDER_SOUNDS:
      Component = (
        <DragAndDropOrderSounds
          {...step}
          preview={preview}
          handleNext={handleNext}
          stuckAtReview={stuckAtReview}
        />
      );
      break;
    case Ty.exerciseTypes.DRAG_AND_DROP_CREATE_ORDER:
      Component = (
        <DragAndDropCreateOrder
          {...step}
          preview={preview}
          handleNext={handleNext}
          stuckAtReview={stuckAtReview}
        />
      );
      break;

    case Ty.exerciseTypes.VOWEL_BREAK:
      Component = (
        <VowelBreak
          {...step}
          handleNext={handleNext}
          disabled={disabled}
          preview={preview}
          stuckAtReview={stuckAtReview}
        />
      );
      layout = 'fullScreenEx';
      break;

    case Ty.exerciseTypes.WORD_JOIN_1:
      Component = (
        <WordJoin1
          {...step}
          handleNext={handleNext}
          disabled={disabled}
          preview={preview}
          stuckAtReview={stuckAtReview}
        />
      );
      break;

    case Ty.exerciseTypes.WORD_JOIN_2:
      Component = (
        <WordJoin2
          {...step}
          handleNext={handleNext}
          disabled={disabled}
          preview={preview}
          stuckAtReview={stuckAtReview}
        />
      );
      layout = 'fullScreenEx';

      break;

    case Ty.exerciseTypes.WORD_JOIN_3:
      Component = (
        <WordJoin3
          {...step}
          handleNext={handleNext}
          disabled={disabled}
          preview={preview}
          stuckAtReview={stuckAtReview}
        />
      );
      layout = 'fullScreenEx';

      break;

    case Ty.exerciseTypes.SENTENCE_FILL:
      Component = (
        <SentenceFill
          {...step}
          handleNext={handleNext}
          disabled={disabled}
          preview={preview}
          stuckAtReview={stuckAtReview}
        />
      );
      layout = 'fullScreenEx';
      break;

    case Ty.exerciseTypes.COMBINE_WORDS_PRE:
      Component = (
        <CombineWordsPre
          {...step}
          handleNext={handleNext}
          disabled={disabled}
          preview={preview}
          stuckAtReview={stuckAtReview}
          isLoading={isLoading}
        />
      );
      layout = 'fullScreenEx';

      break;

    case Ty.exerciseTypes.COMBINE_WORDS:
      Component = (
        <CombineWords
          {...step}
          handleNext={handleNext}
          disabled={disabled}
          preview={preview}
          stuckAtReview={stuckAtReview}
        />
      );
      layout = 'fullScreenEx';

      break;

    case Ty.exerciseTypes.TYPE_NEW_ENDINGS:
      Component = (
        <TypeNewEndings
          {...step}
          handleNext={handleNext}
          disabled={disabled}
          preview={preview}
          stuckAtReview={stuckAtReview}
        />
      );
      break;

    case Ty.exerciseTypes.MULTIPLE_CHOICE_GRID:
      Component = (
        <MultipleChoiceGrid
          {...step}
          handleNext={handleNext}
          disabled={disabled}
          preview={preview}
          stuckAtReview={stuckAtReview}
        />
      );
      break;

    case Ty.exerciseTypes.QUESTION_AND_ANSWER:
      Component = (
        <QuestionAndAnswer
          {...step}
          handleNext={handleNext}
          disabled={disabled}
          preview={preview}
          stuckAtReview={stuckAtReview}
        />
      );

      break;

    case Ty.exerciseTypes.TRACE:
      Component = (
        <Trace
          {...step}
          handleNext={handleNext}
          disabled={disabled}
          preview={preview}
          stuckAtReview={stuckAtReview}
        />
      );
      break;

    case Ty.exerciseTypes.DRAW:
      Component = (
        <Draw
          {...step}
          handleNext={handleNext}
          disabled={disabled}
          preview={preview}
          stuckAtReview={stuckAtReview}
        />
      );
      break;

    case Ty.exerciseTypes.TYPING_HORIZONTAL:
      Component = (
        <TypingHorizontal
          {...step}
          handleNext={handleNext}
          disabled={disabled}
          preview={preview}
          stuckAtReview={stuckAtReview}
          isLoading={isLoading}
        />
      );

      layout = 'fullScreenEx';
      break;

    case Ty.exerciseTypes.FILL_IN_THE_GRID:
      Component = (
        <FillInTheGrid
          {...step}
          handleNext={handleNext}
          disabled={disabled}
          preview={preview}
          stuckAtReview={stuckAtReview}
          isLoading={isLoading}
        />
      );

      break;

    case Ty.exerciseTypes.PYRAMID:
      Component = (
        <Pyramid
          {...step}
          handleNext={handleNext}
          disabled={disabled}
          preview={preview}
          stuckAtReview={stuckAtReview}
          isLoading={isLoading}
        />
      );
      break;

    case Ty.exerciseTypes.GRID_WITH_TYPING_ANSWER:
      Component = (
        <GridWithTypingAnswer
          {...step}
          handleNext={handleNext}
          disabled={disabled}
          preview={preview}
          stuckAtReview={stuckAtReview}
          isLoading={isLoading}
        />
      );
      break;

    case Ty.exerciseTypes.FACT_FAMILY:
      Component = (
        <FactFamily
          {...step}
          handleNext={handleNext}
          disabled={disabled}
          preview={preview}
          stuckAtReview={stuckAtReview}
          isLoading={isLoading}
        />
      );
      layout = 'fullScreenEx';
      break;

    case Ty.exerciseTypes.FORMULAE:
      Component = (
        <Formulae
          {...step}
          handleNext={handleNext}
          disabled={disabled}
          preview={preview}
          stuckAtReview={stuckAtReview}
          isLoading={isLoading}
        />
      );
      break;

    case Ty.exerciseTypes.COLUMNS_TYPING:
      Component = (
        <ColumnsTyping
          {...step}
          handleNext={handleNext}
          disabled={disabled}
          preview={preview}
          stuckAtReview={stuckAtReview}
          isLoading={isLoading}
        />
      );
      break;

    case Ty.exerciseTypes.COMPREHENSION_SELECT:
      Component = (
        <ComprehensionSelect
          {...step}
          handleNext={handleNext}
          disabled={disabled}
          preview={preview}
          stuckAtReview={stuckAtReview}
          isLoading={isLoading}
        />
      );
      break;

    case Ty.exerciseTypes.FRACTIONS:
      Component = (
        <Fractions
          {...step}
          handleNext={handleNext}
          disabled={disabled}
          preview={preview}
          stuckAtReview={stuckAtReview}
          isLoading={isLoading}
        />
      );
      break;

    case Ty.exerciseTypes.MULTIPLE_QUESTIONS_WITH_ENDINGS:
      Component = (
        <MultipleQuestionsWithEndings
          {...step}
          handleNext={handleNext}
          disabled={disabled}
          preview={preview}
          stuckAtReview={stuckAtReview}
          isLoading={isLoading}
        />
      );
      break;

    case Ty.exerciseTypes.PICTOGRAM:
      Component = (
        <Pictogram
          {...step}
          handleNext={handleNext}
          disabled={disabled}
          preview={preview}
          stuckAtReview={stuckAtReview}
          isLoading={isLoading}
        />
      );
      break;

    case Ty.exerciseTypes.WHEEL:
      Component = (
        <Wheel
          {...step}
          handleNext={handleNext}
          disabled={disabled}
          preview={preview}
          stuckAtReview={stuckAtReview}
          isLoading={isLoading}
        />
      );
      break;

    case Ty.exerciseTypes.BAR_MODEL:
      Component = (
        <BarModel
          {...step}
          handleNext={handleNext}
          disabled={disabled}
          preview={preview}
          stuckAtReview={stuckAtReview}
          isLoading={isLoading}
        />
      );
      break;

    case Ty.exerciseTypes.FILL_GAPS:
      Component = (
        <FillGaps
          {...step}
          handleNext={handleNext}
          disabled={disabled}
          preview={preview}
          stuckAtReview={stuckAtReview}
          isLoading={isLoading}
        />
      );
      break;

    case Ty.exerciseTypes.BAR_CHART:
      Component = (
        <BarChart
          {...step}
          handleNext={handleNext}
          disabled={disabled}
          preview={preview}
          stuckAtReview={stuckAtReview}
          isLoading={isLoading}
        />
      );
      break;

    default:
      break;
  }

  return { Component, layout };
};

export default ExerciseStep;
