import { Markdown } from '../../../../components';
import * as S from './style';
import * as SS from '../shared/style';

const QuestionRow = ({ sectionIndex, question, setAnswers, answers }) => {
  return (
    <S.Row
      bgColor={sectionIndex % 2 ? 'white' : 'neutralLight'}
      key={sectionIndex}
      mt={sectionIndex === 0 ? 5 : 0}
      direction="column"
    >
      <Markdown
        text={question.prefix?.replace(/\n/g, ' ')}
        customStyles={{
          p: {
            size: 'large',
            weight: 'regular',
            mb: 0,
            lh: '150%',
            ta: 'start',
          },
        }}
      />
      <SS.TypeInput
        id={'question' + question.id}
        name={'question' + question.id}
        type="text"
        w="146px"
        isCorrect={answers[question.id] === question.answer}
        aria-labelledby={'question' + question.id}
        value={answers[question.id]}
        onChange={(e) => {
          setAnswers((prevAnswers) => ({
            ...prevAnswers,
            [question.id]: e.target.value,
          }));
        }}
      />
      <Markdown
        text={question.suffix?.replace(/\n/g, ' ')}
        customStyles={{
          p: {
            size: 'large',
            weight: 'regular',
            mb: 0,
            lh: '150%',
            ta: 'start',
          },
        }}
      />
    </S.Row>
  );
};

export default QuestionRow;
