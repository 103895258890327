const cookiebotInit = () => {
  try {
    (function () {
      var s = document.createElement('script');
      s.id = 'Cookiebot';
      s.src = 'https://consent.cookiebot.com/uc.js';
      s.setAttribute('data-cbid', 'a7785967-9c6c-4521-b86b-1614ef5af5cb');
      s.setAttribute('data-blockingmode', 'auto');
      s.type = 'text/javascript';
      s.onerror = function () {
        // eslint-disable-next-line no-console
        console.error('Cookiebot script failed to load.');
      };
      document.head.appendChild(s);
    })();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Cookiebot failed to load:', e);
  }
};

export default cookiebotInit;
