import moment from 'moment';
import * as T from 'components/Typography';
import NameColumn from './NameColumn';
import * as S from './style';
import InlineCopy from 'components/Copy/InlineCopy';
import { coachApprovalStatuses } from '../../../constants';
import capitalizeFirstLetter from 'helpers/capitalizeFirstLetter';
import { Button } from 'components';

const tabsColumns = {
  approved: [
    'rowNumber',
    'fullName',
    'email',
    'coachUniqueId',
    'city',
    'totalLearners',
    'availabilityHoursPerWeek',
    'bestDayTime',
    'tpdCompletedTrainingAt',
    'cmidCompletedTrainingAt',
    'organisationName',
  ],
  awaiting: [
    'rowNumber',
    'fullName',
    'email',
    'city',
    'availabilityHoursPerWeek',
    'bestDayTime',
    'actions',
    'organisationName',
  ],
  inTraining: [
    'rowNumber',
    'fullName',
    'email',
    'city',
    'totalLearners',
    'availabilityHoursPerWeek',
    'bestDayTime',
    'tpdCompletedTrainingAt',
    'cmidCompletedTrainingAt',
    'organisationName',
  ],
  invited: ['rowNumber', 'email', 'inviteTokenCreatedAt', 'organisationName'],
  rejected: [
    'rowNumber',
    'fullName',
    'email',
    'coachUniqueId',
    'city',
    'rejectionReason',
  ],
};
const getCoachTableColumns = ({
  tab = 'approved',
  showAdminFields,
  assignCoachActionColumn,
  actions = {
    rejectActionLoading: false,
    acceptActionLoading: false,
    accept: async () => {},
    reject: async () => {},
  },
  page,
  isWorkingInPrisonEnvironment,
  isTpdCourse,
}) => {
  const commonColumns = [
    {
      title: '#',
      dataIndex: 'rowNumber',
      key: 'rowNumber',
      render: (text, record, index) => index + 1 + (page - 1) * 20,
    },
    {
      title: 'Name',
      dataIndex: 'fullName',
      key: 'fullName',
      render: (name, { id, approvalStatus, deleted }) =>
        !deleted ? (
          <NameColumn
            id={id}
            name={name}
            deleted={deleted}
            statusType={'coach'}
            approvalStatus={approvalStatus}
          />
        ) : (
          <T.P>Deleted</T.P>
        ),
      align: 'left',
    },

    {
      title: showAdminFields
        ? 'Email or Coach ID'
        : isWorkingInPrisonEnvironment
        ? 'Unique ID'
        : 'Email',
      dataIndex: isWorkingInPrisonEnvironment ? 'coachUniqueId' : 'email',
      key: isWorkingInPrisonEnvironment ? 'coachUniqueId' : 'email',
      render: (value, { deleted, inviteURL, coachUniqueId }) =>
        !deleted ? (
          <S.CopyLinkWrapper>
            {isWorkingInPrisonEnvironment ? (
              <InlineCopy text={`${value}`} textToCopy={value} />
            ) : (
              <T.P style={{ minWidth: '200px' }}>{value || coachUniqueId}</T.P>
            )}
            {inviteURL && (
              <InlineCopy
                textToCopy={inviteURL}
                text="Invitation link"
                color="primaryMain"
              />
            )}
          </S.CopyLinkWrapper>
        ) : (
          <T.P>Deleted</T.P>
        ),
    },
    {
      title: <T.P weight="bold">Organisation</T.P>,
      dataIndex: 'organisationName',
      key: 'organisationName',
      render: (text) => (text ? capitalizeFirstLetter(text) : 'N/A'),
      adminOnly: true,
    },
    {
      title: 'City',
      dataIndex: 'city',
      key: 'city',
      render: (city) => <T.P>{city || 'N/A'}</T.P>,
    },
    {
      title: 'Learners',
      dataIndex: 'totalLearners',
      key: 'totalLearners',
    },
    {
      title: 'Availability (h/w)',
      dataIndex: 'availabilityHoursPerWeek',
      key: 'availabilityHoursPerWeek',
    },
    {
      title: 'Timepiece',
      dataIndex: 'timepiece',
      key: 'bestDayTime',
      render: (bestDayTime) => <T.P>{bestDayTime || 'N/A'}</T.P>,
    },
    {
      title: 'Training completion',
      dataIndex: isTpdCourse
        ? 'tpdCompletedTrainingAt'
        : 'cmidCompletedTrainingAt',
      key: isTpdCourse ? 'tpdCompletedTrainingAt' : 'cmidCompletedTrainingAt',
      render: (date) => (date ? moment(date).format('MMMM DD, YYYY') : 'TBC'),
    },
    {
      title: 'Rejected Reason',
      dataIndex: 'rejectionReason',
      key: 'rejectionReason',
      render: (rejectionReason) => (
        <T.P>{rejectionReason || 'Not Implemented'}</T.P>
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, row) =>
        row.approvalStatus === coachApprovalStatuses.APPROVED ? (
          <S.Tag bgColor="quinaryDark" fontColor="neutralSurface">
            APPROVED
          </S.Tag>
        ) : (
          <S.ActionsButtonWrapper>
            <Button.BasicButton
              loading={actions.rejectActionLoading}
              disabled={actions.rejectActionLoading}
              handleClick={() => actions.reject(row)}
              width="auto"
              height="auto"
              size="small"
              customColor={'tertiaryMain'}
              borderColor={'tertiaryMain'}
              variant="transparent"
              innerTextPadding="0px"
            >
              Reject
            </Button.BasicButton>
            <Button.BasicButton
              loading={actions.acceptActionLoading}
              disabled={actions.acceptActionLoading}
              onClick={() => actions.accept(row)}
              width="auto"
              height="auto"
              size="small"
              variant="primary"
              innerTextPadding="0px"
            >
              Accept
            </Button.BasicButton>
          </S.ActionsButtonWrapper>
        ),
    },
    {
      title: 'Created At',
      dataIndex: 'inviteTokenCreatedAt',
      key: 'inviteTokenCreatedAt',
      render: (createdAt) => moment(createdAt).format('MMMM DD, YYYY'),
    },
  ].filter((column) => {
    if (column.adminOnly && !showAdminFields) {
      return false;
    }
    if (tabsColumns[tab].includes(column.key)) {
      return true;
    }
    return false;
  });

  return assignCoachActionColumn
    ? [...commonColumns, assignCoachActionColumn]
    : commonColumns;
};

export default getCoachTableColumns;
