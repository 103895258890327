import { string } from 'yup';

import { BasicInput } from '../../../../components/Inputs';
import { MediaKeyInput } from '../../../components/MediaKeyInput';
import * as T from '../../../../components/Typography';
import MDEditor from '../../../../components/MDEditor';
import { mediaInputLabels } from 'constants';
import { courseCodeTypes, mediaTypes } from '../../../../constants';
import { useStepForm } from 'CMS/Providers/StepFormProvider';
import { useRef } from 'react';
import { useSiteSettings } from 'context/site-settings';

const initialData = {
  title: '',
  subtitle: '',
  sentence: '',
  titleImage: '',
};

const ListenAndRepeat = () => {
  // Use useRef to keep the initial state constant across renders
  const initialRef = useRef(initialData);
  const { state, updateFormFields, updateDataFields } = useStepForm(
    initialRef.current
  );
  const { form, data, validationErrs } = state;
  const { courseCode } = useSiteSettings();

  return (
    <div>
      <BasicInput
        value={data.textAudio}
        handleChange={(textAudio) => updateDataFields({ textAudio })}
        label="Explainer Text"
        error={validationErrs.textAudio}
        m={{ mt: 8 }}
      />

      <MediaKeyInput
        label="Explainer Audio"
        type={mediaTypes.TEXT_AUDIO}
        value={data.textAudioKey}
        handleChange={(textAudioKey) => updateDataFields({ textAudioKey })}
        m={{ mt: 5 }}
        error={validationErrs.textAudioKey}
        requireVo
      />

      <MediaKeyInput
        label={mediaInputLabels.AUDIO_LABEL}
        type={mediaTypes.AUDIO}
        value={form.audioKey}
        handleChange={(audioKey) => updateFormFields({ audioKey })}
        m={{ mb: 5, mt: 5 }}
        error={validationErrs?.audioKey}
      />

      <MDEditor
        label={'Title'}
        value={data.title}
        onChange={(title) => updateDataFields({ title })}
        mode={'edit'}
        height={80}
        m={{ mt: 8 }}
        error={validationErrs?.title}
      />

      <MDEditor
        label={'Subtitle'}
        value={data.subtitle}
        onChange={(subtitle) => updateDataFields({ subtitle })}
        mode={'edit'}
        height={80}
        m={{ mt: 8 }}
        error={validationErrs.subtitle}
      />

      <MDEditor
        label="Sentence"
        value={data.sentence}
        onChange={(sentence) => updateDataFields({ sentence })}
        mode={'edit'}
        m={{ mt: 8 }}
        error={validationErrs?.sentence}
      />

      <MediaKeyInput
        label={mediaInputLabels.IMAGE_LABEL}
        type={mediaTypes.IMAGE}
        value={form.imageKey}
        handleChange={(imageKey) => updateFormFields({ imageKey })}
        m={{ mb: 5, mt: 8 }}
        error={validationErrs?.imageKey}
      />

      <BasicInput
        label={courseCode === courseCodeTypes.TPD ? 'Letter' : 'Number'}
        helper={`use to show ${
          courseCode === courseCodeTypes.TPD ? 'letter' : 'number'
        } beside the image`}
        value={data.titleImage}
        handleChange={(titleImage) =>
          titleImage.length <= 1 && updateDataFields({ titleImage })
        }
        m={{ mt: 8 }}
        error={validationErrs?.titleImage}
      />
      <T.P color="error" mt={6}>
        {validationErrs.general}
      </T.P>
    </div>
  );
};

const validationSchema = {
  audioKey: string().notRequired(),
  title: string().max(50).notRequired(),
  subtitle: string().max(15).notRequired(),
  sentence: string().notRequired(),
  titleImage: string().notRequired(),
  imageKey: string()
    .nullable()
    .when('titleImage', {
      is: (titleImage) => !!titleImage,
      then: string().required('required field'),
      otherwise: string().nullable().notRequired(),
    }),

  textAudio: string().notRequired(),
  textAudioKey: string().notRequired(),
  general: string().test(
    'shouldAddContent',
    'You should provide at least one content',
    (val, { parent }) => {
      const { title, subtitle, sentence, titleImage, textAudio } = parent;
      return title || subtitle || sentence || titleImage || textAudio || false;
    }
  ),
};

export { validationSchema };
export default ListenAndRepeat;
