import styled from '@emotion/styled';

export const QuestionWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Divider = styled.div`
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.primaryMain};
  margin: ${({ theme }) => theme.spacings[3]} 0;
`;
