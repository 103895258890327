import { navRoutes as NR, audio } from '../../../constants';
import { useGeneralState } from '../../../context/general-state';
import { getMediaUrl } from '../../../helpers';
import PlayButton from '../../Button/Play/';
import * as S from './style';
import { Row, Col } from '../../../components/Grid';
import { useSiteSettings } from 'context/site-settings';

const Footer = () => {
  const { state: generalState } = useGeneralState();
  const { loaded: siteSettingsLoaded, isWorkingInPrisonEnvironment } =
    useSiteSettings();

  const isNotInPrison = siteSettingsLoaded && !isWorkingInPrisonEnvironment;
  return (
    <S.Footer>
      <S.Container>
        <S.FooterContent>
          <Row ai="center">
            <Col w={[2, 6, 6]}>
              {isNotInPrison && (
                <PlayButton
                  width={'20px'}
                  height={'20px'}
                  radius={12}
                  iconProps={{
                    height: '20px',
                    width: '20px',
                  }}
                  weight="bold"
                  size="regular"
                  color="white"
                  backgroundColor="none"
                  btnColor="white"
                  withoutButtonStyle={true}
                  mlText={0}
                  to={`mailto:${NR.EXTERNAL.CONTACT_US}`}
                  external
                  audioUrl={getMediaUrl(
                    audio.contactUs,
                    true,
                    generalState?.preferredVoice
                  )}
                  fallbackText="Contact us"
                >
                  Contact us
                </PlayButton>
              )}
            </Col>
            <Col w={[2, 6, 6]} jc="flex-end">
              <PlayButton
                width={'20px'}
                height={'20px'}
                radius={12}
                iconProps={{
                  height: '20px',
                  width: '20px',
                }}
                weight="bold"
                size="regular"
                color="white"
                backgroundColor="none"
                btnColor="white"
                withoutButtonStyle={true}
                mlText={0}
                to={
                  isNotInPrison
                    ? NR.EXTERNAL.PRIVACY_POLICY
                    : NR.GENERAL.PRIVACY_AND_POLICY
                }
                external
                audioUrl={getMediaUrl(
                  audio.privacyPolicy,
                  true,
                  generalState?.preferredVoice
                )}
                fallbackText="Privacy Policy"
              >
                Privacy Policy
              </PlayButton>
            </Col>
          </Row>
        </S.FooterContent>
      </S.Container>
    </S.Footer>
  );
};

export default Footer;
