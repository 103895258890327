import * as S from './style';
import Icon from 'components/Icon';
import * as T from 'components/Typography';
import Switch from '../../../../components/Switch';

const getCharLength = (options) => {
  const length = Math.max(
    ...(options?.map((o) => o?.label?.length || 0) || 0),
    10
  );

  return length * 1.3;
};

const Options = ({
  options,
  addNewOption,
  removeOption,
  onOptionChange,
  staticQuestion,
  type,
  error,
}) => {
  return (
    <S.OptionsWrapper>
      {options?.map((option, index) => (
        <S.OptionWrapper>
          <S.InputCheckWrapper>
            <Icon
              icon={type === 'SINGLE_CHOICE' ? 'circle' : 'square'}
              width={24}
              height={24}
              mr={2}
              color={staticQuestion ? 'neutral30' : 'neutralMain'}
            />

            {/* {getCharLength(options)} */}

            <S.QuestionLabelInput
              variant="standard"
              multiline
              value={option?.label}
              onChange={(e) => onOptionChange(index, { label: e.target.value })}
              charLength={getCharLength(options)}
              placeholder="Add option..."
              inputProps={{
                style: {
                  paddingLeft: '12px',
                },
              }}
              disabled={staticQuestion}
              error={error?.options?.[index]?.label}
              helperText={error?.options?.[index]?.label}
            />
          </S.InputCheckWrapper>

          {!staticQuestion && (
            <S.ActionsWrapper>
              <Icon
                icon="cross"
                width={12}
                height={12}
                ml={7}
                color={'primaryMain'}
                pointer
                disabled={options?.length === 1}
                onClick={() => {
                  if (options?.length === 1) return;
                  removeOption(index);
                }}
              />
              {type === 'CHECKBOXES' && (
                <Switch
                  checked={option.required}
                  onChange={(value) =>
                    onOptionChange(index, { required: value })
                  }
                  label="Must tick"
                  weight="400"
                  size="small"
                  sx={{
                    mr: 0,
                    ml: 3,
                  }}
                />
              )}
            </S.ActionsWrapper>
          )}
        </S.OptionWrapper>
      ))}
      {!staticQuestion && (
        <S.AddOptionButton onClick={addNewOption}>
          <Icon icon="plus" width={24} height={24} mr={2} />
          <T.P color="primaryMain">Add option</T.P>
        </S.AddOptionButton>
      )}
    </S.OptionsWrapper>
  );
};

export default Options;
