import styled from '@emotion/styled';

export const CirclesWrapper = styled.div`
  display: grid;
  grid-template-columns: ${({ numberOfCircles }) =>
    numberOfCircles <= 16
      ? 'repeat(4, 56px)'
      : numberOfCircles <= 30
      ? 'repeat(6, 46px)'
      : 'repeat(6, 32px)'};
  gap: 22px;
  justify-content: center;
  margin-top: ${({ theme }) => theme.spacings[4]};
  max-width: 100%;
`;

export const Circle = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid #000;
  cursor: pointer;
  background: ${({ isSelected, theme }) =>
    isSelected ? theme.colors.primaryMain : 'unset'};
`;

export const Divider = styled.div`
  width: 100%;
  border: 1px solid #000;
  margin: ${({ theme }) => theme.spacings[1]} 0;
`;

export const QuestionWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.spacings[3]};
  margin-top: ${({ theme }) => theme.spacings[7]};
`;

export const FractionWrapper = styled.div`
  width: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
