import { Typography as T } from '../../components';
import Logo from './logo.svg';

import * as S from './style';

const PrivacyAndPolicy = () => {
  return (
    <S.Wrapper>
      <S.LogoWrapper>
        <img src={Logo} alt="Shannon Trust Logo" className="h-16" />
      </S.LogoWrapper>
      <T.H1 color="neutralMain" bold mb="6" mt="6">
        Privacy notice
      </T.H1>
      <T.P mb="4">
        Shannon Trust is committed to protecting the privacy and security of
        your personal data. This privacy notice details how Shannon Trust
        collects and uses personal data about you during and after your working
        relationship with us, in accordance with the UK General Data Protection
        Regulation (UK GDPR).
      </T.P>
      <T.P>
        Please read this privacy notice carefully before you provide us with any
        personal data so that you fully understand how your data is collected
        and used by Shannon Trust.
      </T.P>
      <section id="section-1">
        <T.H2 color="secondaryMain" bold mb="5" mt="7">
          1. Important personal data and who we are
        </T.H2>
        <T.P mb="4">
          Shannon Trust is a "data controller". This means that we are
          responsible for deciding how we hold and use personal data about you.
          We are required under data protection legislation to notify you of the
          personal data contained in this privacy notice.
        </T.P>
        <T.P>
          This notice applies to all current and former learners and mentors,
          prison and other partners’ staff, supporters and donors, customers,
          trustees, staff and volunteers. It is important that you read and
          retain this notice, together with any other privacy notice we may
          provide on specific occasions when we are collecting or processing
          personal data about you, so that you are aware of how and why we are
          using such personal data and what your rights are under the data
          protection legislation.
        </T.P>
      </section>
      <section id="section-2">
        <T.H2 color="secondaryMain" bold mb="5" mt="7">
          2. Data protection principles
        </T.H2>
        <T.P mb="2">
          We will comply with data protection law. This says that the personal
          data we hold about you must be:
        </T.P>
        <T.Ul ml="6">
          <T.Li listStyle="disc">
            used lawfully, fairly and in a transparent way
          </T.Li>
          <T.Li listStyle="disc">
            collected only for valid purposes that we have clearly explained to
            you and not used in any way that is incompatible with those purposes
          </T.Li>
          <T.Li listStyle="disc">
            relevant to the purposes we have told you about and limited only to
            those purposes
          </T.Li>
          <T.Li listStyle="disc">accurate and kept up to date</T.Li>
          <T.Li listStyle="disc">
            kept only as long as necessary for the purposes we have told you
            about; and
          </T.Li>
          <T.Li listStyle="disc">kept securely</T.Li>
        </T.Ul>
      </section>
      <section id="section-3">
        <T.H2 color="secondaryMain" bold mb="5" mt="7">
          3. The personal data we collect
        </T.H2>
        <T.P mb="4">
          Personal data means any information about an individual from which
          that person can be identified. It does not include data where the
          identity has been removed (anonymous data).
        </T.P>
        <T.P mb="2">
          Depending on how you are involved with us, we will collect your
          personal data under one or more of the following legal bases:
        </T.P>
        <T.Ul ml="6">
          <T.Li listStyle="disc">
            Consent – we may be able to offer you a choice as to whether or not
            we collect, store, share or otherwise process your personal data.
            This will be made clear to you and will not be done unless we have
            your consent.
          </T.Li>
          <T.Li listStyle="disc">
            Contract – in some cases we need to collect personal data to fulfil
            a contract, this personal data will always be anonymised. For
            example, where we are required to report on learner diversity.
          </T.Li>
          <T.Li listStyle="disc">
            Legitimate interest – where processing is necessary for the purposes
            of our legitimate interests, except where such interests are
            overridden by your interests or fundamental rights and freedoms
            which require protection of personal data.
          </T.Li>
          <T.Li listStyle="disc">
            Legal obligation – sometimes we may need to store and share personal
            data where we have a legal obligation to do so. For example, we may
            need to store personal data to comply with employment or health and
            safety legislation.
          </T.Li>
          <T.Li listStyle="disc">
            Public interest – where processing is necessary for us to perform a
            task in the public interest, and the task has a clear basis in law.
          </T.Li>
        </T.Ul>
        <T.P mb="2" mt="4">
          To carry out our core activities, we collect, store and use personal
          data about you, which may include:
        </T.P>
        <T.Ul ml="6">
          <T.Li listStyle="disc">your name and title</T.Li>
          <T.Li listStyle="disc">address and postcode</T.Li>
          <T.Li listStyle="disc">date of birth</T.Li>
          <T.Li listStyle="disc">gender</T.Li>
          <T.Li listStyle="disc">marital status and dependants</T.Li>
          <T.Li listStyle="disc">private and corporate email address</T.Li>
          <T.Li listStyle="disc">phone number</T.Li>
          <T.Li listStyle="disc">employment history</T.Li>
          <T.Li listStyle="disc">education history</T.Li>
          <T.Li listStyle="disc">
            financial personal data and compliance documentation
          </T.Li>
          <T.Li listStyle="disc">
            references verifying your qualifications and experience
          </T.Li>
          <T.Li listStyle="disc">
            documents that verify your right to work in the United Kingdom
          </T.Li>
          <T.Li listStyle="disc">curriculum vitae (CV)</T.Li>
          <T.Li listStyle="disc">photograph</T.Li>
          <T.Li listStyle="disc">
            employment details, including links to your professional profiles
            available in the public domain, for example, LinkedIn, Twitter,
            Facebook, or a personal or corporate website
          </T.Li>
        </T.Ul>
        <T.P mb="2" mt="4">
          Some of the personal data we collect about you is considered as being
          sensitive personal data, which requires a higher level of protection.
          This may include
        </T.P>
        <T.Ul ml="6">
          <T.Li listStyle="disc">age and date of birth</T.Li>
          <T.Li listStyle="disc">race or ethnicity</T.Li>
          <T.Li listStyle="disc">sexual preferences</T.Li>
          <T.Li listStyle="disc">
            alcohol and other drug use Shannon Trust privacy notice Page | 3
          </T.Li>
          <T.Li listStyle="disc">trade union membership</T.Li>
          <T.Li listStyle="disc">
            data relating to a disability or your physical or mental health
            including records relating to your leaving employment for reason of
            ill health, injury or disability
          </T.Li>
          <T.Li listStyle="disc">
            details of any absences (other than holidays) from work including
            time on statutory parental leave and sick leave
          </T.Li>
          <T.Li listStyle="disc">
            criminal convictions or offending behaviour
          </T.Li>
          <T.Li listStyle="disc">experience of homelessness</T.Li>
          <T.Li listStyle="disc">experience of the care system</T.Li>
        </T.Ul>
      </section>
      <section id="section-4">
        <T.H2 color="secondaryMain" bold mb="5" mt="7">
          4. How we collect this personal data
        </T.H2>
        <T.P mb="2">
          We collect personal data about current and former learners and
          mentors, prison and other partners’ staff, donors, supporters,
          customers, trustees, staff and volunteers by:
        </T.P>
        <T.Ul ml="6">
          <T.Li listStyle="disc">
            {' '}
            corresponding with us by phone, email or otherwise
          </T.Li>
          <T.Li listStyle="disc">
            engaging with us to participate in one of our programmes
          </T.Li>
          <T.Li listStyle="disc">attending our events</T.Li>
        </T.Ul>
        <T.P mt="4">
          We may also obtain personal data about you from other sources such as
          LinkedIn, Twitter, Facebook, corporate websites, job board websites,
          online CV libraries, your business card, personal recommendations, and
          any relevant social media sites. We may sometimes collect additional
          information from third parties including former employers or
          background check agencies.{' '}
        </T.P>
      </section>
      <section id="section-5">
        <T.H2 color="secondaryMain" bold mb="5" mt="7">
          5. Cookies{' '}
        </T.H2>
        <T.P mb="4">
          Our website uses cookies, which are small files that are placed on
          your computer when you visit a website. Cookies allow the website to
          recognise your browser, and to distinguish you from other website
          users, by reading the contents of the cookie each time the user visits
          the website. They help us to improve users’ experiences of our
          website, to track usage and trends, and to generally improve the
          effectiveness of our website.
        </T.P>
        <T.P mb="4">
          By using our website, you indicate that you accept our use of cookies.
          You may refuse the use of cookies by selecting the appropriate
          settings on your browser, however, please note that if you do this you
          may not be able to use the full functionality of this website.{' '}
        </T.P>
        <T.P>
          Please note that third party sites that you are linked to within our
          website may also use cookies. We have no control over the use of
          cookies by third party sites. Please ensure that you read the cookie
          policies on any third party site to which you are linked.
        </T.P>
      </section>
      <section id="section-6">
        <T.H2 color="secondaryMain" bold mb="5" mt="7">
          6. How we use the personal data we collect about you
        </T.H2>
        <T.P mb="2">
          We will only use your personal data when the law allows us to. Most
          commonly, we will use your personal data where:
        </T.P>
        <T.Ul ml="6">
          <T.Li listStyle="disc">
            we need to perform a contract we have entered into with you
          </T.Li>
          <T.Li listStyle="disc">
            we need to comply with a legal obligation
          </T.Li>
          <T.Li listStyle="disc">
            it is necessary for legitimate interests pursued by us or a third
            party and your interests and fundamental rights do not override
            those interests
          </T.Li>
        </T.Ul>
        <T.P mb="2" mt="4">
          We may also use your personal data in the following situations, which
          are likely to be rare, where:
        </T.P>
        <T.Ul ml="6">
          <T.Li listStyle="disc">
            we need to protect your interests (or someone else's interests)
          </T.Li>
          <T.Li listStyle="disc">• it is needed in the public interest</T.Li>
        </T.Ul>
        <T.P mb="4" mt="4">
          We need all the categories of information in{' '}
          <T.Link to="#section-3">section 3</T.Link> to allow us to perform our
          contract with you and to enable us to comply with legal obligations.
          In some cases, we may use your personal data to pursue our legitimate
          interests, except where such interests are overridden by your
          interests or fundamental rights and freedoms which require protection
          of personal data. Some of these grounds for processing will overlap
          and there may be several grounds which justify our use of your
          personal data.
        </T.P>
        <T.P mb="2">
          The situations in which we will process your personal data:
        </T.P>
        <T.Ul ml="6">
          <T.Li listStyle="disc">
            If you are a subscriber, supporter or donor, or a prospective donor,
            we will primarily use the personal data that you provide to us to
            contact you about our work and future fundraising initiatives.
          </T.Li>
          <T.Li listStyle="disc">
            If you are a learner or mentor, we will primarily use the personal
            data that you provide to us to allow us to enrol you in our
            educational programmes. However, we may also use your personal data
            to do:
            <T.Ul ml="6">
              <T.Li listStyle="circle">
                assess the effectiveness of our programmes
              </T.Li>
              <T.Li listStyle="circle">
                keep you and others safe whilst working with us
              </T.Li>
              <T.Li listStyle="circle">
                in risk assessments, to put in place countermeasures for
                identified risks
              </T.Li>
              <T.Li listStyle="circle">equal opportunities monitoring</T.Li>
              <T.Li listStyle="circle">
                comply with our health and safety obligations
              </T.Li>
            </T.Ul>
          </T.Li>
          <T.Li listStyle="disc">
            If you are a member of staff in a prison or another of our partner
            agencies, or a mentor, we will primarily use the personal data that
            you provide to us to allow us to manage your involvement in our
            educational programmes. However, we may also use your personal data
            to:
            <T.Ul ml="6">
              <T.Li listStyle="circle">maintain records</T.Li>
              <T.Li listStyle="circle">
                comply with health and safety obligations
              </T.Li>
              <T.Li listStyle="circle">
                communicate with you about our work
              </T.Li>
              <T.Li listStyle="circle">
                keep you and others safe whilst working with us
              </T.Li>
              <T.Li listStyle="circle">
                carry out risk assessments, to put in place countermeasures for
                identified risks
              </T.Li>
            </T.Ul>
          </T.Li>
          <T.Li listStyle="disc">
            If you are a member of staff or a trustee, or wish to apply for
            either of those roles with us, we will primarily use the personal
            data that you provide to us to Shannon Trust privacy notice Page | 5
            allow us to perform our role as employer; to enable us to comply
            with legal obligations. However, we may also use your personal data
            for:
            <T.Ul ml="6">
              <T.Li listStyle="circle">
                making a decision about your recruitment or appointment, and
                determine the terms on which you work for us
              </T.Li>
              <T.Li listStyle="circle">
                checking you are legally entitled to work in the UK
              </T.Li>
              <T.Li listStyle="circle">
                paying you and, if you are an employee, deducting tax, National
                Insurance and pension contributions
              </T.Li>
              <T.Li listStyle="circle">
                liaising with your pension provider, providing information about
                changes to your employment
              </T.Li>
              <T.Li listStyle="circle">
                general administration of the contract we have entered into with
                you
              </T.Li>
              <T.Li listStyle="circle">
                business management and planning, including accounting and
                auditing
              </T.Li>
              <T.Li listStyle="circle">
                conducting performance reviews, managing performance and making
                decisions about salary reviews and compensation
              </T.Li>
              <T.Li listStyle="circle">
                assessing qualifications for a particular job or task, including
                decisions about promotions and make decisions about your
                continued employment or engagement
              </T.Li>
              <T.Li listStyle="circle">
                gathering evidence and any other steps relating to possible
                grievance or disciplinary matters
              </T.Li>
              <T.Li listStyle="circle">
                education, training and development requirements
              </T.Li>
              <T.Li listStyle="circle">
                dealing with legal disputes involving you, or other employees,
                workers and contractors, including accidents at work
              </T.Li>
              <T.Li listStyle="circle">
                determining your fitness to work, managing sickness absence
              </T.Li>
              <T.Li listStyle="circle">
                complying with health and safety obligations
              </T.Li>
              <T.Li listStyle="circle">preventing fraud</T.Li>
              <T.Li listStyle="circle">
                monitoring your business and personal use of our information and
                communication systems to ensure compliance with our IT policies
              </T.Li>
              <T.Li listStyle="circle">
                ensuring network and information security
              </T.Li>
              <T.Li listStyle="circle">
                complying with equal opportunities monitoring
              </T.Li>
            </T.Ul>
          </T.Li>
          <T.Li listStyle="disc">
            If you are a volunteer or prospective volunteer, we will primarily
            use the personal data that you provide to us to allow us to support
            and manage your volunteering role. However, we may also use your
            personal data to:
            <T.Ul ml="6">
              <T.Li listStyle="circle">
                assess your suitability for a volunteering role
              </T.Li>
              <T.Li listStyle="circle">maintain records</T.Li>
              <T.Li listStyle="circle">
                comply with health and safety obligations
              </T.Li>
              <T.Li listStyle="circle">
                meet education, training and development requirements
              </T.Li>
              <T.Li listStyle="circle">
                communicate about your volunteering role
              </T.Li>
              <T.Li listStyle="circle">
                keep you and others safe while volunteering
              </T.Li>
              <T.Li listStyle="circle">
                carry out risk assessments, to put in place countermeasures for
                identified risks
              </T.Li>
              <T.Li listStyle="circle">
                comply with equal opportunities monitoring
              </T.Li>
            </T.Ul>
          </T.Li>
          <T.Li listStyle="disc">
            If you are a customer or prospective customer, we will primarily use
            the personal data you provide to us to exchange information with you
            regarding our products and services. However we may also use your
            personal data to:
            <T.Ul ml="6">
              <T.Li listStyle="circle">
                establish if you might become a customer
              </T.Li>
              <T.Li listStyle="circle">process your order</T.Li>
              <T.Li listStyle="circle">collect payment from you</T.Li>
            </T.Ul>
          </T.Li>
          <T.Li listStyle="disc">
            If you are a member of our alumni scheme, as a former learner or
            mentor, we will primarily use the personal data that you provide to
            us to:
            <T.Ul ml="6">
              <T.Li listStyle="circle">
                assess your suitability to join, or continue as a member of, our
                alumni scheme
              </T.Li>
              <T.Li listStyle="circle">maintain records</T.Li>
              <T.Li listStyle="circle">
                contact you regarding our alumni activities and keep you up to
                date with our alumni activities
              </T.Li>
              <T.Li listStyle="circle">
                comply with our health and safety obligations
              </T.Li>
            </T.Ul>
          </T.Li>
        </T.Ul>
        <T.P mb="4" mt="4">
          If you fail to provide certain personal data when requested, we may
          not be able to perform the contract we have entered into with you, or
          we may be prevented from complying with our legal obligations.
        </T.P>
        <T.P mb="4">
          We will only use your personal data for the purposes for which we
          collected it, unless we reasonably consider that we need to use it for
          another reason and that reason is compatible with the original
          purpose. If we need to use your personal data for an unrelated
          purpose, we will notify you and we will explain the legal basis which
          allows us to do so.
        </T.P>
        <T.P mb="4">
          Please note that we may process your personal data without your
          knowledge or consent, in compliance with the above rules, where this
          is required or permitted by law.
        </T.P>
        <T.P>
          We do not envisage that any decisions will be taken about you using
          automated means, however we will notify you in writing if this
          position changes.
        </T.P>
      </section>
      <section id="section-7">
        <T.H2 color="secondaryMain" bold mb="5" mt="7">
          7. How we use particularly sensitive personal data we collect about
          you
        </T.H2>
        <T.P mb="2">
          "Special categories" of particularly sensitive personal data, such as
          information about disability or other illness, physical or mental
          health, and criminal convictions or offending behaviour, require
          higher levels of protection. We need to have further justification for
          collecting, storing and using this type of personal data. We have in
          place an appropriate policy document and safeguards which we are
          required by law to maintain when processing such data. We may process
          special categories of personal data:
        </T.P>
        <T.Ul ml="6">
          <T.Li listStyle="disc">
            in limited circumstances, with your explicit written consent
          </T.Li>
          <T.Li listStyle="disc">
            where we need to carry out our legal obligations or exercise rights
            in connection with employment
          </T.Li>
          <T.Li listStyle="disc">
            where it is needed in the public interest
          </T.Li>
          <T.Li listStyle="disc">
            where it is necessary to protect you or another person from harm
          </T.Li>
        </T.Ul>
        <T.P mb="4" mt="4">
          Less commonly, we may process this type of personal data where it is
          needed in relation to legal claims, or where it is needed to protect
          your interests (or someone else's interests) and you are not capable
          of giving your consent, or where you have already made the information
          public.
        </T.P>
        <T.P mb="4">
          In general, we will not process particularly sensitive personal data
          about you unless it is necessary for performing or exercising
          obligations or rights in connection with any contract between us. On
          rare occasions, there may be other reasons for processing, such as it
          is in the public interest to do so.
        </T.P>
        <T.P mb="2">
          The situations in which we will process your particularly sensitive
          personal data are:
        </T.P>
        <T.Ul ml="6">
          <T.Li listStyle="disc">
            to assess a current or former learner’s or mentor’s eligibility to
            participate in one of our programmes
          </T.Li>
          <T.Li listStyle="disc">
            to allow us to work with government agencies to assess the
            effectiveness of our programmes
          </T.Li>
          <T.Li listStyle="disc">
            to allow a learner or mentor to transition from a prison based
            programme to a community programme
          </T.Li>
          <T.Li listStyle="disc">
            to determine a potential employee’s, trustee’s or volunteer’s
            suitability for engagement with us, in particular whether you hold
            any criminal convictions which may prevent you from participating in
            prison work or working with certain categories of beneficiaries
          </T.Li>
          <T.Li listStyle="disc">
            to assess any special needs you may have as a result of a disability
            or health condition
          </T.Li>
          <T.Li listStyle="disc">
            to allow us to monitor the demographic profile of those that we work
            with - this information is held separately to any other personal
            data and is anonymised
          </T.Li>
          <T.Li listStyle="disc">
            if we reasonably believe that you or another person are at risk of
            harm and the processing is necessary to protect you or them from
            physical, mental or emotional harm or to protect physical, mental or
            emotional wellbeing
          </T.Li>
        </T.Ul>
        <T.P mb="4" mt="4">
          We do not need your consent if we use special categories of your
          personal data in accordance with our written policy to carry out our
          legal obligations or exercise specific legal rights, or if we are
          acting in the public interest.
        </T.P>
        <T.P mb="4">
          In limited circumstances, we may approach you for your written consent
          to allow us to process certain particularly sensitive data. If we do
          so, we will provide you with full details of the information that we
          would like and the reason we need it, so that you can carefully
          consider whether you wish to consent. You should be aware that it is
          not a condition of your contract with us that you agree to any request
          for consent from us.
        </T.P>
        <T.P>
          We do not need your consent where the purpose of the processing is to
          protect you or another person from harm or to protect your wellbeing
          and if we reasonably believe that you need care and support, are at
          risk of harm and are unable to protect yourself.
        </T.P>
      </section>
      <section id="section-8">
        <T.H2 color="secondaryMain" bold mb="5" mt="7">
          8. Information about criminal convictions
        </T.H2>
        <T.P mb="4">
          We may only use information relating to criminal convictions where the
          law allows us to do so. This will usually be where such processing is
          necessary to carry out our obligations and provided we do so in line
          with our data protection policy.
        </T.P>
        <T.P mb="4">
          We will hold personal data about criminal convictions, and we will
          process this information in the situations listed in{' '}
          <T.Link to="#section-7">section 7</T.Link>.
        </T.P>
        <T.P>
          We are allowed to use your personal data in this way as part of
          performing or exercising obligations or rights in connection with the
          contract between us, or to allow us to perform tasks in the public
          interest. We have in place an appropriate policy and safeguards which
          we are required by law to maintain when processing such data.
        </T.P>
      </section>
      <section id="section-9">
        <T.H2 color="secondaryMain" bold mb="5" mt="7">
          9. Disclosing the personal data which we collect
        </T.H2>
        <T.P mb="4">
          We will share your personal data with third parties where required by
          law, where it is necessary to administer the working relationship with
          you or where we have another legitimate interest in doing so.
        </T.P>
        <T.P mb="2">
          We may share the personal data that you provide to us with the
          following third parties:
        </T.P>
        <T.Ul ml="6">
          <T.Li listStyle="disc">
            partner organisations, including referral partners and other
            specialist service providers, to facilitate the provision of our
            educational programmes
          </T.Li>
          <T.Li listStyle="disc">
            the police or the local authorities where we consider this necessary
            to protect the safety of yourself or others
          </T.Li>
        </T.Ul>
        <T.P mb="4" mt="4">
          All our third party service providers are required to take appropriate
          security measures to protect your personal data in line with our
          policies. We do not allow our third party service providers to use
          your personal data for their own purposes. We only permit them to
          process your personal data for specified purposes and in accordance
          with our instructions.
        </T.P>
        <T.P mb="4">
          Your personal data is not disclosed to third parties for marketing or
          advertising purposes unless with your express and written consent.
        </T.P>
        <T.P>We will not transfer your personal data outside the EEA.</T.P>
      </section>
      <section id="section-10">
        <T.H2 color="secondaryMain" bold mb="5" mt="7">
          10. How we keep your personal data safe
        </T.H2>
        <T.P mb="4">
          We have put in place appropriate technical and organisational measures
          to protect the personal data you provide to us. Although we take
          appropriate measures to prevent personal data from being lost,
          destroyed, damaged or unlawfully processed, we cannot guarantee this
          will not occur. Further details of these measures may be obtained upon
          request.
        </T.P>
        <T.P mb="2">
          To protect personal data, we use the following security measures:
        </T.P>
        <T.Ul ml="6">
          <T.Li listStyle="disc">
            A specialist database to keep the personal data of current and
            former learners, mentors, prison and other partners’ staff,
            volunteers, supporters, customers, donors, trustees and employees
            private. Each user of our Shannon Trust privacy notice Page | 9
            database has a certain level of access assessed as appropriate to
            facilitate their work. This access is reviewed regularly and
            provided following a thorough induction and the signing of the
            relevant policies.
          </T.Li>
          <T.Li listStyle="disc">
            Strong passwords – we use unique passwords with multiple numbers,
            symbols, and letters. These passwords are used to protect documents
            that include personal data. The password provides an extra layer of
            protection to secure personal data.
          </T.Li>
          <T.Li listStyle="disc">
            Secure networks – all of our employees make sure their system is
            secure when accessing personal data by using a firewall, password
            protecting the network, and using a virtual private network (VPN) to
            keep data secure
          </T.Li>
          <T.Li listStyle="disc">
            Awareness – cybersecurity is a top priority when handling sensitive
            personal data. Hackers create new and innovative threats every day.
            If you receive an email asking you to click on a suspicious link or
            provide private personal data about you or someone else, do not
            click on it. Report the threat to us as soon as possible and delete
            the email.
          </T.Li>
        </T.Ul>
        <T.P mb="4">
          In addition, we limit access to your personal data to third parties
          who have a business need to know. They will only process your personal
          data on our instructions, are subject to a duty of confidentiality,
          and are also required to take appropriate measures to protect that
          personal data.
        </T.P>
        <T.P>
          We have put in place procedures to deal with any suspected data
          security breach and will notify you and any applicable regulator of a
          suspected breach where we are legally required to do so.
        </T.P>
      </section>
      <section id="section-11">
        <T.H2 color="secondaryMain" bold mb="5" mt="7">
          11. How long we keep your personal data for{' '}
        </T.H2>
        <T.P mb="4">
          We retain different types of data for differing periods, but will only
          retain your personal data for as long as necessary to fulfil the
          purposes we collected it for, including for the purposes of satisfying
          any legal, accounting, or reporting requirements.
        </T.P>
        <T.P mb="2">
          The criteria we use to determine whether we should retain your data
          and how long for includes:
        </T.P>
        <T.Ul ml="6">
          <T.Li listStyle="disc">
            the amount, nature and sensitivity of the personal data
          </T.Li>
          <T.Li listStyle="disc">
            the potential risk of harm from unauthorised use or disclosure of
            your personal data
          </T.Li>
          <T.Li listStyle="disc">
            the purposes for which we process your personal data and whether we
            can achieve those purposes through other means
          </T.Li>
          <T.Li listStyle="disc">any applicable legal requirements</T.Li>
          <T.Li listStyle="disc">
            the length and extent of your engagement with our programmes
          </T.Li>
          <T.Li listStyle="disc">our legal obligations</T.Li>
        </T.Ul>
        <T.P mt="4">
          We may archive part of or all your personal data, or retain it on our
          financial systems but delete all or part of it from our other
          databases. On removal, we may anonymise Shannon Trust privacy notice
          Page | 10 parts of your personal data – particularly following a
          request for suppression or deletion of your personal data – to ensure
          we do not re-enter your personal data to our database unless you have
          requested us to do so.
        </T.P>
      </section>
      <section id="section-12">
        <T.H2 color="secondaryMain" bold mb="5" mt="7">
          12. Your rights in connection with personal data
        </T.H2>
        <T.P mb="2">The GDPR provides you with the rights to:</T.P>
        <T.Ul ml="6">
          <T.Li listStyle="disc">
            be informed about the personal data we process about you
          </T.Li>
          <T.Li listStyle="disc">
            request access to the personal data we process about you – this is
            commonly known as a "data subject access request" and enables you to
            receive a copy of the personal data we hold about you and to check
            that we are lawfully processing it
          </T.Li>
          <T.Li listStyle="disc">
            request correction of your personal data – this enables you to have
            any incomplete or inaccurate information we hold about you corrected
          </T.Li>
          <T.Li listStyle="disc">
            request erasure of your personal data in certain circumstances,
            where there is no good reason for us continuing to process it – you
            also have the right to ask us to delete or remove your personal data
            where you have exercised your right to object to processing (see
            below)
          </T.Li>
          <T.Li listStyle="disc">
            request the restriction of processing – this enables you to ask us
            to suspend the processing of personal data about you, for example if
            you want us to establish its accuracy or the reason for processing
            it
          </T.Li>
          <T.Li listStyle="disc">
            request the transfer of your personal data to another party
          </T.Li>
          <T.Li listStyle="disc">
            object to the processing of your personal data where we are relying
            on a legitimate interest (or those of a third party) and there is
            something about your particular situation which makes you want to
            object to processing on this ground – you also have the right to
            object where we are processing your personal data for direct
            marketing purposes
          </T.Li>
          <T.Li listStyle="disc">
            not be subjected to automated decision making and profiling
          </T.Li>
        </T.Ul>
        <T.P mb="4" mt="4">
          It is important that the personal data we hold about you is accurate
          and current. Please keep us informed if your personal data changes
          during your relationship with us.
        </T.P>
        <T.P mb="4">
          If you want to review, verify, correct or request erasure of your
          personal data, object to the processing of your personal data, or
          request that we transfer a copy of your personal data to another
          party, please contact us in writing using the contact details at the
          end of this privacy notice.
        </T.P>
        <T.P mb="4">
          You will not have to pay a fee to access your personal data (or to
          exercise any of the other rights). However, we may charge a reasonable
          fee if your request for access is clearly unfounded or excessive.
          Alternatively, we may refuse to comply with the request in such
          circumstances.
        </T.P>
        <T.P>
          We may need to request specific information from you to help us
          confirm your identity and ensure your right to access the information
          (or to exercise any of your Shannon Trust privacy notice Page | 11
          other rights). This is another appropriate security measure to ensure
          that personal data is not disclosed to any person who has no right to
          receive it.
        </T.P>
      </section>
      <section id="section-13">
        <T.H2 color="secondaryMain" bold mb="5" mt="7">
          13. Changes to this privacy notice
        </T.H2>
        <T.P>
          We reserve the right to update this privacy notice at any time, and
          any changes we make to our privacy notice will be notified to you as
          soon as is reasonably practical, and will be posted on our website.
        </T.P>
      </section>
      <footer>
        <T.P mb="4" mt="4">
          Please send any questions, comments or requests relating to this
          privacy notice to{' '}
          <T.Link href="mailto:chris@shannontrust.org.uk">
            chris@shannontrust.org.uk
          </T.Link>
        </T.P>
        <T.P>Date of Issue: September 2021</T.P>
      </footer>
    </S.Wrapper>
  );
};

export default PrivacyAndPolicy;
