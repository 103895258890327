import Stack from '@mui/material/Stack';
import Table from '../Table';
import * as T from '../Typography';
import { InlineCopy } from '../Copy';

import * as S from './style';
import CopyInviteLink from '../CopyInviteLink';

const InvitesTable = ({
  dataSource,
  hideErrorColumn,
  hideInviteLink,
  showCoachUniqueId,
  showCoachPassword,
}) => {
  const columns = [
    {
      title: <T.P weight="bold">Status</T.P>,
      dataIndex: 'status',
      key: 'status',
      width: '10%',
      render: (status, record) => (
        <Stack spacing="16px">
          <S.Tag
            bgColor={status === 'Error' ? 'tertiaryMain' : 'primaryMain'}
            key={status}
          >
            {status}
          </S.Tag>
          {record.isMultiOrganisationsFacilitator && (
            <S.Tag bgColor={'warningLight'} fontColor="neutral90" key={status}>
              Multi-organisations facilitator
            </S.Tag>
          )}
        </Stack>
      ),
    },
    {
      title: (
        <T.P weight="bold">
          {showCoachUniqueId ? 'Unique Coach ID' : 'Email'}
        </T.P>
      ),
      dataIndex: showCoachUniqueId ? 'coachUniqueId' : 'email',
      key: showCoachUniqueId ? 'coachUniqueId' : 'email',
      render: (value, dd) => {
        if (showCoachUniqueId) {
          return <InlineCopy text={`${value}`} textToCopy={value} />;
        }
        return <T.P>{value}</T.P>;
      },
      width: '10%',
    },
    {
      title: <T.P weight="bold">Invite link</T.P>,
      dataIndex: 'inviteLink',
      key: 'inviteLink',
      width: '10%',

      render: (inviteLink, record) => {
        if (record.isMultiOrganisationsFacilitator) {
          return <T.P>-</T.P>;
        } else {
          return inviteLink === '-' ? (
            <T.P>{inviteLink}</T.P>
          ) : (
            <T.Link color="neutralMain" underline>
              <CopyInviteLink textToCopy={inviteLink} />
            </T.Link>
          );
        }
      },
    },
    {
      title: <T.P weight="bold">Reason for error</T.P>,
      dataIndex: 'error',
      key: 'error',
      render: (error) => <T.P>{error}</T.P>,
      width: '40%',
    },
  ];

  if (showCoachPassword) {
    columns.push({
      title: <T.P weight="bold">Coach Password</T.P>,
      dataIndex: 'password',
      key: 'password',
      render: (password) => (
        <T.P>{<InlineCopy text={`${password}`} textToCopy={password} />}</T.P>
      ),
      width: '10%',
    });
  }

  return (
    <Table
      columns={columns
        .filter((column) => !hideErrorColumn || column.key !== 'error')
        .filter((column) => !hideInviteLink || column.key !== 'inviteLink')}
      dataSource={dataSource}
      pagination={{
        showSizeChanger: false,
        pageSize: 5,
      }}
    />
  );
};

export default InvitesTable;
