import * as S from './style';

const NumbersWheel = ({
  innerNumbers = [],
  outerNumbers = [],
  centerValue,
}) => {
  const totalSlices = innerNumbers.length;
  const sliceAngle = 360 / totalSlices;
  // Adjusted rotation to display numbers in the center of the slice
  const getNumberRotation = (i) => sliceAngle * i + sliceAngle / 2 - 90;

  return (
    <S.WheelContainer>
      {outerNumbers.map((_, index) => (
        <S.OuterSliceBorder key={index} rotation={sliceAngle * index} />
      ))}

      <S.OuterCircle>
        {outerNumbers.map(
          (number, index) =>
            number !== null && (
              <S.Number
                key={`outer-${index}`}
                rotation={getNumberRotation(index)}
                circleType="outer"
              >
                {number}
              </S.Number>
            )
        )}
      </S.OuterCircle>

      <S.InnerCircle>
        {innerNumbers.map(
          (number, index) =>
            number !== null && (
              <S.Number
                key={`inner-${index}`}
                rotation={getNumberRotation(index)}
                circleType="inner"
              >
                {number}
              </S.Number>
            )
        )}
      </S.InnerCircle>

      <S.CenterText centerValue={centerValue}>{centerValue}</S.CenterText>
    </S.WheelContainer>
  );
};

export default NumbersWheel;
