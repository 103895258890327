import styled from '@emotion/styled';

import { Col } from '../../components/Grid';
import { Typography as T } from '../../components';
import {
  BasicInput,
  Textarea,
  Radio,
  Dropdown,
  Checkbox,
} from '../../components/Inputs';

const StyledP = styled(T.P)`
  line-height: 28px !important;
  font-size: 1 !important;
  color: ${({ theme }) => theme.colors.neutral90} !important;
  padding: 4.5px 0 !important;
  padding-top: 0 !important;
  padding-left: ${({ theme }) => theme.spacings[2]} !important;
`;

const Question = ({ question, setAnswer, error }) => {
  switch (question.type) {
    case 'SHORT_ANSWER':
      return (
        <Col
          w={[
            4,
            question?.label?.length >= 100 ? 12 : 6,
            question?.label?.length >= 100 ? 12 : 6,
          ]}
          mt="6"
        >
          <BasicInput
            label={question.label}
            name={question.key || question.label}
            value={question.answer}
            handleChange={(value) => setAnswer(value)}
            placeholder="Add answer..."
            optional={!question.required}
            error={error?.answer}
          />
        </Col>
      );
    case 'PARAGRAPH':
      return (
        <Col w={[4, 12, 12]} mt="6">
          <Textarea
            label={question.label}
            name={question.key || question.label}
            value={question.answer}
            handleChange={(value) => setAnswer(value)}
            placeholder="Add answer..."
            optional={!question.required}
            error={error?.answer}
          />
        </Col>
      );
    case 'MULTIPLE_CHOICE':
      return (
        <Col w={[4, 12, 12]} mt="6">
          <Dropdown
            label={question.label}
            name={question.key || question.label}
            handleChange={(value) => setAnswer(value)}
            options={question.options}
            optional={!question.required}
            selected={
              question?.answer?.length
                ? question?.answer?.map((a) => ({
                    label: a,
                    value: a,
                  }))
                : []
            }
            multi
            error={error?.answer}
          />
        </Col>
      );
    case 'SINGLE_CHOICE':
      return (
        <Col w={[4, 12, 12]} mt="6">
          <Radio
            label={question.label}
            name={question.key || question.label}
            value={question?.answer}
            onChange={(value) => setAnswer(value)}
            options={question.options}
            optional={!question.required}
            error={error?.answer}
          />
        </Col>
      );
    case 'CHECKBOXES':
      return (
        <Col w={[4, 12, 12]} mt="6">
          <T.P weight="bold" color="neutralMain" mb="2">
            {question.label}
          </T.P>

          {question.options.map((option, i) => (
            <Checkbox
              label={<StyledP>{option.label}</StyledP>}
              checked={question.answer?.includes(option.value)}
              handleChange={(checked) => {
                if (checked) {
                  setAnswer(
                    question?.answer?.length
                      ? [...question.answer, option.value]
                      : [option.value]
                  );
                } else {
                  setAnswer(
                    question.answer.filter((ans) => ans !== option.value)
                  );
                }
              }}
              plain
              error={error?.options?.[i]?.isAnswered}
            />
          ))}
        </Col>
      );

    default:
      return null;
  }
};

export default Question;
