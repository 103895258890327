import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import * as T from '../../../../components/Typography';
import TryAgainModal from '../../../../components/ModalNew/TryAgainModal';
import { PlayButton } from '../../../../components/Button';
import StepFooterButtons from '../StepFooterButtons';
import QuestionRow from './QuestionRow';
import * as S from './style';
import * as SS from '../shared/style';

const getInitialAnswers = (options) => {
  const _answers = options.reduce((acc, q) => {
    acc[q.id] = '';
    return acc;
  }, {});

  return _answers;
};

const MultipleQuestionsWithEndings = ({
  data: { textAudio, textAudioUrl, options = [] } = {},
  imageUrl,
  handleNext,
  stuckAtReview,
  disabled,
  preview,
}) => {
  const [showTryAgain, setShowTryAgain] = useState(false);
  const [answers, setAnswers] = useState(() => getInitialAnswers(options));

  const location = useLocation();
  useEffect(() => {
    if (options?.length) {
      setAnswers(getInitialAnswers(options));
    }

    // To avoid unnecessary re-renders we only want this to work when the pathname changes,
    // if react-query still refetching step is handled in the parent (isStepLoading)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const handleClick = () => {
    const allCorrect = options?.every(
      (option) => answers[option.id] === option.answer
    );
    if (allCorrect) {
      handleNext();
    } else {
      setShowTryAgain(true);
    }
  };

  const allAnswersAreFilled = Object.keys(answers).every((answerKey) => {
    return answers[answerKey] !== '';
  });

  return (
    <S.Wrapper display={'flex'}>
      {textAudio ? (
        <SS.TextAudioWrapper ml={3} mlM={1} mlT={2} mt={1} mb={7}>
          {textAudioUrl ? (
            <>
              <PlayButton
                audioUrl={textAudioUrl}
                width="56px"
                height="56px"
                iconProps={{ width: '32px', height: '32px' }}
                fallbackText={textAudio}
              />
              <T.P ml={3} weight="regular" size="med">
                {textAudio}
              </T.P>
            </>
          ) : (
            <T.P weight="regular" size="med">
              {textAudio}
            </T.P>
          )}
        </SS.TextAudioWrapper>
      ) : null}

      {imageUrl && (
        <SS.ImageWrapper>
          <SS.Img src={imageUrl} alt={textAudio} />
        </SS.ImageWrapper>
      )}
      {options?.length > 0 &&
        options.map((question, index) => {
          return (
            <QuestionRow
              key={question.id}
              sectionIndex={index}
              question={question}
              setAnswers={setAnswers}
              answers={answers}
            />
          );
        })}

      <StepFooterButtons
        stuckAtReview={stuckAtReview}
        handleNext={handleClick}
        preview={preview}
        disabled={disabled || preview || !allAnswersAreFilled}
        enableNext={allAnswersAreFilled}
      />
      <TryAgainModal
        aria-modal="true"
        isModalVisible={showTryAgain}
        setIsModalVisible={setShowTryAgain}
      />
    </S.Wrapper>
  );
};

export default MultipleQuestionsWithEndings;
