import * as T from 'components/Typography';
import { useCallback, useEffect } from 'react';
import * as S from './style';

import { useState } from 'react';
import InfoCard from 'components/InfoCard';
import AcceptedModal from './AcceptedModal';
import { useCoachConfirmsLearner } from 'api-calls/learners.queries';
import { queryClient } from 'api-calls/queryClient';
import { useSiteSettings } from 'context/site-settings';

const NewLearners = ({ newLearners }) => {
  const [notes, setNotes] = useState('');
  const [acceptSuccessfullyModalVisible, setAcceptSuccessfullyModalVisible] =
    useState(false);
  const [rejectConfirmationVisible, setRejectConfirmationVisible] =
    useState(false);
  const [learnerId, setLearnerId] = useState(null);
  const { isWorkingInPrisonEnvironment } = useSiteSettings();
  const {
    mutate: confirmLearner,
    error: confirmLearnerHttpError,
    isLoading,
  } = useCoachConfirmsLearner();

  const handleConfirm = useCallback(
    (bool, learnerId, _notes) => {
      confirmLearner(
        {
          learnerId,
          confirm: bool,
          notes: _notes,
        },
        {
          onSuccess: () => {
            if (bool) {
              if (isWorkingInPrisonEnvironment) {
                queryClient.invalidateQueries([
                  'GET_LEARNERS',
                  'GET_LEARNERS_COUNT',
                ]);

                return;
              }
              setAcceptSuccessfullyModalVisible(true);
            } else {
              setRejectConfirmationVisible(false);
              queryClient.invalidateQueries([
                'GET_LEARNERS',
                'GET_LEARNERS_COUNT',
              ]);
            }
          },
        }
      );
    },
    [confirmLearner, isWorkingInPrisonEnvironment]
  );

  const onClickAccept = useCallback(
    (learnerId) => {
      handleConfirm(true, learnerId);
    },
    [handleConfirm]
  );

  const onClickReject = useCallback(
    (learnerId) => {
      setLearnerId(learnerId);
      setRejectConfirmationVisible(true);
    },
    [setLearnerId, setRejectConfirmationVisible]
  );

  const onRejectYes = useCallback(() => {
    handleConfirm(false, learnerId, notes);
  }, [handleConfirm, learnerId, notes]);

  const onIUnderstand = useCallback(() => {
    setRejectConfirmationVisible(false);

    queryClient.invalidateQueries(['GET_LEARNERS', 'GET_LEARNERS_COUNT']);
  }, []);

  useEffect(() => {
    setNotes('');
  }, [acceptSuccessfullyModalVisible, rejectConfirmationVisible]);

  return (
    <>
      {newLearners?.length ? (
        <S.CardsWrapper>
          <T.H2 mb="4">New Learners</T.H2>

          {newLearners?.map((learner, idx) => (
            <InfoCard
              key={idx}
              mb={idx === newLearners.length - 1 ? '0' : '3'}
              firstColumnLabel="Learner ID"
              firstColumnValue={learner.learnerUniqueId}
              secondColumnLabel="Name"
              secondColumnValue={learner.firstName}
              buttonText={'Confirm'}
              actionType={'confirm'}
              learnerId={learner.id}
              onClickAccept={() => onClickAccept(learner.id)}
              onClickReject={() => onClickReject(learner.id)}
              isLoading={isLoading}
            />
          ))}
        </S.CardsWrapper>
      ) : null}
      <AcceptedModal
        acceptSuccessfullyModalVisible={acceptSuccessfullyModalVisible}
        rejectConfirmationVisible={rejectConfirmationVisible}
        setRejectConfirmationVisible={setRejectConfirmationVisible}
        isLoading={isLoading}
        confirmLearnerHttpError={confirmLearnerHttpError}
        onIUnderstand={onIUnderstand}
        onRejectYes={onRejectYes}
        notes={notes}
        setNotes={setNotes}
      />
    </>
  );
};

export default NewLearners;
