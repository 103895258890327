import { pictogramShapes } from '../../../../constants';
import * as S from './style';

export const checkIsValidSelection = ({
  numberOfShapesToSelect,
  shapes,
  keyValue = 1,
}) => {
  const selectedShapes = shapes.filter((shape) => shape.isSelected);

  const selectedTotal = selectedShapes.length * keyValue;

  if (selectedTotal !== numberOfShapesToSelect) {
    return false;
  }

  const requiredShapesCount = Math.ceil(numberOfShapesToSelect / keyValue);

  const expectedIds = shapes
    .slice(0, requiredShapesCount)
    .map((shape) => shape.id);
  const selectedIds = selectedShapes.map((shape) => shape.id);

  // Check if IDs match the expected order
  return selectedIds.every((id, index) => id === expectedIds[index]);
};

export const getCorrectShape = (shape) => {
  if (shape === pictogramShapes.SQUARE) return S.Square;
  if (shape === pictogramShapes.TRIANGLE) return S.Triangle;
  return S.Circle;
};

export const getSelectedShapes = ({
  numberOfShapesToSelect,
  shapes,
  keyValue,
}) => {
  const requiredShapesCount = Math.ceil(numberOfShapesToSelect / keyValue);

  const updatedShapes = shapes.map((s, index) =>
    index < requiredShapesCount
      ? { ...s, isSelected: true }
      : { ...s, isSelected: false }
  );

  return updatedShapes;
};
