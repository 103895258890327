import { useRef } from 'react';
import { Typography as T } from '../../../../../components';
import { BasicInput, Dropdown } from '../../../../../components/Inputs';
import { MediaKeyInput } from '../../../../components/MediaKeyInput';
import { mediaTypes, fractionOperationTypes } from '../../../../../constants';
import { useStepForm } from 'CMS/Providers/StepFormProvider';
import { convertSnakeCaseToSpaces } from 'helpers';
import * as S from './style';

export { validationSchema } from './validation';

const initialData = {
  textAudio: '',
  textAudioKey: '',
  numberOfCircles: 1,
  circles: [{ id: 0, isSelected: false }],
  fractionOperationType: fractionOperationTypes.FRACTION_OF,
  topNumber: 0,
  bottomNumber: 0,
  decimalNumber: 0.0,
  fractionOfNumber: 0,
  correctAnswer: 0,
};

const Fractions = () => {
  const initialRef = useRef(initialData);
  const { state, updateDataFields, updateFormMetaData } = useStepForm(
    initialRef.current
  );
  const { data = initialData, validationErrs } = state;

  const handleNumberOfCirclesChange = ({ numberOfCircles = 1 }) => {
    if (numberOfCircles < 1 || numberOfCircles > 64) {
      updateFormMetaData({
        validationErrs: {
          ...validationErrs,
          numberOfCircles:
            numberOfCircles < 1
              ? 'Circles must be more than 1'
              : numberOfCircles > 64
              ? 'Circles rows maximum is 8 rows'
              : '',
        },
      });
      updateDataFields({ numberOfCircles });

      return;
    }

    const newCircles = Array.from({ length: numberOfCircles }).map(
      (_, circleIndex) => ({
        id: circleIndex,
        isSelected: data.circles?.[circleIndex]?.isSelected ?? false,
        circleIndex,
      })
    );

    updateDataFields({
      numberOfCircles,
      circles: newCircles,
    });
    updateFormMetaData({
      validationErrs: {
        ...validationErrs,
        numberOfCircles: '',
      },
    });
  };

  const fractionOperationOptions = Object.keys(fractionOperationTypes).map(
    (e) => ({
      label: convertSnakeCaseToSpaces(e),
      value: e,
    })
  );

  return (
    <div>
      <BasicInput
        label="Explainer Text"
        value={data.textAudio}
        handleChange={(textAudio) => updateDataFields({ textAudio })}
        m={{ mt: 8 }}
        error={validationErrs?.textAudio}
      />
      <MediaKeyInput
        type={mediaTypes.TEXT_AUDIO}
        label="Explainer Text file Key"
        value={data.textAudioKey}
        handleChange={(textAudioKey) => updateDataFields({ textAudioKey })}
        m={{ mt: 5 }}
        error={validationErrs?.textAudioKey}
      />

      <BasicInput
        label="Number Of Circles"
        value={data.numberOfCircles}
        handleChange={(numberOfCircles) => {
          handleNumberOfCirclesChange({
            numberOfCircles: Number(numberOfCircles),
          });
        }}
        m={{ mt: 8 }}
        type="number"
        error={validationErrs.numberOfCircles}
      />

      <Dropdown
        label="Choose a fraction operation type"
        options={fractionOperationOptions}
        selected={
          data.fractionOperationType
            ? {
                value: data.fractionOperationType,
                label: convertSnakeCaseToSpaces(data.fractionOperationType),
              }
            : initialData.type
        }
        handleChange={(fractionOperationType) =>
          updateDataFields({ fractionOperationType })
        }
        error={validationErrs?.fractionOperationType}
        m={{ mt: 5 }}
      />

      <T.P mt={8} mb={4} size="large" weight="bold">
        Fraction Operation
      </T.P>

      <S.QuestionWrapper>
        {data.fractionOperationType === fractionOperationTypes.FRACTION_OF ? (
          <div>
            <BasicInput
              placeholder={0}
              value={data?.topNumber}
              handleChange={(topNumber) => updateDataFields({ topNumber })}
              bgColor="neutralSurface"
              borderColor=""
              error={validationErrs?.topNumber}
              hideErrorMsg
              textAlign="center"
              type="number"
            />
            <S.Divider />
            <BasicInput
              placeholder={0}
              value={data?.bottomNumber}
              handleChange={(bottomNumber) =>
                updateDataFields({ bottomNumber })
              }
              bgColor="neutralSurface"
              borderColor=""
              error={validationErrs?.bottomNumber}
              hideErrorMsg
              textAlign="center"
              type="number"
            />
          </div>
        ) : (
          <BasicInput
            placeholder={0}
            value={data?.decimalNumber}
            handleChange={(decimalNumber) =>
              updateDataFields({ decimalNumber })
            }
            bgColor="neutralSurface"
            borderColor=""
            error={validationErrs?.decimalNumber}
            hideErrorMsg
            textAlign="center"
            type="number"
            w="35%"
          />
        )}

        <T.P mx={3}>of</T.P>

        <BasicInput
          placeholder={0}
          value={data?.fractionOfNumber}
          handleChange={(fractionOfNumber) =>
            updateDataFields({ fractionOfNumber })
          }
          bgColor="neutralSurface"
          borderColor=""
          error={validationErrs?.fraction?.fractionOfNumber}
          hideErrorMsg
          textAlign="center"
          type="number"
          w="35%"
        />

        <T.P mx={3}>=</T.P>

        <BasicInput
          placeholder={0}
          value={data?.correctAnswer}
          handleChange={(correctAnswer) =>
            updateDataFields({ correctAnswer: Number(correctAnswer) })
          }
          borderColor="primaryMain"
          error={validationErrs?.correctAnswer}
          hideErrorMsg
          textAlign="center"
          type="number"
          w="35%"
        />
      </S.QuestionWrapper>
    </div>
  );
};

export default Fractions;
