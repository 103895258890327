import styled from '@emotion/styled';

export const InputsWrapper = styled.div`
  background: ${({ theme }) => theme.colors.neutralLightNew};
  padding: ${({ theme }) => `${theme.spacings[4]} ${theme.spacings[5]}`};
  margin-top: ${({ theme }) => theme.spacings[4]};
  margin-bottom: ${({ theme }) => theme.spacings[4]};
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(2, auto);
  gap: 8px;
  & > * {
    max-width: 60px;
  }
`;
