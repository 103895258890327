import { useSiteSettings } from 'context/site-settings';
import { useMemo } from 'react';
import { getTrainingData, getTrainingContent } from '../training-content';

const useFacilitatorTrainingData = () => {
  const { courseName, courseCode } = useSiteSettings();

  const trainingData = useMemo(
    () => getTrainingData({ courseName }),
    [courseName]
  );

  const trainingContent = useMemo(
    () => getTrainingContent({ courseName, courseCode }),
    [courseCode, courseName]
  );

  return { trainingData, trainingContent };
};

export default useFacilitatorTrainingData;
