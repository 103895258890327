import { fields, createSchema, validate as _validate } from '..';

const {
  firstName,
  lastName,
  email,
  requiredText,
  optionalText,
  requiredDate,
  optionalDate,
  requiredBoolean,
  requiredSubdomain,
} = fields;

const commonFields = {
  firstName,
  lastName,
  name: requiredText,
  sfAccountId: requiredText,
  email,
  isWorkingInPrison: requiredBoolean,
  isTpdCourse: requiredBoolean,
  isCmidCourse: requiredBoolean,
};

const defaultSchema = createSchema({
  contractEndDate: requiredDate,
  paymentPlan: requiredText,
  ...commonFields,
});

const inPrisonEnvSchema = createSchema({
  contractEndDate: optionalDate,
  paymentPlan: optionalText,
  subdomain: requiredSubdomain,
  isWorkingInPrisonEnvironment: requiredBoolean,
  ...commonFields,
});

const validate = (data) => {
  let schema = defaultSchema;
  if (data.isWorkingInPrisonEnvironment) {
    schema = inPrisonEnvSchema;
  }

  return _validate(schema, data);
};

export default validate;
