import moment from 'moment';
import * as T from 'components/Typography';
import * as S from './style';
import { navRoutes } from '../../../constants';
import InlineCopy from '../../../components/Copy/InlineCopy';
import { Icon } from 'components';
import { organisationStatuses } from '../../../constants';
import {
  getOrganisationCustomUrl,
  getOrganisationInviteUrl,
} from 'helpers/get-organisations-links';

const getOrganisationTableColumn = ({ onActivateAndDeactivate }) => [
  {
    title: <T.P weight="bold">Organisation</T.P>,
    dataIndex: 'name',
    key: 'name',
    width: '20%',
    render: (text, record) => {
      const customLink = getOrganisationCustomUrl({
        subdomain: record.subdomain,
      });

      return (
        <S.SignupDateWrapper>
          <T.P color="neutralMain" mb={2}>
            {text}
          </T.P>
          {record.isWorkingInPrisonEnvironment && (
            <S.LinkWrapper width="200px">
              <S.CopyWrapper>
                <InlineCopy textToCopy={customLink} />
              </S.CopyWrapper>
              <T.Link to={customLink} external ellipses title={customLink}>
                {customLink.split('//')[1]}
              </T.Link>
            </S.LinkWrapper>
          )}
        </S.SignupDateWrapper>
      );
    },
  },
  {
    title: (
      <T.P
        style={{
          whiteSpace: 'nowrap',
        }}
        weight="bold"
      >
        Account email
      </T.P>
    ),
    dataIndex: 'email',
    width: '20%',
    key: 'email',
    render: (text, record) => {
      if (record.status === organisationStatuses.DELETED) return 'Deleted';
      return text;
    },
  },

  {
    title: (
      <T.P
        style={{
          whiteSpace: 'nowrap',
        }}
        weight="bold"
      >
        Account created
      </T.P>
    ),
    dataIndex: 'signupDate',
    key: 'signupDate',
    render: (text, record) => {
      if (text) return moment(text).format('DD/MM/YYYY');
      if (record.status === organisationStatuses.INVITED) {
        const inviteLink = getOrganisationInviteUrl({
          inviteToken: record.inviteToken,
          subdomain: record.subdomain,
          isWorkingInPrisonEnvironment: record.isWorkingInPrisonEnvironment,
        });

        return (
          <S.SignupDateWrapper>
            <T.P color="neutralMain" mb={2}>
              Awaiting sign up
            </T.P>
            <InlineCopy text={'Copy invite link'} textToCopy={inviteLink} />
          </S.SignupDateWrapper>
        );
      } else {
        return <T.P color="neutralMain">N/A</T.P>;
      }
    },
  },
  {
    title: (
      <T.P
        style={{
          whiteSpace: 'nowrap',
        }}
        weight="bold"
      >
        Works in prisons
      </T.P>
    ),
    dataIndex: 'isWorkingInPrison',
    key: 'isWorkingInPrison',
    render: (text) => {
      if (text) return <T.P color="neutralMain">Yes</T.P>;
      return <T.P color="neutralMain">No</T.P>;
    },
  },
  {
    title: <T.P weight="bold">Actions</T.P>,
    dataIndex: 'id',
    key: 'id',
    render: (text, record) =>
      record.status === organisationStatuses.DELETED ? (
        'N/A'
      ) : (
        <S.ActionsWrapper>
          <S.Link
            to={navRoutes.ADMIN.EDIT_ORGANISATION.replace(':id', record.id)}
            color="#051730"
            weight="bold"
          >
            <S.EditDiv>
              <Icon icon={'edit'} color="primaryMain" />
            </S.EditDiv>
            Edit
          </S.Link>
          <S.ActivationButton
            onClick={async () => {
              await onActivateAndDeactivate(
                record.id,
                record.status === organisationStatuses.DEACTIVATED
                  ? organisationStatuses.ACTIVATED
                  : organisationStatuses.DEACTIVATED
              );
            }}
          >
            <Icon
              icon={
                record?.status === organisationStatuses.DEACTIVATED
                  ? 'toggleDeActive'
                  : 'toggleActive'
              }
              color={
                record?.status === organisationStatuses.DEACTIVATED
                  ? 'neutral50'
                  : 'primaryMain'
              }
              width="39"
              height="24"
              mr={2}
            />
            Active
          </S.ActivationButton>
        </S.ActionsWrapper>
      ),
  },
];

export default getOrganisationTableColumn;
