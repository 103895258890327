import moment from 'moment';
import { string, number, boolean, array, object, date } from 'yup';
import * as errMsgs from './err-msgs';
import './custom-functions';
import {
  sessionPreferencesTypes,
  voicePreferencesTypes,
  preferredSessionsTypes,
} from '../constants/data-types';
import { URLregex, URLSlugRegex, twoDigitsRegex } from './regex';
// eslint-disable-next-line no-unused-vars
const hexRegex = /^#(?:[0-9a-fA-F]{3}){1,2}$/;

export const requiredText = string()
  .typeError(errMsgs.DEFAULT_REQUIRED)
  .required(errMsgs.DEFAULT_REQUIRED);

export const requiredRadio = string()
  .typeError(errMsgs.MUST_SELECT_OPTION)
  .required(errMsgs.MUST_SELECT_OPTION);

export const requiredRadioNumber = number()
  .min(1, errMsgs.MUST_SELECT_OPTION)
  .typeError(errMsgs.MUST_SELECT_OPTION)
  .required(errMsgs.MUST_SELECT_OPTION);

export const learnerUniqueId = string()
  .length(6, errMsgs.LENGTH_6)
  .typeError(errMsgs.DEFAULT_REQUIRED)
  .required(errMsgs.DEFAULT_REQUIRED);

export const organisationName = string()
  .min(3, errMsgs.DEFAULT_REQUIRED)
  .max(40, errMsgs.TOO_LONG_MAX_40)
  .required(errMsgs.DEFAULT_REQUIRED);

export const firstName = string()
  .min(1, errMsgs.DEFAULT_REQUIRED)
  .max(20, errMsgs.TOO_LONG_MAX_20)
  .typeError(errMsgs.DEFAULT_REQUIRED)
  .required(errMsgs.DEFAULT_REQUIRED);

export const city = string()
  .min(1, errMsgs.DEFAULT_REQUIRED)
  .max(20, errMsgs.TOO_LONG_MAX_20)
  .typeError(errMsgs.DEFAULT_REQUIRED)
  .required(errMsgs.DEFAULT_REQUIRED);

export const lastName = string()
  .min(1, errMsgs.DEFAULT_REQUIRED)
  .max(20, errMsgs.TOO_LONG_MAX_20)
  .typeError(errMsgs.DEFAULT_REQUIRED)
  .required(errMsgs.DEFAULT_REQUIRED);

export const email = string()
  .email(errMsgs.INVALID_EMAIL)
  .max(100, errMsgs.TOO_LONG_MAX_100)
  .required(errMsgs.DEFAULT_REQUIRED)
  .typeError(errMsgs.DEFAULT_REQUIRED);

export const password = string()
  .matches(
    /^(?=[^A-Z\s]*[A-Z])(?=[^a-z\s]*[a-z])(?=[^\d\s]*\d)(?=\w*[\W_])\S{8,}$/gm,
    errMsgs.SHORT_PASSWORD
  )
  .required(errMsgs.DEFAULT_REQUIRED);

export const loginPassword = string().required(errMsgs.DEFAULT_REQUIRED);

export const postcode = string()
  .required(errMsgs.DEFAULT_REQUIRED)
  .min(6, errMsgs.TOO_SHORT_MIN_5)
  .max(8, errMsgs.TOO_LONG_MAX_7)
  .matches(
    /\b(([a-z][0-9]{1,2})|(([a-z][a-hj-y][0-9]{1,2})|(([a-z][0-9][a-z])|([a-z][a-hj-y][0-9]?[a-z])))) [0-9][a-z]{2}\b/gi,
    errMsgs.INVALID_POSTCODE
  );

export const agreedOnTerms = boolean()
  .oneOf([true], errMsgs.SHOULD_AGREE_ON_TERMS)
  .required(errMsgs.DEFAULT_REQUIRED);

export const shouldBeTrue = boolean()
  .oneOf([true], errMsgs.SHOULD_BE_TICKED)
  .typeError(errMsgs.SHOULD_BE_TICKED)
  .required(errMsgs.SHOULD_BE_TICKED);

export const arrayOfIds = array()
  .of(number())
  .min(1)
  .required(errMsgs.DEFAULT_REQUIRED)
  .typeError(errMsgs.DEFAULT_REQUIRED);

export const optionalText = string()
  .typeError(errMsgs.DEFAULT_REQUIRED)
  .nullable();

export const urlRequired = string()
  .matches(URLregex, {
    message: errMsgs.INVALID_LINK,
  })
  .required(errMsgs.DEFAULT_REQUIRED);

export const urlOptional = string().matches(URLregex, {
  message: errMsgs.INVALID_LINK,
  excludeEmptyString: true,
});

export const urlSlug = string()
  .required(errMsgs.DEFAULT_REQUIRED)
  .matches(URLSlugRegex, {
    message: errMsgs.INVALID_LINK,
  })
  .required(errMsgs.DEFAULT_REQUIRED);

export const description = string()
  .min(4, errMsgs.TOO_SHORT_MIN_4)
  .max(500, errMsgs.TOO_LONG_MAX_500)
  .required(errMsgs.DEFAULT_REQUIRED);

export const descriptionOptional = string()
  .min(4, errMsgs.TOO_SHORT_MIN_4)
  .max(500, errMsgs.TOO_LONG_MAX_500)
  .nullable();

// SINGLE CONTENT FIELDS
export const title = string()
  .min(4, errMsgs.TOO_SHORT_MIN_4)
  .max(50, errMsgs.TOO_LONG_MAX_50)
  .required(errMsgs.DEFAULT_REQUIRED);

export const optionalArrayOfOptionalString = array().of(requiredText);

export const libraryContent = boolean()
  .oneOf([true, false])
  .required(errMsgs.DEFAULT_REQUIRED);

export const instructions = string()
  .min(4, errMsgs.TOO_SHORT_MIN_4)
  .max(1000, errMsgs.TOO_LONG_MAX_1000)
  .required(errMsgs.DEFAULT_REQUIRED);

export const link = string().matches(URLregex, {
  message: errMsgs.INVALID_LINK,
});

export const inviteToken = string()
  .length(8)
  .required(errMsgs.DEFAULT_REQUIRED);

export const content = array().of(
  object().shape({
    title,
    categories: optionalArrayOfOptionalString,
    libraryContent,
    instructions,
  })
);

export const phoneNumber = string()
  .phone()
  .typeError(errMsgs.DEFAULT_REQUIRED)
  .max(20, errMsgs.INVALID_PHONE)
  .required(errMsgs.DEFAULT_REQUIRED);

export const optionalPhoneNumber = string().when((value, schema) => {
  if (value) {
    return schema.phone().typeError(errMsgs.INVALID_PHONE);
  }
  return schema.nullable();
});
export const requiredArrayOfStrings = array()
  .of(string().required(errMsgs.DEFAULT_REQUIRED))
  .required();

export const interestsAtLeastOne = array()
  .of(string().required(errMsgs.DEFAULT_REQUIRED))
  .min(1)
  .required();

export const optionalCheckbox = boolean()
  .typeError(errMsgs.DEFAULT_REQUIRED)
  .nullable();

export const numberField = number()
  .typeError(errMsgs.DEFAULT_REQUIRED)
  .required(errMsgs.DEFAULT_REQUIRED);

export const textMax300Required = string()
  .min(4, errMsgs.TOO_SHORT_MIN_4)
  .max(300, errMsgs.TOO_LONG_MAX_300)
  .required(errMsgs.DEFAULT_REQUIRED);

// step form

export const contactLinks = array()
  .of(
    object().shape({
      type: requiredText,
      availability: requiredText,
      description: requiredText,
      link: string().when('type', {
        is: (v) => v === 'WEBCHAT_LINK',
        then: urlRequired,
        otherwise: string().nullable(),
      }),
      phoneNumber: string().when('type', {
        is: (v) => v === 'PHONE',
        then: phoneNumber,
        otherwise: string().nullable(),
      }),
      email: string().when('type', {
        is: (v) => v === 'EMAIL',
        then: email,
        otherwise: string().nullable(),
      }),
    })
  )
  .nullable();

export const birthDate = date()
  .test('DOB', errMsgs.INVALID_DATE_OF_BIRTH, (value) => {
    return moment().diff(moment(value), 'years') >= 18;
  })
  .typeError(errMsgs.INVALID_BIRTH_DATE)
  .required(errMsgs.DEFAULT_REQUIRED);

export const requiredDate = date()
  .typeError(errMsgs.INVALID_DATE)
  .required(errMsgs.DEFAULT_REQUIRED);

export const optionalDate = date().typeError(errMsgs.INVALID_DATE).nullable();

export const optionalEmail = string()
  .email(errMsgs.INVALID_EMAIL)
  .max(50, errMsgs.TOO_LONG_MAX_50)
  .typeError(errMsgs.DEFAULT_REQUIRED)
  .nullable();

export const sessionPreferences = array()
  .of(
    string()
      .oneOf(Object.values(sessionPreferencesTypes), errMsgs.INVALID_DATA)
      .required(errMsgs.DEFAULT_REQUIRED)
  )
  .min(1, errMsgs.DEFAULT_REQUIRED)
  .required(errMsgs.DEFAULT_REQUIRED);

export const arrayOfString = array()
  .of(string().required(errMsgs.DEFAULT_REQUIRED))
  .min(1, errMsgs.DEFAULT_REQUIRED)
  .required(errMsgs.DEFAULT_REQUIRED);

export const arrayOfEmails = array()
  .of(email)
  .min(1, errMsgs.DEFAULT_REQUIRED)
  .max(50, errMsgs.MAX_50_EMAILS)
  .required(errMsgs.DEFAULT_REQUIRED);

export const requiredBoolean = boolean()
  .oneOf([true, false], errMsgs.DEFAULT_REQUIRED)
  .typeError(errMsgs.DEFAULT_REQUIRED)
  .required(errMsgs.DEFAULT_REQUIRED);

export const positiveNumber = number()
  .test('Is positive?', errMsgs.SHOULD_BE_POSITIVE, (value) => value > 0)
  .typeError(errMsgs.DEFAULT_REQUIRED)
  .required(errMsgs.DEFAULT_REQUIRED);

export const voicePreferences = string()
  .oneOf(Object.values(voicePreferencesTypes), errMsgs.INVALID_DATA)
  .required(errMsgs.DEFAULT_REQUIRED);

export const optionalStringIfAnswerNo = string().when(
  ['hasCourtAppearances', 'hasPreviousConvictions'],
  {
    is: (val1, val2) => val1 === true || val2 === true,
    then: string().required(errMsgs.DEFAULT_REQUIRED),
    otherwise: (schema) => schema.min(0),
  }
);

export const optionalArrayOfString = array()
  .of(string().required(errMsgs.DEFAULT_REQUIRED))
  .notRequired()
  .nullable();

export const twoDigits = string()
  .matches(twoDigitsRegex, {
    message: errMsgs.INVALID_COACH_CODE,
  })
  .required(errMsgs.DEFAULT_REQUIRED);

export const preferredSessionsType = string()
  .oneOf(Object.values(preferredSessionsTypes), errMsgs.INVALID_DATA)
  .required(errMsgs.DEFAULT_REQUIRED);

// 2fa code 6-digits (all numbers)
export const twoFactorCode = string()
  .matches(/^\d{6}$/, 'Invalid code')
  .required('Code is required');

export const requiredSubdomain = string()
  .min(3, errMsgs.TOO_SHORT_MIN_3)
  .max(20, errMsgs.TOO_LONG_MAX_20)
  .matches(
    /^[a-z0-9]([a-z0-9-]*[a-z0-9])?$/,
    errMsgs.INVALID_ORGANISATION_SUBDOMAIN
  )
  .required(errMsgs.DEFAULT_REQUIRED);

export const emailOrCoachUniqueId = string()
  .required(errMsgs.DEFAULT_REQUIRED)
  .test(
    'emailOrCoachUniqueId',
    'Must be a valid email or valid coach id',
    (value) => {
      if (!value) return false;
      const isEmail = string()
        .email()
        .max(100, errMsgs.TOO_LONG_MAX_100)
        .isValidSync(value);
      const isCoachId = string().length(6).isValidSync(value);
      return isEmail || isCoachId;
    }
  )
  .typeError(errMsgs.DEFAULT_REQUIRED);
