import { useRef } from 'react';

import * as T from '../../../../../components/Typography';
import { BasicInput } from '../../../../../components/Inputs';
import MDEditor from '../../../../../components/MDEditor';
import * as S from './style';
import * as SS from '../style';
import { Icon } from '../../../../../components';
import { useStepForm } from 'CMS/Providers/StepFormProvider';
import { MediaKeyInput } from 'CMS/components/MediaKeyInput';
import { mediaTypes } from '../../../../../constants';

export { validationSchema } from './validation';

const initialData = {
  textAudio: '',
  textAudioKey: '',
  options: [{ id: 0, prefix: '', answer: '', suffix: '' }],
};

const MultipleQuestionsWithEndings = () => {
  // Use useRef to keep the initial state constant across renders
  const initialRef = useRef(initialData);
  const { state, updateDataFields, updateFormFields } = useStepForm(
    initialRef.current
  );
  const { form, data, validationErrs } = state;

  const onOptionChange = ({ key, value, id }) => {
    updateDataFields({
      options: data.options.map((e) => {
        if (e.id !== id) return e;

        return {
          ...e,
          [key]: value,
        };
      }),
    });
  };

  const addOption = () => {
    const maxId = Math.max(...data.options.map((e) => e.id), 0);
    updateDataFields({
      options: [
        ...data.options,
        { id: maxId + 1, prefix: '', answer: '', suffix: '' },
      ],
    });
  };

  const removeOption = (id) => {
    updateDataFields({
      options: data.options.filter((e) => e.id !== id),
    });
  };

  return (
    <div>
      <BasicInput
        value={data.textAudio}
        handleChange={(textAudio) => updateDataFields({ textAudio })}
        label="Explainer Text"
        error={validationErrs.textAudio}
        m={{ mt: 8 }}
      />
      <MediaKeyInput
        label="Explainer Audio"
        type={mediaTypes.TEXT_AUDIO}
        value={data.textAudioKey}
        handleChange={(textAudioKey) => updateDataFields({ textAudioKey })}
        m={{ mt: 5 }}
        error={validationErrs.textAudioKey}
        requireVo
      />

      <MediaKeyInput
        type={mediaTypes.IMAGE}
        label="Image Key"
        value={form.imageKey}
        handleChange={(imageKey) => updateFormFields({ imageKey })}
        m={{ mt: 7 }}
        error={validationErrs?.imageKey}
      />

      <T.P mb={2} mt={8} size="large" weight="bold">
        Questions
      </T.P>

      {validationErrs?.options &&
        typeof validationErrs.options === 'string' && (
          <T.P mb={2} mt={2} color="error">
            {validationErrs.options}
          </T.P>
        )}
      {data.options?.map((question, i) => {
        return (
          <S.SectionWrapper key={question.id} index={i}>
            <T.P mb={2} mt={5} weight="bold" size="med">
              Question {i + 1}
            </T.P>
            <MDEditor
              label="Prefix text"
              value={question.prefix}
              onChange={(value) => {
                onOptionChange({
                  key: 'prefix',
                  value,
                  id: question.id,
                });
              }}
              height={80}
              m={{ mb: 2, mt: 5 }}
              error={validationErrs?.options?.[i]?.prefix}
              helper="e.g 'Area a ='"
            />
            <BasicInput
              label="Answer"
              value={question.answer}
              handleChange={(value) => {
                onOptionChange({
                  key: 'answer',
                  value,
                  id: question.id,
                });
              }}
              m={{ mb: 2, mt: 5 }}
              error={validationErrs?.options?.[i]?.answer}
              helper="e.g '30'"
            />
            <MDEditor
              label="Suffix text"
              value={question.suffix}
              onChange={(value) => {
                onOptionChange({
                  key: 'suffix',
                  value,
                  id: question.id,
                });
              }}
              height={80}
              m={{ mb: 2, mt: 5 }}
              error={validationErrs?.options?.[i]?.suffix}
              helper="e.g 'cm2'"
            />

            {data.options.length === 1 && i === 0 ? null : (
              <SS.Button
                type="link"
                danger
                ghost
                mt={1}
                onClick={() => removeOption(question.id)}
                ml="auto"
              >
                <Icon icon="cross" />
              </SS.Button>
            )}
          </S.SectionWrapper>
        );
      })}

      <SS.Button
        type="link"
        ghost
        mt={5}
        mb={5}
        onClick={addOption}
        ml="auto"
        mr="auto"
      >
        <Icon icon="plus" /> Add Question
      </SS.Button>
    </div>
  );
};

export default MultipleQuestionsWithEndings;
