import {
  types as Ty,
  comprehensionsKeys,
  exerciseTypes,
} from '../../../constants';

import ListenAndRepeat from '../../../pages/Exercises/ExerciseStep/ListenAndRepeat';
import ListenAndRepeatMultiple from '../../../pages/Exercises/ExerciseStep/ListenAndRepeatMultiple';
import ListenAndRepeatGrid from '../../../pages/Exercises/ExerciseStep/ListenAndRepeatGrid';
import ListenAndRepeatGridHidden from '../../../pages/Exercises/ExerciseStep/ListenAndRepeatGridHidden';
import MultipleChoice from '../../../pages/Exercises/ExerciseStep/MultipleChoice';
import WordBuild, {
  OtherCorrectAnswers,
} from '../../../pages/Exercises/ExerciseStep/WordBuild';
import SpotTheWord from '../../../pages/Exercises/ExerciseStep/SpotTheWord';
import MultipleChoiceGrid from '../../../pages/Exercises/ExerciseStep/MultipleChoiceGrid';
import {
  DragAndDropV1,
  DragAndDropV2,
  DragAndDropOrderSounds,
  DragAndDropCreateOrder,
} from '../../../pages/Exercises/ExerciseStep/DragAndDrop';
import VowelBreak from '../../../pages/Exercises/ExerciseStep/VowelBreak';
import {
  WordJoin1,
  WordJoin2,
  WordJoin3,
} from '../../../pages/Exercises/ExerciseStep/WordJoin';
import SentenceFill from '../../../pages/Exercises/ExerciseStep/SentenceFill';
import TypeNewEndings from '../../../pages/Exercises/ExerciseStep/TypeNewEndings';
import {
  TextFinderBasic,
  TextFinderComprehension,
} from '../../../pages/Exercises/ExerciseStep/TextFinder';
import CombineWords, {
  CombineWordsPre,
} from '../../../pages/Exercises/ExerciseStep/CombineWords';
import Trace from '../../../pages/Exercises/ExerciseStep/Trace';
import Draw from '../../../pages/Exercises/ExerciseStep/Draw';
import Comprehensions from '../../../pages/Exercises/ExerciseStep/Comprehensions';
import QuestionAndAnswer from '../../../pages/Exercises/ExerciseStep/QuestionAndAnswer';
import TypingHorizontal from '../../../pages/Exercises/ExerciseStep/TypingHorizontal';
import FillInTheGrid from '../../../pages/Exercises/ExerciseStep/FillInTheGrid';
import Pyramid from '../../../pages/Exercises/ExerciseStep/Pyramid';
import GridWithTypingAnswer from '../../../pages/Exercises/ExerciseStep/GridWithTypingAnswer';
import FactFamily from '../../../pages/Exercises/ExerciseStep/FactFamily';
import Formulae from '../../../pages/Exercises/ExerciseStep/Formulae';
import ColumnsTyping from '../../../pages/Exercises/ExerciseStep/ColumnsTyping';
import ComprehensionSelect from '../../../pages/Exercises/ExerciseStep/ComprehensionSelect';
import Fractions from '../../../pages/Exercises/ExerciseStep/Fractions';
import MultipleQuestionsWithEndings from '../../../pages/Exercises/ExerciseStep/MultipleQuestionsWithEndings';
import Pictogram from '../../../pages/Exercises/ExerciseStep/Pictogram';
import Wheel from '../../../pages/Exercises/ExerciseStep/Wheel';
import BarModel from '../../../pages/Exercises/ExerciseStep/BarModel';
import FillGaps from '../../../pages/Exercises/ExerciseStep/FillGaps';
import BarChart from '../../../pages/Exercises/ExerciseStep/BarChart';

const obj = {
  [Ty.exerciseTypes.LISTEN_AND_REPEAT]: ListenAndRepeat,
  [Ty.exerciseTypes.LISTEN_AND_REPEAT_MULTIPLE]: ListenAndRepeatMultiple,
  [Ty.exerciseTypes.LISTEN_AND_REPEAT_GRID]: ListenAndRepeatGrid,
  [Ty.exerciseTypes.LISTEN_AND_REPEAT_GRID_HIDDEN]: ListenAndRepeatGridHidden,
  [Ty.exerciseTypes.MULTIPLE_CHOICE]: MultipleChoice,
  [Ty.exerciseTypes.MULTIPLE_CHOICE_BUBBLE]: MultipleChoice,
  [Ty.exerciseTypes.WORD_BUILD]: WordBuild,
  [Ty.exerciseTypes.SPOT_THE_WORD]: SpotTheWord,
  [Ty.exerciseTypes.DRAG_AND_DROP_1]: DragAndDropV1,
  [Ty.exerciseTypes.DRAG_AND_DROP_2]: DragAndDropV2,
  [Ty.exerciseTypes.DRAG_AND_DROP_ORDER_SOUNDS]: DragAndDropOrderSounds,
  [Ty.exerciseTypes.DRAG_AND_DROP_CREATE_ORDER]: DragAndDropCreateOrder,
  [Ty.exerciseTypes.VOWEL_BREAK]: VowelBreak,
  [Ty.exerciseTypes.WORD_JOIN_1]: WordJoin1,
  [Ty.exerciseTypes.WORD_JOIN_2]: WordJoin2,
  [Ty.exerciseTypes.WORD_JOIN_3]: WordJoin3,
  [Ty.exerciseTypes.SENTENCE_FILL]: SentenceFill,
  [Ty.exerciseTypes.COMBINE_WORDS_PRE]: CombineWordsPre,
  [Ty.exerciseTypes.COMBINE_WORDS]: CombineWords,
  [Ty.exerciseTypes.TYPE_NEW_ENDINGS]: TypeNewEndings,
  [Ty.exerciseTypes.MULTIPLE_CHOICE_GRID]: MultipleChoiceGrid,
  [Ty.exerciseTypes.TEXT_FINDER]: TextFinderBasic,
  [Ty.exerciseTypes.COMPREHENSION_TEXT_FINDER]: TextFinderComprehension,
  [Ty.exerciseTypes.TRACE]: Trace,
  [Ty.exerciseTypes.DRAW]: Draw,
  [Ty.exerciseTypes.QUESTION_AND_ANSWER]: QuestionAndAnswer,
  [Ty.exerciseTypes.TYPING_HORIZONTAL]: TypingHorizontal,
  [Ty.exerciseTypes.FILL_IN_THE_GRID]: FillInTheGrid,
  [Ty.exerciseTypes.PYRAMID]: Pyramid,
  [Ty.exerciseTypes.GRID_WITH_TYPING_ANSWER]: GridWithTypingAnswer,
  [Ty.exerciseTypes.FACT_FAMILY]: FactFamily,
  [Ty.exerciseTypes.FORMULAE]: Formulae,
  [Ty.exerciseTypes.COLUMNS_TYPING]: ColumnsTyping,
  [Ty.exerciseTypes.COMPREHENSION_SELECT]: ComprehensionSelect,
  [Ty.exerciseTypes.FRACTIONS]: Fractions,
  [Ty.exerciseTypes.MULTIPLE_QUESTIONS_WITH_ENDINGS]:
    MultipleQuestionsWithEndings,
  [Ty.exerciseTypes.PICTOGRAM]: Pictogram,
  [Ty.exerciseTypes.WHEEL]: Wheel,
  [Ty.exerciseTypes.BAR_MODEL]: BarModel,
  [Ty.exerciseTypes.FILL_GAPS]: FillGaps,
  [Ty.exerciseTypes.BAR_CHART]: BarChart,
};

const Preview = ({ type, step, exercise }) => {
  if (comprehensionsKeys.includes(exercise.key)) {
    return <Comprehensions exKey={exercise.key} cmsPreview {...step} />;
  }

  const Component = obj[type];
  return type ? (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
        width: '100%',
        minHeight: '100%',
        paddingBottom: '32px',
      }}
    >
      <Component
        cmsPreview
        bubble={type === Ty.exerciseTypes.MULTIPLE_CHOICE_BUBBLE}
        {...step}
        handleNext={() => {}}
      />
      {type === exerciseTypes.WORD_BUILD && (
        <OtherCorrectAnswers cmsPreview disabled {...step} />
      )}
    </div>
  ) : null;
};

export default Preview;
