import { pictogramShapes, pictogramVariations } from '../../../../../constants';
import { string, array, object, number, boolean } from 'yup';

export const validationSchema = {
  variation: string()
    .oneOf(Object.values(pictogramVariations.variations), 'required field')
    .required('required field'),
  textAudio: string().required('Explainer Text is required'),
  textAudioKey: string().required('Explainer Text file key is required'),
  keyValue: number().required('Key value is required'),
  keyName: string().required('Key name is required'),
  shape: string()
    .oneOf(Object.values(pictogramShapes), 'required field')
    .required('required field'),
  numberOfShapes: number()
    .required('Number of shapes is required')
    .integer('Number of shapes must be an integer')
    .positive('Number of shapes must be greater than 0')
    .test(
      'min-shape-count',
      'Circles must be more than 1',
      (value) => value >= 1
    )
    .test(
      'max-shape-count',
      'Circles rows maximum is 8 rows',
      (value) => value <= 64
    ),
  shapes: array()
    .of(
      object({
        id: number().required('Shape ID is required'),
        isSelected: boolean().required('Shape selection status is required'),
      })
    )
    .required('Circles array is required')
    .min(1, 'At least one circle is required'),
  total: number().when('variation', {
    is: (variation) =>
      variation === pictogramVariations.variations.FILL_IN_THE_TOTAL ||
      variation === pictogramVariations.variations.FILL_IN_SHAPES,
    then: number()
      .required('Total is required')
      .test(
        'valid-total',
        'Total cannot be less than 1 or more than the maximum possible value',
        function (value) {
          const { numberOfShapes, keyValue } = this.parent; // Access other values in the schema
          const maxTotalOrTallyValue = numberOfShapes * keyValue;
          return value >= 1 && value <= maxTotalOrTallyValue;
        }
      ),
    otherwise: number().notRequired(),
  }),
  tally: number().when('variation', {
    is: (variation) =>
      variation === pictogramVariations.variations.FILL_IN_SHAPES_FROM_TALLY,
    then: number()
      .required('Tally is required')
      .test(
        'valid-tally',
        'Tally cannot be less than 1 or more than the maximum possible value',
        function (value) {
          const { numberOfShapes, keyValue } = this.parent; // Access other values in the schema
          const maxTotalOrTallyValue = numberOfShapes * keyValue;
          return value >= 1 && value <= maxTotalOrTallyValue;
        }
      ),
    otherwise: number().notRequired(),
  }),
  tallyOptions: array().when('variation', {
    is: (variation) =>
      variation === pictogramVariations.variations.SELECT_TALLY,
    then: array()
      .of(
        object().shape({
          id: number().required('Tally option ID is required'),
          value: number().required('Tally option value is required'),
          isCorrect: boolean().required('Tally option correctness is required'),
        })
      )
      .test(
        'at-least-one-correct',
        'At least one tally option must be correct',
        (tallyOptions) =>
          tallyOptions.some((option) => option.isCorrect === true)
      )
      .required('Tally options are required')
      .min(2, 'At least 2 tally options are required')
      .max(6, 'You can add up to 6 tally options'),
    otherwise: array().notRequired(),
  }),
};
