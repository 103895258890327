import { useMemo, useRef } from 'react';
import { Icon, Typography as T } from '../../../../../components';
import {
  BasicInput,
  Checkbox,
  Dropdown,
} from '../../../../../components/Inputs';
import { factFamilyVariations } from '../../../../../constants';
import { useStepForm } from 'CMS/Providers/StepFormProvider';
import * as S from '../style';
import * as LS from './style';
import { convertSnakeCaseToSpaces } from 'helpers';
import { generateAddOptionInitial, generateInitialOptions } from './utils';
import MDEditor from '../../../../../components/MDEditor';
import { MediaKeyInput } from '../../../../components/MediaKeyInput';
import { mediaTypes } from '../../../../../constants';

export { validationSchema } from './validation';

const initialData = {
  textAudio: '',
  textAudioKey: '',
  options: [],
  variation: '',
  firstQuestion: '',
};

const FactFamily = () => {
  const initialRef = useRef(initialData);
  const { state, updateDataFields } = useStepForm(initialRef.current);
  const { data = initialData, validationErrs } = state;

  const onOptionChange = ({ key, value, id, rowId }) => {
    updateDataFields({
      options: data.options.map((e) => {
        if (e.id !== rowId) return e;

        if (key === 'operator') {
          return {
            ...e,
            operator: value,
          };
        }

        return {
          ...e,
          numbers: [
            ...e.numbers.map((n) => {
              if (n.id !== id) return n;
              return {
                ...n,
                [key]: value,
              };
            }),
          ],
        };
      }),
    });
  };

  const addOption = () => {
    updateDataFields({
      options: [
        ...data.options,
        generateAddOptionInitial(data.variation, data.options),
      ],
    });
  };

  const removeOption = (id) => {
    updateDataFields({
      options: data.options.filter((e) => e.id !== id),
    });
  };

  const isPyramid = data.variation === factFamilyVariations.variations.PYRAMID;
  const isFlatWithFirstQuestion =
    data.variation === factFamilyVariations.variations.FLAT_WITH_FIRST_QUESTION;
  const isFlat =
    data.variation === factFamilyVariations.variations.FLAT ||
    isFlatWithFirstQuestion;

  const topRow = useMemo(() => {
    const topRowOption = data.options?.[0]?.numbers;
    if (!topRowOption) return [];

    return [[topRowOption[0]], [topRowOption[1], topRowOption[2]]];
  }, [data.options]);

  const rows = useMemo(
    () => (isPyramid ? data.options?.slice(1) || [] : data.options || []),
    [data.options, isPyramid]
  );

  const onVariationChange = (variation) => {
    updateDataFields({
      variation,
      options: generateInitialOptions(variation, data.options),
      textAudio:
        variation === factFamilyVariations.variations.PYRAMID
          ? ''
          : data.textAudio,
      textAudioKey:
        variation === factFamilyVariations.variations.PYRAMID
          ? ''
          : data.textAudioKey,
    });
  };

  const variationOptions = Object.keys(factFamilyVariations.variations).map(
    (e) => ({
      label: convertSnakeCaseToSpaces(e),
      value: e,
    })
  );

  return (
    <div>
      <Dropdown
        label="Choose a variation"
        options={variationOptions}
        selected={
          data.variation
            ? {
                value: data.variation,
                label: convertSnakeCaseToSpaces(data.variation),
              }
            : null
        }
        handleChange={onVariationChange}
        error={validationErrs?.variation}
      />
      {!!data.variation && (
        <>
          {isFlat && (
            <>
              <MDEditor
                label="Explainer Text"
                value={data.textAudio}
                onChange={(textAudio) => updateDataFields({ textAudio })}
                mode={'edit'}
                height={80}
                m={{ mt: 6 }}
                error={validationErrs?.textAudio}
              />

              <MediaKeyInput
                type={mediaTypes.TEXT_AUDIO}
                label="Explainer Text file Key"
                value={data.textAudioKey}
                handleChange={(textAudioKey) =>
                  updateDataFields({ textAudioKey })
                }
                m={{ mt: 5 }}
                error={validationErrs?.textAudioKey}
              />
            </>
          )}
          <T.P mt="8">
            Fill in all the squares with the correct values. uncheck the box if
            you want to hide the value and allow the user to fill it.
          </T.P>
          {isPyramid ? (
            <LS.PyramidInputsWrapper>
              {topRow.map((rowOptions, rowIndex) => (
                <LS.RowWrapper key={rowIndex}>
                  {rowOptions.map((option) => {
                    const id = option.id;
                    const error = validationErrs?.options?.[id]?.option;
                    return (
                      <SquareInput
                        rowId={0}
                        option={option}
                        onOptionChange={onOptionChange}
                        error={error}
                      />
                    );
                  })}
                </LS.RowWrapper>
              ))}
            </LS.PyramidInputsWrapper>
          ) : null}

          {isFlatWithFirstQuestion && (
            <MDEditor
              label="First Question"
              value={data.firstQuestion}
              onChange={(firstQuestion) => updateDataFields({ firstQuestion })}
              mode={'edit'}
              height={80}
              m={{ mt: 6, mb: 6 }}
              error={validationErrs?.firstQuestion}
            />
          )}

          <div>
            {rows.map((row) => {
              const rowId = row.id;
              const error = validationErrs?.options?.[rowId];

              return (
                <LS.RowWrapper key={rowId}>
                  <SquareInput
                    rowId={rowId}
                    option={row.numbers[0]}
                    onOptionChange={onOptionChange}
                    error={error?.numbers?.[0]}
                    variation={data.variation}
                  />
                  <LS.BoxWrapper>
                    <BasicInput
                      placeholder=""
                      value={row.operator}
                      handleChange={(value) => {
                        onOptionChange({
                          key: 'operator',
                          value,
                          rowId: rowId,
                        });
                      }}
                      bgColor="neutralSurface"
                      borderColor={error?.operator ? 'error' : 'primaryMain'}
                      error={error?.operator}
                      hideErrorMsg
                      textAlign="center"
                    />
                  </LS.BoxWrapper>
                  <SquareInput
                    rowId={rowId}
                    option={row.numbers[1]}
                    onOptionChange={onOptionChange}
                    error={error?.numbers?.[1]}
                    variation={data.variation}
                  />
                  <T.P weight="bold" m="0" size="large" lh="64px !important">
                    =
                  </T.P>
                  <SquareInput
                    rowId={rowId}
                    option={row.numbers[2]}
                    onOptionChange={onOptionChange}
                    error={error?.numbers?.[2]}
                    variation={data.variation}
                  />
                  {rows.length > 1 && (
                    <S.Button
                      type="link"
                      danger
                      ghost
                      mt={1}
                      onClick={() => removeOption(rowId)}
                      ml="auto"
                    >
                      <Icon icon="cross" />
                    </S.Button>
                  )}
                </LS.RowWrapper>
              );
            })}
          </div>
          <S.Button
            type="link"
            ghost
            mt={5}
            mb={5}
            onClick={addOption}
            disabled={
              data.options?.find((o) => o.numbers.find((n) => !n.option)) ||
              rows.length >= 9
            }
            ml="auto"
            mr="auto"
          >
            <Icon icon="plus" /> Add Option
          </S.Button>
        </>
      )}
    </div>
  );
};

const SquareInput = ({ variation, rowId, option, onOptionChange, error }) => {
  const isFlatWithFirstQuestion =
    variation === factFamilyVariations.variations.FLAT_WITH_FIRST_QUESTION;
  return (
    <LS.BoxWrapper>
      <BasicInput
        key={option.id}
        placeholder=""
        value={option.option}
        handleChange={(value) => {
          onOptionChange({
            key: 'option',
            value,
            rowId: rowId,
            id: option.id,
          });
        }}
        bgColor={option.showValue ? 'neutralSurface' : ''}
        borderColor={
          error?.option ? 'error' : option.showValue ? '' : 'primaryMain'
        }
        error={error?.option}
        hideErrorMsg
        textAlign="center"
        type={isFlatWithFirstQuestion ? 'string' : 'number'}
      />
      <Checkbox
        w="33px"
        plain
        noPadding
        checked={option.showValue}
        handleChange={(checked) => {
          onOptionChange({
            key: 'showValue',
            value: checked,
            rowId: rowId,
            id: option.id,
          });
        }}
        error={error?.showValue}
        hideErrorMsg
      />
    </LS.BoxWrapper>
  );
};

export default FactFamily;
