import styled from '@emotion/styled';

export const InputsWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.neutralLightNew};
  padding: ${({ theme }) => `${theme.spacings[4]} ${theme.spacings[5]}`};
`;

export const BottomNumbersWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacings[4]};
  display: flex;
  gap: ${({ theme }) => theme.spacings[2]};
`;

export const OperationWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: ${({ theme }) => theme.spacings[2]};
  align-items: center;
  margin: ${({ theme }) => theme.spacings[9]} 0;
`;
