import styled from '@emotion/styled';

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(${(props) => props.cols}, 80px);
  grid-template-rows: repeat(${(props) => props.rows}, 30px) auto;
  gap: 1px;
  background-color: white;
  margin: 0 auto;
  margin-bottom: ${({ theme }) => theme.spacings[6]};
`;

export const RowLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  user-select: none;
`;

export const Cell = styled.div`
  width: 80px;
  height: 30px;
  border: 1px solid #ddd;
  background-color: ${(props) => (props.isHighlighted ? 'gray' : 'white')};
  transition: background-color 0.2s;
`;

export const BottomLabelContainer = styled.div`
  grid-column: 2 / span ${(props) => props.cols};
  display: grid;
  grid-template-columns: repeat(${(props) => props.cols}, 80px);
  gap: 1px;
  justify-items: center;
  margin-top: 5px;
`;

export const ColumnLabel = styled.div`
  text-align: center;
  font-size: 12px;
  width: fit-content;
`;
