import { useRef } from 'react';
import { BasicInput } from '../../../../../components/Inputs';
import * as T from '../../../../../components/Typography';
import { MediaKeyInput } from '../../../../components/MediaKeyInput';
import { mediaTypes } from '../../../../../constants';
import { useStepForm } from 'CMS/Providers/StepFormProvider';
import * as SS from '../style';
import * as S from './style';
import { Icon } from 'components';
import MDEditor from '../../../../../components/MDEditor';

export { validationSchema } from './validation';

const initialData = {
  textAudio: '',
  textAudioKey: '',
  topNumber: 0,
  bottomNumbers: [{ id: 0, value: 0 }],
  operation: '',
  correctAnswer: '',
};

const BarModel = () => {
  const initialRef = useRef(initialData);
  const { state, updateDataFields } = useStepForm(initialRef.current);
  const { data = initialData, validationErrs } = state;

  const handleBottomNumbersChange = (value, numberId) => {
    updateDataFields({
      bottomNumbers: data.bottomNumbers.map((n) => {
        if (n.id !== numberId) return n;
        return { ...n, value };
      }),
    });
  };

  const handleAddBottomNumber = () => {
    const maxId = Math.max(...data.bottomNumbers.map((n) => n.id), 0);
    updateDataFields({
      bottomNumbers: [...data.bottomNumbers, { id: maxId + 1, value: 0 }],
    });
  };

  const handleRemoveBottomNumber = (numberId) => {
    updateDataFields({
      bottomNumbers: data.bottomNumbers.filter((n) => n.id !== numberId),
    });
  };

  return (
    <div>
      <MDEditor
        label="Explainer Text"
        value={data.textAudio}
        onChange={(textAudio) => updateDataFields({ textAudio })}
        mode={'edit'}
        height={80}
        m={{ mt: 6 }}
        error={validationErrs?.textAudio}
      />

      <MediaKeyInput
        type={mediaTypes.TEXT_AUDIO}
        label="Explainer Text file Key"
        value={data.textAudioKey}
        handleChange={(textAudioKey) => updateDataFields({ textAudioKey })}
        m={{ mt: 5 }}
        error={validationErrs?.textAudioKey}
      />

      <T.P mt={6} mb={4} size="regular" weight="bold">
        Numbers
      </T.P>

      <BasicInput
        placeholder=""
        type="number"
        value={data.topNumber}
        handleChange={(topNumber) => {
          updateDataFields({ topNumber });
        }}
        bgColor={'neutralSurface'}
        error={validationErrs?.topNumber}
        hideErrorMsg
        textAlign="center"
      />

      <S.BottomNumbersWrapper>
        {data.bottomNumbers?.map((n, numberIndex) => (
          <div key={`${n.id}-${numberIndex}`}>
            <BasicInput
              placeholder=""
              type="number"
              value={n.value}
              handleChange={(newNumber) =>
                handleBottomNumbersChange(Number(newNumber), n.id)
              }
              borderColor="primaryMain"
              error={validationErrs?.bottomNumbers}
              hideErrorMsg
              textAlign="center"
            />
            {data.bottomNumbers?.length <= 2 ? null : (
              <SS.Button
                type="link"
                danger
                ghost
                mt={1}
                onClick={() => handleRemoveBottomNumber(n.id)}
                ml="auto"
              >
                <Icon icon="cross" />
              </SS.Button>
            )}
          </div>
        ))}
      </S.BottomNumbersWrapper>

      <SS.Button
        type="link"
        mt={5}
        mb={5}
        onClick={handleAddBottomNumber}
        disabled={data.bottomNumbers?.length === 5}
        ml="auto"
        mr="auto"
      >
        <Icon icon="plus" /> Add Bottom Number
      </SS.Button>

      <MDEditor
        label="Operation"
        value={data.operation}
        onChange={(operation) => updateDataFields({ operation })}
        mode={'edit'}
        height={80}
        m={{ mt: 6 }}
        error={validationErrs?.operation}
        helper="i.e 1/2 of 12 ="
      />

      <BasicInput
        label="Correct Answer"
        placeholder={0}
        value={data?.correctAnswer}
        handleChange={(correctAnswer) =>
          updateDataFields({ correctAnswer: Number(correctAnswer) })
        }
        borderColor="primaryMain"
        error={validationErrs?.correctAnswer}
        hideErrorMsg
        textAlign="center"
        type="number"
        m={{ mt: 6 }}
      />
    </div>
  );
};

export default BarModel;
