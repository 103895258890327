import React from 'react';
import styled from '@emotion/styled';

const RotateMessageWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  z-index: 1000;
  text-align: center;
  padding: 20px;
`;

const RotateMessage = () => {
  return (
    <RotateMessageWrapper>
      <p>Please rotate your phone to landscape mode for this exercise</p>
    </RotateMessageWrapper>
  );
};

export default RotateMessage;
