import { useEffect, useState } from 'react';

import * as T from '../../../../components/Typography';
import { PlayButton } from '../../../../components/Button';
import { useLocation } from 'react-router-dom';
import StepFooterButtons from '../StepFooterButtons';

import * as S from './style';
import * as SharedStyles from '../shared/style';
import { fractionOperationTypes } from '../../../../constants';

const Fractions = ({
  data: {
    textAudio,
    textAudioUrl,
    numberOfCircles,
    circles: _circles = [],
    fractionOperationType,
    topNumber,
    bottomNumber,
    decimalNumber,
    fractionOfNumber,
    correctAnswer: originalCorrectAnswer,
  } = {},
  handleNext,
  stuckAtReview,
  disabled,
  preview,
}) => {
  const [enableNext, setEnableNext] = useState(false);
  const [correctAnswer, setCorrectAnswer] = useState(0);
  const [circles, setCircles] = useState(_circles);

  const location = useLocation();
  useEffect(() => {
    setEnableNext(false);
  }, [location]);

  useEffect(() => {
    if (circles?.length) {
      const numberOfSelectedCircles = circles.filter(
        (c) => c.isSelected
      ).length;

      setEnableNext(
        numberOfSelectedCircles === originalCorrectAnswer &&
          correctAnswer === originalCorrectAnswer
      );
    }
  }, [circles, originalCorrectAnswer, correctAnswer]);

  const onCircleClick = (circle) => {
    const newCircles = circles.map((c) =>
      c.id === circle.id ? { ...c, isSelected: !c.isSelected } : c
    );

    setCircles(newCircles);
  };

  return (
    <SharedStyles.Wrapper>
      <SharedStyles.ContentWrapper>
        {!!textAudio && (
          <SharedStyles.TextAudioWrapper mt="2" mb="4">
            {textAudioUrl ? (
              <>
                <PlayButton
                  audioUrl={textAudioUrl}
                  width="56px"
                  height="56px"
                  iconProps={{ width: '32px', height: '32px' }}
                  fallbackText={textAudio}
                />
                <T.P ml={4} weight="regular" size="med">
                  {textAudio}
                </T.P>
              </>
            ) : (
              <T.P weight="regular" size="regular">
                {textAudio}
              </T.P>
            )}
          </SharedStyles.TextAudioWrapper>
        )}

        <S.CirclesWrapper numberOfCircles={numberOfCircles}>
          {circles.map((c) => (
            <S.Circle
              key={c.id}
              isSelected={c.isSelected}
              onClick={() => onCircleClick(c)}
            />
          ))}
        </S.CirclesWrapper>

        <S.QuestionWrapper>
          {fractionOperationType === fractionOperationTypes.FRACTION_OF ? (
            <S.FractionWrapper>
              <T.P size="large">{topNumber}</T.P>
              <S.Divider />
              <T.P size="large">{bottomNumber}</T.P>
            </S.FractionWrapper>
          ) : (
            <T.P size="large">{decimalNumber}</T.P>
          )}
          <T.P size="large">of</T.P>
          <T.P size="large">{fractionOfNumber}</T.P>
          <T.P size="large">=</T.P>
          <SharedStyles.TypeInput
            id={`fraction-answer`}
            name={`fraction-answer`}
            type="number"
            aria-labelledby={`fraction-answer`}
            value={correctAnswer}
            onChange={(e) => setCorrectAnswer(Number(e.target.value))}
            isCorrect={correctAnswer === originalCorrectAnswer}
            isFullWidth
          />
        </S.QuestionWrapper>
      </SharedStyles.ContentWrapper>

      <StepFooterButtons
        stuckAtReview={stuckAtReview}
        handleNext={handleNext}
        preview={preview}
        disabled={disabled}
        enableNext={enableNext}
      />
    </SharedStyles.Wrapper>
  );
};

export default Fractions;
