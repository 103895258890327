const freshDeskInit = () => {
  try {
    // freshdesk
    window.fwSettings = {
      widget_id: 201000000145,
    };

    // Initialize FreshworksWidget
    if (typeof window.FreshworksWidget !== 'function') {
      var n = function () {
        n.q.push(arguments);
      };
      n.q = [];
      window.FreshworksWidget = n;
    }
    // Add the script element
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://euc-widget.freshworks.com/widgets/201000000145.js';
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('freshdesk failed to lead:', e);
  }
};

export default freshDeskInit;
