import styled from '@emotion/styled';

export const Container = styled.div`
  background: #fdfcf9;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(var(--vh, 1vh) * 90);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000000;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
`;

export const IconButton = styled.button`
  border: none;
  background: none;
  outline: none;
`;
