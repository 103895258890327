import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import * as T from '../../../../components/Typography';
import TryAgainModal from '../../../../components/ModalNew/TryAgainModal';
import { factFamilyVariations } from '../../../../constants';
import StepFooterButtons from '../StepFooterButtons';
import * as SharedStyles from '../shared/style';
import { Markdown } from 'components';

import * as S from './style';
import { PlayButton } from 'components/Button';
import { createH1 } from 'utils/markdown';

const getInitialAnswers = (options) => {
  const _answers = {};
  options?.forEach((option) => {
    _answers[option.id] = {};
    option.numbers.forEach((number) => {
      _answers[option.id][number.id] = number.showValue ? number.option : '';
    });
    const operator = option.operator;
    const firstNumber = option.numbers[0].option;
    const secondNumber = option.numbers[1].option;
    const thirdNumber = option.numbers[2].option;
    _answers[
      option.id
    ].correctAnswer = `${firstNumber}${operator}${secondNumber}=${thirdNumber}`;
  });

  return _answers;
};

const FactFamily = ({
  key,
  data: { textAudio, textAudioUrl, firstQuestion, options, variation } = {},
  handleNext,
  stuckAtReview,
  disabled,
  preview,
}) => {
  const [showTryAgain, setShowTryAgain] = useState(false);
  const [answers, setAnswers] = useState(() => getInitialAnswers(options));

  const location = useLocation();
  useEffect(() => {
    if (options?.length) {
      setAnswers(getInitialAnswers(options));
    }

    // To avoid unnecessary re-renders we only want this to work when the pathname changes,
    // if react-query still refetching step is handled in the parent (isStepLoading)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const isPyramid = variation === factFamilyVariations.variations.PYRAMID;
  const isFlatWithFirstQuestion =
    variation === factFamilyVariations.variations.FLAT_WITH_FIRST_QUESTION;

  const correctAnswersFactFamilies = useMemo(
    () =>
      Object.keys(answers)
        .slice(isPyramid ? 1 : 0)
        .map((key) => answers[key].correctAnswer),
    [answers, isPyramid]
  );

  const handleClick = () => {
    const isTopRowCorrect = options[0].numbers.every(
      (number) => answers[1][number.id] === number.option
    );

    const answersFactFamilies = Object.keys(answers)
      .slice(isPyramid ? 1 : 0)
      .map((key) => {
        const operator = options.find(
          (option) => Number(option.id) === Number(key)
        )?.operator;
        return `${answers[key][0]}${operator}${answers[key][1]}=${answers[key][2]}`;
      });

    // Create arrays of unique values for comparison
    const uniqueAnswersSet = new Set(answersFactFamilies);

    // Compare both length and contents
    const isOthersCorrect =
      uniqueAnswersSet.size === correctAnswersFactFamilies.length &&
      correctAnswersFactFamilies.every((fact) => uniqueAnswersSet.has(fact));

    if (isTopRowCorrect && isOthersCorrect) {
      handleNext();
    } else {
      setShowTryAgain(true);
    }
  };

  const topRow = useMemo(() => {
    const topRowOption = options?.[0]?.numbers;
    if (!topRowOption) return [];

    return [[topRowOption[0]], [topRowOption[1], topRowOption[2]]];
  }, [options]);

  const rows = useMemo(
    () => (isPyramid ? options?.slice(1) || [] : options || []),
    [options, isPyramid]
  );

  const isAllInputsFilled = Object.values(answers).every(
    (ans) => !!ans[0] && !!ans[1] && !!ans[2]
  );

  return (
    <S.Wrapper>
      <S.ContentWrapper>
        {isPyramid ? (
          <S.PreFilledAnswersGridWrapper>
            {topRow.map((rowOptions, rowIndex) => (
              <S.PyramidRowWrapper key={rowIndex}>
                {rowOptions.map((option, i) => {
                  const id = option.id;
                  const value = answers[0][id];
                  const isCorrect = value === option.option;
                  return (
                    <S.BoxWrapper key={id} showLine={rowIndex > 0} index={i}>
                      <S.TypeInput
                        id={`answer-0-${id}`}
                        name={`answer-0-${id}`}
                        type="number"
                        aria-labelledby={`answer-0-${id}`}
                        showValue={option.showValue}
                        value={value}
                        disabled={option.showValue}
                        onChange={(e) => {
                          setAnswers({
                            ...answers,
                            0: {
                              ...answers[0],
                              [id]: e.target.value,
                            },
                          });
                        }}
                        isCorrect={isCorrect}
                      />
                    </S.BoxWrapper>
                  );
                })}
              </S.PyramidRowWrapper>
            ))}
          </S.PreFilledAnswersGridWrapper>
        ) : null}

        {!!textAudio && (
          <SharedStyles.TextAudioWrapper mt="2" mb="4">
            {textAudioUrl ? (
              <>
                <PlayButton
                  audioUrl={textAudioUrl}
                  width="56px"
                  height="56px"
                  iconProps={{ width: '32px', height: '32px' }}
                  fallbackText={textAudio}
                />
                <Markdown
                  text={createH1(textAudio)}
                  customStyles={{
                    h1: {
                      size: 'regular',
                      weight: 'regular',
                      ml: 4,
                    },
                  }}
                />
              </>
            ) : (
              <Markdown
                text={createH1(textAudio)}
                customStyles={{
                  h1: {
                    size: 'regular',
                    weight: 'regular',
                    ml: 4,
                  },
                }}
              />
            )}
          </SharedStyles.TextAudioWrapper>
        )}

        {isFlatWithFirstQuestion && firstQuestion && (
          <S.RowWrapper>
            <Markdown
              text={createH1(firstQuestion)}
              customStyles={{
                h1: {
                  size: 'medLarge',
                  weight: 'regular',
                  ml: 4,
                },
              }}
            />
          </S.RowWrapper>
        )}

        {!!rows?.length &&
          rows.map((row, rowIndex) => {
            const rowId = row.id;
            const values = answers[rowId] || {};
            const rowFactFamily = `${values[0]}${row.operator}${values[1]}=${values[2]}`;
            const isDuplicate = rows.some(
              (otherRow) =>
                otherRow.id < row.id &&
                rowFactFamily ===
                  `${answers[otherRow.id][0]}${otherRow.operator}${
                    answers[otherRow.id][1]
                  }=${answers[otherRow.id][2]}`
            );

            const isCorrect =
              correctAnswersFactFamilies.includes(rowFactFamily) &&
              !isDuplicate;

            return (
              <S.RowWrapper key={rowIndex} rowIndex={rowIndex}>
                <S.TypeInput
                  id={`answer-${rowId}-0`}
                  name={`answer-${rowId}-0`}
                  type={isFlatWithFirstQuestion ? 'string' : 'number'}
                  aria-labelledby={`answer-${rowId}-0`}
                  showValue={row.numbers[0].showValue}
                  value={values[0]}
                  disabled={row.numbers[0].showValue}
                  onChange={(e) => {
                    setAnswers({
                      ...answers,
                      [rowId]: { ...answers[rowId], 0: e.target.value },
                    });
                  }}
                  isCorrect={isCorrect}
                />
                <T.P weight="bold" m="0" size="large" lh="64px !important">
                  {row.operator}
                </T.P>
                <S.TypeInput
                  id={`answer-${rowId}-1`}
                  name={`answer-${rowId}-1`}
                  type={isFlatWithFirstQuestion ? 'string' : 'number'}
                  aria-labelledby={`answer-${rowId}-1`}
                  showValue={row.numbers[1].showValue}
                  value={values[1]}
                  disabled={row.numbers[1].showValue}
                  onChange={(e) => {
                    setAnswers({
                      ...answers,
                      [rowId]: { ...answers[rowId], 1: e.target.value },
                    });
                  }}
                  isCorrect={isCorrect}
                />
                <T.P weight="bold" m="0" size="large" lh="64px !important">
                  =
                </T.P>
                <S.TypeInput
                  isEqualInput
                  id={`answer-${rowId}-2`}
                  name={`answer-${rowId}-2`}
                  type={isFlatWithFirstQuestion ? 'string' : 'number'}
                  aria-labelledby={`answer-${rowId}-2`}
                  showValue={row.numbers[2].showValue}
                  value={values[2]}
                  disabled={row.numbers[2].showValue}
                  onChange={(e) => {
                    setAnswers({
                      ...answers,
                      [rowId]: { ...answers[rowId], 2: e.target.value },
                    });
                  }}
                  isCorrect={isCorrect}
                />
              </S.RowWrapper>
            );
          })}
      </S.ContentWrapper>

      <StepFooterButtons
        stuckAtReview={stuckAtReview}
        handleNext={handleClick}
        preview={preview}
        disabled={disabled}
        enableNext={isAllInputsFilled}
      />
      <TryAgainModal
        aria-modal="true"
        isModalVisible={showTryAgain}
        setIsModalVisible={setShowTryAgain}
      />
    </S.Wrapper>
  );
};

export default FactFamily;
