import { useEffect, useState } from 'react';

import { PlayButton } from '../../../../components/Button';
import { useLocation } from 'react-router-dom';
import StepFooterButtons from '../StepFooterButtons';

import * as SharedStyles from '../shared/style';
import * as S from './style';
import * as T from '../../../../components/Typography';
import { Markdown } from 'components';
import { createH1 } from 'utils/markdown';
import GridChartWithLabels from './GridChartWithLabels';
import RotateMessage from './RotateMessage';

const BarChart = ({
  data: {
    textAudio,
    textAudioUrl,
    question,
    questionAudioUrl,
    rowsLabels,
    colsLabels,
    colsLabelsValues: originalColsLabelsValues,
  } = {},
  imageUrl,
  handleNext,
  stuckAtReview,
  disabled,
  preview,
}) => {
  const [enableNext, setEnableNext] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const handleOrientationChange = () => {
      const isPortrait = window.innerHeight > window.innerWidth;
      setShowMessage(isPortrait);
    };

    handleOrientationChange();

    window.addEventListener('resize', handleOrientationChange);

    return () => {
      window.removeEventListener('resize', handleOrientationChange);
    };
  }, []);

  useEffect(() => {
    setEnableNext(false);
  }, [location]);

  return (
    <SharedStyles.Wrapper>
      <SharedStyles.ContentWrapper mx={7}>
        {!!textAudio && (
          <SharedStyles.TextAudioWrapper mt="2" mb="4">
            {textAudioUrl ? (
              <>
                <PlayButton
                  audioUrl={textAudioUrl}
                  width="56px"
                  height="56px"
                  iconProps={{ width: '32px', height: '32px' }}
                  fallbackText={textAudio}
                />
                <Markdown
                  text={createH1(textAudio)}
                  customStyles={{
                    h1: {
                      size: 'regular',
                      weight: 'regular',
                      ml: 4,
                    },
                  }}
                />
              </>
            ) : (
              <Markdown
                text={createH1(textAudio)}
                customStyles={{
                  h1: {
                    size: 'regular',
                    weight: 'regular',
                    ml: 4,
                  },
                }}
              />
            )}
          </SharedStyles.TextAudioWrapper>
        )}

        {imageUrl && (
          <S.ImageWrapper>
            <S.Img src={imageUrl} alt={textAudio} />
          </S.ImageWrapper>
        )}

        {!!question && (
          <SharedStyles.TextAudioWrapper mt="2" mb="4">
            <PlayButton
              audioUrl={questionAudioUrl}
              width="56px"
              height="56px"
              iconProps={{ width: '32px', height: '32px' }}
              fallbackText={question}
            />
            <T.P ml={4} weight="regular" size="small">
              {question}
            </T.P>
          </SharedStyles.TextAudioWrapper>
        )}

        {showMessage ? (
          <RotateMessage />
        ) : (
          <GridChartWithLabels
            rowsLabels={rowsLabels}
            colsLabels={colsLabels}
            originalColsLabelsValues={originalColsLabelsValues}
            setEnableNext={setEnableNext}
          />
        )}
      </SharedStyles.ContentWrapper>

      <StepFooterButtons
        stuckAtReview={stuckAtReview}
        handleNext={handleNext}
        preview={preview}
        disabled={disabled}
        enableNext={enableNext}
      />
    </SharedStyles.Wrapper>
  );
};

export default BarChart;
