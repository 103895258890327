import React, { useEffect, useReducer, useRef } from 'react';
import { useParams } from 'react-router-dom';
import * as T from '../Typography';
import { Col, Row } from '../Grid';
import Modal from '../ModalNew';
import { BasicButton } from '../Button';
import { PasswordInput } from '../Inputs';
import InlineCopy from '../Copy/InlineCopy';
import { useUpdateCoachPassword } from 'api-calls/coaches.queries';
import validate from '../../validation/schemas/reset-password';
import * as S from './style';

const initialState = {
  password: '',
  validationErrs: {},
  showResetPasswordForm: false,
  warningModalVisible: false,
  successModalVisible: false,
};

function reducer(state, newState) {
  return { ...state, ...newState };
}

const ResetCoachPassword = () => {
  const { coachId } = useParams();
  const submitAttempt = useRef(false);
  const [state, setState] = useReducer(reducer, initialState);
  const {
    password,
    validationErrs,
    showResetPasswordForm,
    warningModalVisible,
    successModalVisible,
  } = state;
  const {
    mutate: updatePassword,
    isLoading,
    error: httpError,
  } = useUpdateCoachPassword();

  const validateForm = () => {
    try {
      validate({
        password,
      });
      setState({ validationErrs: {} });
      return true;
    } catch (error) {
      if (error.name === 'ValidationError') {
        setState({ validationErrs: error.inner });
      }
      return false;
    }
  };

  useEffect(() => {
    if (submitAttempt.current) {
      validateForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [password]);

  const handleCreateNewPassword = () => {
    setState({ warningModalVisible: false });

    updatePassword(
      {
        coachId,
        password,
      },
      {
        onSuccess: () => {
          setState({ successModalVisible: true });
        },
      }
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    submitAttempt.current = true;
    const isValid = validateForm();

    if (isValid) {
      setState({ warningModalVisible: true });
    }
  };

  return (
    <>
      <T.H2 size="med" mb="3">
        Reset Password
      </T.H2>
      <T.P mb="3">
        This will reset the password for this coach. Please note this cannot be
        undone.
      </T.P>
      <S.LinkButton
        onClick={() =>
          setState({ showResetPasswordForm: !showResetPasswordForm })
        }
      >
        {showResetPasswordForm ? 'Hide' : 'Show'} Reset Password Form
      </S.LinkButton>

      {showResetPasswordForm && (
        <S.Form onSubmit={(e) => e.preventDefault()}>
          <Row>
            <Col w={[4, 12, 8]}>
              <PasswordInput
                margins={{ mt: '2', mb: '1' }}
                label="Create new password"
                placeholder="Password..."
                password={password}
                autoFocus
                handleChange={(password) => setState({ password })}
                error={validationErrs.password}
              />
            </Col>
          </Row>
          <S.ButtonsWrapper mt="3">
            <S.ButtonContainer w={[4, 12, 6]}>
              <BasicButton
                mt={httpError ? '2' : '6'}
                variant="secondary"
                bgColor="tertiaryMain"
                disabled={isLoading || !password}
                loading={isLoading}
                type="button"
                onClick={handleSubmit}
                id="create-new-password"
              >
                Reset password
              </BasicButton>
            </S.ButtonContainer>
          </S.ButtonsWrapper>
        </S.Form>
      )}
      {/* Confirm Modal */}
      <Modal
        onCancel={() => setState({ warningModalVisible: false })}
        visible={warningModalVisible}
      >
        <Row>
          <Col w={[4, 12, 12]} mb={4}>
            <T.H3 color="neutralSurface">
              Are you sure you want to reset the password for this coach?
            </T.H3>
          </Col>
        </Row>
        {httpError && (
          <Row>
            <Col w={[4, 12, 12]}>
              <T.P color="error">
                {httpError.message ||
                  'There was an error resetting the password. Please try again later.'}
              </T.P>
            </Col>
          </Row>
        )}
        <BasicButton
          mt={httpError ? '2' : '6'}
          variant="secondary"
          loading={isLoading}
          disabled={isLoading || !password}
          handleClick={handleCreateNewPassword}
        >
          <T.P color="white" weight="bold">
            Yes, reset password
          </T.P>
        </BasicButton>
        <BasicButton
          mt={3}
          to={'#'}
          variant="tertiary"
          handleClick={() => setState({ warningModalVisible: false })}
        >
          No, go back
        </BasicButton>
      </Modal>

      {/* SUCCESS MODAL */}
      <Modal visible={successModalVisible}>
        <S.ModalContent>
          <T.H1 mb={3} color="neutralSurface" ta="center">
            Password updated successfully! 🎉
          </T.H1>

          <T.P
            mb={3}
            color="neutralSurface"
            ta="center"
            textDecoration="underline"
          >
            if you close this modal, you will not be able to see the password
            again.
          </T.P>
          <S.CopyWrapper>
            <InlineCopy
              text={`New Password: ${password}`}
              textToCopy={password}
              color="neutralSurface"
            />
          </S.CopyWrapper>

          <BasicButton
            mt="4"
            variant="secondary"
            handleClick={() => {
              setState({
                successModalVisible: false,
                showResetPasswordForm: false,
                password: '',
              });
              submitAttempt.current = false;
            }}
          >
            <T.P color="white" weight="bold">
              Close
            </T.P>
          </BasicButton>
        </S.ModalContent>
      </Modal>
    </>
  );
};

export default ResetCoachPassword;
