import styled from '@emotion/styled';
import setMargin from '../../../../helpers/set-margin';

export const Wrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  flex-direction: column;
  flex: 1;
  margin-top: ${({ theme }) => theme.spacings[2]};
  display: ${({ display }) => display};
`;

export const Row = styled.div`
  ${setMargin};
  width: 100%;
  min-width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${({ theme }) => `${theme.spacings[6]} ${theme.spacings[1]}`};
  background: ${({ theme, bgColor }) =>
    bgColor ? theme.colors[bgColor] || bgColor : theme.colors.neutralLight};
`;
