import * as T from '../Typography';
import { useSiteSettings } from 'context/site-settings';
import * as S from './style';

// plays YouTube video embeds
const backUpTemplate = 'https://www.youtube.com/embed/LfsXExFeBg0';
const Video = ({
  src = backUpTemplate,
  width = '100%',
  height = '236px',
  showRadius,
  ...props
}) => {
  const { isWorkingInPrisonEnvironment } = useSiteSettings();

  if (isWorkingInPrisonEnvironment) {
    return (
      <S.PlaceHolderContainer>
        <T.P>Sorry, this video is not available in this environment</T.P>
      </S.PlaceHolderContainer>
    );
  }

  return (
    <S.ResponsiveYoutube showRadius={showRadius}>
      <iframe
        width="560"
        height="315"
        src={src}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        {...props}
      ></iframe>
    </S.ResponsiveYoutube>
  );
};

export default Video;
