import styled from '@emotion/styled';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const ContentWrapper = styled.div`
  margin-bottom: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ButtonsWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacings[5]};
`;

export const TextAudioWrapper = styled.div`
  width: 100%;
  margin-top: ${({ theme, mt }) => theme.spacings[mt || 0]};
  margin-bottom: ${({ theme, mb }) => theme.spacings[mb || 0]};
  display: flex;
  align-items: center;
  justify-content: ${({ jc }) => jc || 'center'};
  ${({ theme }) => theme.media.mobile} {
    width: 300px;
  }
`;

export const OptionsWrapper = styled.div`
  display: flex;
  margin-top: ${({ theme }) => theme.spacings[5]};
  padding: 0 ${({ theme }) => theme.spacings[5]};
  flex-direction: ${({ needsRowFormat }) => (needsRowFormat ? 'col' : 'row')};
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
`;

export const OptionWrapper = styled.div`
  width: ${({ needsRowFormat }) => (needsRowFormat ? '100%' : '50%')};
`;
