import { Row, Col } from '../../../components/Grid';
import Video from '../../../components/Video';
import * as T from '../../../components/Typography';
import Modal from '../../../components/Modal';
import PageIconBanner from '../../../components/PageIconBanner';
import { BasicButton } from '../../../components/Button';
import Loading from '../../../components/Loading';
import CopyInviteLink from '../../../components/CopyInviteLink';

import AddCoachesTips from './AddCoachesTips';
import NeedHelpCard from './NeedHelpCard';
import AddCoachesCard from './AddCoachesCard';
import { useOrganisationDetails } from '../../../context/organisation-details';
import {
  EXTERNAL,
  ORGANISATION_ADMIN,
  GENERAL,
} from '../../../constants/nav-routes';

import * as S from './style';
import { useGetOrgProgress } from '../../../Hooks';
import { BackButton } from 'components/BackButton';
import { useSiteSettings } from 'context/site-settings';
import AddCoachForPrisonCard from './AddCoachForPrisonCard';

const AddCoaches = () => {
  const { organisationDetails } = useOrganisationDetails();
  const { loading, hasFacilitator } = useGetOrgProgress();
  const textToCopy = `${window.location.origin}${GENERAL.INTEREST.replace(
    ':uniqueSlug',
    organisationDetails?.uniqueSlug
  )}`;
  const { courseName, isWorkingInPrisonEnvironment } = useSiteSettings();

  if (loading) return <Loading />;

  return (
    <>
      <BackButton />
      <Row inner mb="9" mt="6">
        <Col w={[4, 12, 9]} mb="5">
          <PageIconBanner
            icon="usersGroup"
            color="neutralMain"
            title="Add coaches"
          >
            <T.P>
              Here, you can set up coaches for your {courseName} tool. Coaches
              provide 1:1 support to help learners improve reading skills,
              offering encouragement and guidance to set and achieve goals
              without acting as teachers or tutors.
            </T.P>
          </PageIconBanner>
        </Col>
        <S.LeftWrapper w={[4, 12, 7]} dir="column" ai="flex-start">
          <AddCoachesTips
            courseName={courseName}
            isWorkingInPrisonEnvironment={isWorkingInPrisonEnvironment}
          />
          {!isWorkingInPrisonEnvironment && (
            <>
              <S.VideoWrapper>
                <Video src={EXTERNAL.TURNING_PAGES_APP_VIDEO} showRadius />
              </S.VideoWrapper>
              <T.H2 mt="40px" mb="3">
                Recruit coaches
              </T.H2>
              <T.P>
                Customise our ready made recruitment form so you can invite
                potential to express interest in becoming a coach for you, and
                manage their entire approval process right here on the tool{' '}
              </T.P>
              <CopyInviteLink textToCopy={textToCopy} />
            </>
          )}
        </S.LeftWrapper>
        <S.RightWrapper w={[4, 12, 5]} mt="0" mtT="6">
          {isWorkingInPrisonEnvironment ? (
            <AddCoachForPrisonCard />
          ) : (
            <>
              <NeedHelpCard uniqueSlug={organisationDetails?.uniqueSlug} />
              <AddCoachesCard />
            </>
          )}
        </S.RightWrapper>
      </Row>

      <Modal visible={!hasFacilitator} maskColor="rgba(255, 255, 255, 0.5)">
        <T.H2 mb={4} color="quaternaryLight">
          You need a facilitator first
        </T.H2>
        <T.P mb={4} color="quaternaryLight">
          At the moment, there are no facilitators registered under your
          account.
          <br />
          To add coaches, either ask your invited facilitators to create an
          account or become a facilitator yourself.
          <br />
          Please note that adding coaches requires facilitators to have created
          their accounts.
        </T.P>
        <BasicButton
          to={ORGANISATION_ADMIN.ADD_FACILITATORS}
          variant="secondary"
        >
          <T.P color="neutralSurface" weight="bold">
            Add facilitator
          </T.P>
        </BasicButton>
      </Modal>
    </>
  );
};

export default AddCoaches;
