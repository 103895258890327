import { useCallback, useEffect, useRef, useState } from 'react';

import * as T from '../../../../components/Typography';
import { PlayButton } from '../../../../components/Button';
import { useLocation } from 'react-router-dom';
import StepFooterButtons from '../StepFooterButtons';
import * as SharedStyles from '../shared/style';
import NumbersWheel from './NumbersWheel';
import { getOperatorSymbol } from 'helpers';
import { wheelOperationTypes } from 'constants';
import { Row } from 'components/Grid';
import * as S from './style';
import { useExercise } from 'context/exercise';

const Wheel = ({
  data: {
    textAudio,
    textAudioUrl,
    operationType = wheelOperationTypes.MULTIPLICATION,
    centerNumber,
    innerNumbers,
    hideInnerNumbers,
    outerNumbers,
    hideOuterNumbers,
  } = {},
  handleNext,
  stuckAtReview,
  disabled,
  preview,
  cmsPreview,
}) => {
  const [enableNext, setEnableNext] = useState(false);
  const [currentWheelStepIndex, setCurrentWheelStepIndex] = useState(0);
  const [wheelSteps, setWheelSteps] = useState([
    { id: 0, operation: '', answer: null },
  ]);
  const location = useLocation();
  const operator = getOperatorSymbol(operationType);
  const centerValue = `${centerNumber}${operator}`;
  const currentWheelStep = wheelSteps[currentWheelStepIndex];
  const originalAnswers = hideOuterNumbers ? outerNumbers : innerNumbers;
  const inputRef = useRef(null);
  const { setSubProgress } = useExercise();

  // Generate wheel steps based on center number and inner numbers
  useEffect(() => {
    // Reset enable next
    setEnableNext(false);

    const numbersToDisplay = hideOuterNumbers ? innerNumbers : outerNumbers;

    // If no numbers to display, return early
    if (!numbersToDisplay || numbersToDisplay.length === 0) return;

    // Generate steps based on operation type
    const generatedSteps = numbersToDisplay.map((innerNum, index) => ({
      id: index + 1,
      operation: `${centerNumber} ${operator} ${innerNum} =`,
      answer: null,
    }));

    setWheelSteps(generatedSteps);
  }, [centerNumber, hideOuterNumbers, innerNumbers, operator, outerNumbers]);

  useEffect(() => {
    setEnableNext(false);
  }, [location]);

  const customHandleNext = useCallback(() => {
    const requiredSteps = wheelSteps.length;
    const completedSteps = wheelSteps.filter((ws) => ws.answer !== null).length;
    const lastWheelStepIndex = wheelSteps.length - 1;
    if (currentWheelStepIndex < lastWheelStepIndex) {
      setCurrentWheelStepIndex((prev) => prev + 1);
      setSubProgress(completedSteps / requiredSteps);
      setEnableNext(false);
    } else if (currentWheelStepIndex === lastWheelStepIndex) {
      setCurrentWheelStepIndex(lastWheelStepIndex);
      handleNext();
    }
  }, [currentWheelStepIndex, handleNext, setSubProgress, wheelSteps]);

  const onWheelStepAnswerChange = useCallback(
    (answer) => {
      const originalAnswer = Number(originalAnswers[currentWheelStepIndex]);
      const updatedWheelStep = [...wheelSteps];
      updatedWheelStep[currentWheelStepIndex].answer = answer;
      setWheelSteps(updatedWheelStep);
      if (answer !== originalAnswer) {
        return setEnableNext(false);
      }
      setEnableNext(true);
    },
    [currentWheelStepIndex, originalAnswers, wheelSteps]
  );

  // Make the SharedStyles.TypeInput focused while the user move to the next wheelStep and clear it's previous answer
  useEffect(() => {
    if (!cmsPreview) {
      inputRef.current.focus();
      inputRef.current.value = null;
    }
  }, [cmsPreview, currentWheelStepIndex]);

  return (
    <SharedStyles.Wrapper>
      <SharedStyles.ContentWrapper mx={7} ai="center">
        {!!textAudio && (
          <SharedStyles.TextAudioWrapper mt="2" mb="4">
            {textAudioUrl ? (
              <>
                <PlayButton
                  audioUrl={textAudioUrl}
                  width="56px"
                  height="56px"
                  iconProps={{ width: '32px', height: '32px' }}
                  fallbackText={textAudio}
                />
                <T.P ml={4} weight="regular" size="med">
                  {textAudio}
                </T.P>
              </>
            ) : (
              <T.P weight="regular" size="regular">
                {textAudio}
              </T.P>
            )}
          </SharedStyles.TextAudioWrapper>
        )}

        <Row my={7} jc="center">
          <NumbersWheel
            innerNumbers={
              hideInnerNumbers
                ? wheelSteps.map((ws) => ws.answer)
                : innerNumbers
            }
            outerNumbers={
              hideOuterNumbers
                ? wheelSteps.map((ws) => ws.answer)
                : outerNumbers
            }
            centerValue={centerValue}
          />
        </Row>

        {currentWheelStep && (
          <S.QuestionContainer>
            <T.P size="large">{currentWheelStep.operation}</T.P>
            <SharedStyles.TypeInput
              ref={inputRef}
              id={`wheel-step-answer-${currentWheelStep.id}`}
              name={`wheel-step-answer-${currentWheelStep.id}`}
              type="number"
              aria-labelledby={`wheel-step-answer-${currentWheelStep.id}`}
              value={currentWheelStep.answer ?? null}
              onChange={(e) => onWheelStepAnswerChange(Number(e.target.value))}
              isFullWidth
            />
          </S.QuestionContainer>
        )}
      </SharedStyles.ContentWrapper>

      <StepFooterButtons
        stuckAtReview={stuckAtReview}
        handleNext={customHandleNext}
        preview={preview}
        disabled={disabled}
        enableNext={enableNext}
      />
    </SharedStyles.Wrapper>
  );
};

export default Wheel;
