import styled from '@emotion/styled';
import { setMargin } from 'helpers';
import { Row, Col } from '../Grid';

export const LinkButton = styled.button`
  ${setMargin};
  all: unset;
  font-size: 16px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.tertiaryMain};
  text-decoration: underline;
  cursor: pointer;
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Form = styled.form`
  width: 100%;
  padding-top: ${({ theme }) => theme.spacings[4]};
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const ButtonsWrapper = styled(Row)`
  flex: 0;
  align-items: flex-end;
  ${({ theme }) => theme.media.tablet} {
    flex: 1;
  }
`;

export const ButtonContainer = styled(Col)`
  align-items: flex-end;
`;

export const CopyWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
