import { useMemo, useRef, Fragment } from 'react';

import { BasicInput, Dropdown } from '../../../../../components/Inputs';
import { MediaKeyInput } from '../../../../components/MediaKeyInput';
import {
  mediaTypes,
  questionAndAnswerVariations,
} from '../../../../../constants';
import { convertSnakeCaseToSpaces } from 'helpers';
import MDEditor from '../../../../../components/MDEditor';
import { generateInitialCorrectOptions } from './utils';
import CorrectAnswers from './CorrectAnswers';
import { useStepForm } from 'CMS/Providers/StepFormProvider';
import { Icon } from 'components';
import * as S from '../style';
export { validationSchema } from './validation';

const initialData = {
  correctOptions: [{ id: 0, option: '' }],
  variation: '',
  textAudio: '',
  textAudioKey: '',
  paragraphs: [],
  questions: [{ id: 0, question: '', questionAudioKey: '' }],
};

const QuestionAndAnswer = () => {
  // Use useRef to keep the initial state constant across renders
  const initialRef = useRef(initialData);
  const { state, updateFormFields, updateDataFields } = useStepForm(
    initialRef.current
  );
  const { form, data, validationErrs } = state;

  const correctOptions = useMemo(
    () => data.correctOptions || [{ id: 0, option: '' }],
    [data.correctOptions]
  );
  const variation = useMemo(() => data.variation, [data.variation]);

  const onOptionChange = ({ key, value, id }) => {
    updateDataFields({
      correctOptions: correctOptions.map((e) => {
        if (e.id !== id) return e;
        return {
          ...e,
          [key]: value,
        };
      }),
    });
  };

  const onVariationChange = (variation) => {
    updateDataFields({
      variation,
      correctOptions: generateInitialCorrectOptions(variation),
      paragraphs: [],
      questions: [{ id: 0, question: '', questionAudioKey: '' }],
    });
  };

  const variationOptions = Object.keys(
    questionAndAnswerVariations.variations
  ).map((e) => ({
    label: convertSnakeCaseToSpaces(e),
    value: e,
  }));

  // use same logic in ExerciseStep/QuestionAndAnswer/index.js
  const showImage =
    variation !== questionAndAnswerVariations.variations.NO_IMAGE;
  const noImage = variation === questionAndAnswerVariations.variations.NO_IMAGE;
  const showQuestion =
    questionAndAnswerVariations.showQuestionVariations.includes(variation);

  const addParagraph = () => {
    const maxId = Math.max(...data.paragraphs.map((e) => e.id), 0);
    updateDataFields({
      paragraphs: [...data.paragraphs, { id: maxId + 1, text: '' }],
    });
  };

  const onParagraphChange = ({ text, id }) => {
    const newParagraphs = data.paragraphs?.map((e) => {
      if (e.id !== id) return e;
      return {
        ...e,
        text,
      };
    });
    updateDataFields({ paragraphs: newParagraphs });
  };

  const addQuestionAndAnswer = () => {
    const maxId = Math.max(...data.questions?.map((e) => e.id), 0);
    updateDataFields({
      questions: [
        ...data?.questions,
        { id: maxId + 1, question: '', questionAudioKey: '' },
      ],
    });
    updateDataFields({
      correctOptions: [...data.correctOptions, { id: maxId + 1, option: '' }],
    });
  };

  const onQuestionChange = ({ key, value, id }) => {
    const newQuestions = data.questions?.map((e) => {
      if (e.id !== id) return e;
      return {
        ...e,
        [key]: value,
      };
    });
    updateDataFields({ questions: newQuestions });
  };

  return (
    <div>
      <Dropdown
        label="Choose a variation"
        options={variationOptions}
        selected={
          variation
            ? {
                value: variation,
                label: convertSnakeCaseToSpaces(variation),
              }
            : null
        }
        handleChange={onVariationChange}
        error={validationErrs?.variation}
      />

      {!!variation && (
        <>
          <BasicInput
            label="Explainer Text"
            value={data.textAudio}
            handleChange={(textAudio) => updateDataFields({ textAudio })}
            m={{ mt: 8 }}
            error={validationErrs?.textAudio}
          />
          <MediaKeyInput
            type={mediaTypes.TEXT_AUDIO}
            label={`Explainer Text file Key`}
            value={data.textAudioKey}
            handleChange={(textAudioKey) => updateDataFields({ textAudioKey })}
            m={{ mt: 5 }}
            error={validationErrs?.textAudioKey}
          />

          {showImage ? (
            <MediaKeyInput
              type={mediaTypes.IMAGE}
              label="Image Key"
              value={form.imageKey}
              handleChange={(imageKey) => updateFormFields({ imageKey })}
              m={{ mb: 5, mt: 5 }}
              error={validationErrs?.imageKey}
            />
          ) : (
            <>
              {data.paragraphs?.map((paragraph, index) => (
                <MDEditor
                  key={`paragraph-${index}`}
                  value={paragraph.text}
                  onChange={(text) =>
                    onParagraphChange({ id: paragraph.id, text })
                  }
                  mode={'edit'}
                  label={`Paragraph ${index + 1}`}
                  m={{ mt: 8 }}
                  helper="Provide the paragraph"
                  error={validationErrs.paragraph}
                />
              ))}
              {data.paragraphs?.length < 2 && (
                <S.Button
                  type="link"
                  ghost
                  mt={5}
                  mb={5}
                  onClick={addParagraph}
                  ml="auto"
                  mr="auto"
                >
                  <Icon icon="plus" /> Add Paragraph
                </S.Button>
              )}
            </>
          )}

          {showQuestion &&
            data.questions?.map(({ id, question, questionAudioKey }, index) => (
              <Fragment key={`question-${id}`}>
                <BasicInput
                  label="Question"
                  value={question}
                  handleChange={(value) =>
                    onQuestionChange({ key: 'question', value, id })
                  }
                  m={{ mt: 8 }}
                  error={validationErrs?.questions?.[index]?.question}
                />
                <MediaKeyInput
                  index={index}
                  type={mediaTypes.QUESTION_AUDIO}
                  label={`Question file Key`}
                  value={questionAudioKey}
                  handleChange={(value) =>
                    onQuestionChange({ key: 'questionAudioKey', value, id })
                  }
                  m={{ mt: 5, mb: 4 }}
                  error={validationErrs?.questions?.[index]?.questionAudioKey}
                />
                {noImage && (
                  <BasicInput
                    key={`correctOption-${index}`}
                    value={correctOptions?.[index]?.option}
                    handleChange={(value) => {
                      onOptionChange({
                        key: 'option',
                        value,
                        id,
                      });
                    }}
                    error={
                      validationErrs?.correctOptions &&
                      validationErrs?.correctOptions?.[index]?.option
                    }
                    placeholder="0"
                    my={4}
                  />
                )}
              </Fragment>
            ))}
          {!noImage && (
            <CorrectAnswers
              variation={variation}
              validationErrs={validationErrs}
              onOptionChange={onOptionChange}
              correctOptions={correctOptions}
            />
          )}
          {noImage && data.questions?.length < 6 && (
            <S.Button
              type="link"
              ghost
              mt={5}
              mb={5}
              onClick={addQuestionAndAnswer}
              ml="auto"
              mr="auto"
            >
              <Icon icon="plus" /> Add Question And Answer
            </S.Button>
          )}
        </>
      )}
    </div>
  );
};

export default QuestionAndAnswer;
