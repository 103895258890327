import { useLayoutEffect } from 'react';

import { useSiteSettings } from './context/site-settings';
import * as ThirdPartyInit from './utils/thirdPartyInitialization';

const ThirdPartyInitializer = ({ ReactGA }) => {
  const siteSettings = useSiteSettings();

  useLayoutEffect(() => {
    if (siteSettings.loaded && !siteSettings.isWorkingInPrisonEnvironment) {
      const isProduction =
        window.location.hostname.includes('turningpages.co.uk') ||
        window.location.hostname.includes('countmeindigital.co.uk');

      if (isProduction) {
        ThirdPartyInit.cookiebotInit();
        ThirdPartyInit.hotJarInit();
        ThirdPartyInit.freshDeskInit();
        ThirdPartyInit.googleAnalyticsInit(ReactGA);
      }

      // allow sentry to work in staging
      ThirdPartyInit.sentryInit();
    }
  }, [ReactGA, siteSettings]);

  return null;
};

export default ThirdPartyInitializer;
