import styled from '@emotion/styled';
// import theme from './../../theme';
import setMargin from '../../helpers/set-margin';
import * as T from '../Typography';
import { useTheme } from '@emotion/react';

// ICONS
import Tick from './icons/Tick';
import Next from './icons/Next';
import Back from './icons/Back';
import QuestionMark from './icons/QuestionMark';
import BookmarkSaved from './icons/BookmarkSaved';
import Eye from './icons/Eye';
import EyeSlash from './icons/EyeSlash';
import Profile from './icons/Profile';
import Lock from './icons/Lock';
import Note from './icons/Note';
import Like from './icons/Like';
import Cup from './icons/Cup';
import TryAgain from './icons/TryAgain';
import Milestone from './icons/Milestone';
import Book from './icons/Book';
import LearningAndCoachingTime from './icons/LearningAndCoachingTime';
import Report from './icons/Report';
import Device from './icons/Device';
import Speaker from './icons/Speaker';
import ByYourSelf from './icons/ByYourSelf';
import WithCoach from './icons/WithCoach';
import Search from './icons/Search';
import ChevronDown from './icons/ChevronDown';
import ChevronRight from './icons/ChevronRight';
import OpenedBook from './icons/OpenedBook';
import ArrowIncrease from './icons/ArrowIncrease';
import Smile from './icons/Smile';
import Accolade from './icons/Accolade';
import Copy from './icons/Copy';
import UserWithArrow from './icons/UserWithArrow';
import Calendar from './icons/Calendar';
import Check from './icons/Check';
import Cross from './icons/Cross';
import SmallTick from './icons/SmallTick';
import ProfileEye from './icons/ProfileEye';
import Logout from './icons/Logout';
import Alert from './icons/Alert';
import Plus from './icons/Plus';
import ArrowUp from './icons/ArrowUp';
import ArrowDown from './icons/ArrowDown';
import DashedLine from './icons/DashedLine';
import CurvedDashedLine from './icons/CurvedDashedLine';
import CurvedDashedLineBackground from './icons/CurvedDashedLineBackground';
import ArrowLine from './icons/ArrowLine';
import ArrowLineUp from './icons/ArrowLineUp';
import Phone from './icons/Phone';
import AchievePersonalGoal from './icons/AchievedPersonalGoal';
import AttendFiveSessions from './icons/AttendedFiveSessions';
import Bullseye from './icons/Bullseye';
import CompletedFirstReadingBook from './icons/CompletedFirstReadingBook';
import DropTheMic from './icons/DropTheMic';
import EndOfM1 from './icons/EndOfM1';
import EndOfM2 from './icons/EndOfM2';
import EndOfM3 from './icons/EndOfM3';
import EndOfM4 from './icons/EndOfM4';
import EndOfM5 from './icons/EndOfM5';
import FirstStep from './icons/FirstStep';
import ListenAndRepeat from './icons/ListenAndRepeat';
import MakingProgress from './icons/MakingProgress';
import MasterInTheMaking from './icons/MasterInTheMaking';
import SpoiltForChoice from './icons/SpoiltForChoice';
import StreakMaster from './icons/StreakMaster';
import StreakProdigy from './icons/StreakProdigy';
import WordBuilder from './icons/WordBuilder';
import WordCollector from './icons/WordCollector';
import WordSmith from './icons/WordSmith';
import Hamburger from './icons/Hamburger';
import Exercises from './icons/Exercises';
import Goals from './icons/Goals';
import MilestoneNew from './icons/MilestoneNew';
import FeedbackLow from './icons/FeedbackLow';
import FeedbackMedium from './icons/FeedbackMedium';
import FeedbackHigh from './icons/FeedbackHigh';
import Edit from './icons/Edit';
import Trash from './icons/Trash';
import Lens from './icons/Lens';
import ToggleActive from './icons/ToggleActive';
import ToggleDeActive from './icons/ToggleDeActive';
import Info from './icons/Info';
import Copy2 from './icons/Copy2';
import UsersGroup from './icons/UsersGroup';
import Catalog from './icons/Catalog';
import FileUpload from './icons/FileUpload';
import UserWithPlus from './icons/UserWithPlus';
import CircleTick from './icons/CircleTick';
import Copy3 from './icons/Copy3';
import ArrowBack from './icons/ArrowBack';
import Close from './icons/Close';
import AlarmClock from './icons/AlarmClock';
import Awaiting from './icons/Awaiting';
import SandGlass from './icons/SandGlass';
import StandingPerson from './icons/StandingPerson';
import Copy4 from './icons/Copy4';
import OpenLink from './icons/OpenLink';
import LeftChevron from './icons/LeftChevron';
import Clock from './icons/Clock';
import ShortAnswer from './icons/ShortAnswer';
import Paragraph from './icons/Paragraph';
import Circle from './icons/Circle';
import Square from './icons/Square';
import Trash2 from './icons/Trash2';
import SixDots from './icons/SixDots';
import Checklist from './icons/Checklist';
import Link from './icons/Link';
import DividerLine from './icons/DividerLine';
import Face1 from './icons/Face1';
import Face2 from './icons/Face2';
import Face3 from './icons/Face3';
import Face4 from './icons/Face4';
import Expand from './icons/Expand';
import Assign from './icons/Assign';
import Export from './icons/Export';
import RightChevron from './icons/RightChevron';
import Bell from './icons/Bell';
import LeftProgressToRightStep from './icons/LeftProgressToRightStep';
import LeftStepToRightStep from './icons/LeftStepToRightStep';
import RighSteptToLeftStep from './icons/RighSteptToLeftStep';
import RightProgressToLeftStep from './icons/RightProgressToLeftStep';
import StepToProgress from './icons/StepToProgress';
import StartPath from './icons/StartPath';
import Edit2 from './icons/Edit2';
import ThreePersons from './icons/ThreePersons';
import ActiveUser from './icons/ActiveUser';
import UserWithPlusFill from './icons/UserWithPlusFill';
import UserMenu from './icons/UserMenu';
import Logo from './icons/Logo';
import Send from './icons/Send';
import WarningFilled from './icons/WarningFilled';
import Message from './icons/Message';
import Edit3 from './icons/Edit3';
import Mic from './icons/Mic';
import Play from './icons/Play';
import Pause from './icons/Pause';
import LogoCMID from './icons/LogoCMID';
import CmidIcon from './icons/CmidIcon';
import Eraser from './icons/Eraser';
import ArrowDropDown from './icons/ArrowDropDown';
import ArrowDropUp from './icons/ArrowDropUp';
import Edit4 from './icons/Edit4';
import Triangle from './icons/Triangle';
import Pencil from './icons/Pencil';

export const IconMap = {
  tick: Tick,
  standingPerson: StandingPerson,
  next: Next,
  back: Back,
  questionMark: QuestionMark,
  bookmarkSaved: BookmarkSaved,
  eye: Eye,
  eyeSlash: EyeSlash,
  profile: Profile,
  lock: Lock,
  note: Note,
  like: Like,
  cup: Cup,
  tryAgain: TryAgain,
  milestone: Milestone,
  book: Book,
  alarmClock: AlarmClock,
  learningAndCoachingTime: LearningAndCoachingTime,
  report: Report,
  device: Device,
  search: Search,
  chevronDown: ChevronDown,
  chevronRight: ChevronRight,
  speaker: Speaker,
  byYourSelf: ByYourSelf,
  withCoach: WithCoach,
  openedBook: OpenedBook,
  arrowIncrease: ArrowIncrease,
  smile: Smile,
  accolade: Accolade,
  copy: Copy,
  userWithArrow: UserWithArrow,
  calendar: Calendar,
  check: Check,
  cross: Cross,
  smallTick: SmallTick,
  profileEye: ProfileEye,
  logout: Logout,
  alert: Alert,
  plus: Plus,
  arrowUp: ArrowUp,
  arrowDown: ArrowDown,
  dashedLine: DashedLine,
  curvedDashedLine: CurvedDashedLine,
  curvedDashedLineBackground: CurvedDashedLineBackground,
  arrowLine: ArrowLine,
  arrowLineUp: ArrowLineUp,
  phone: Phone,
  achievedPersonalGoal: AchievePersonalGoal,
  attendedFiveSessions: AttendFiveSessions,
  bullseye: Bullseye,
  completedFirstReadingBook: CompletedFirstReadingBook,
  dropTheMic: DropTheMic,
  endOfM1: EndOfM1,
  endOfM2: EndOfM2,
  endOfM3: EndOfM3,
  endOfM4: EndOfM4,
  endOfM5: EndOfM5,
  firstStep: FirstStep,
  listenAndRepeat: ListenAndRepeat,
  makingProgress: MakingProgress,
  masterInTheMaking: MasterInTheMaking,
  spoiltForChoice: SpoiltForChoice,
  streakMaster: StreakMaster,
  streakProdigy: StreakProdigy,
  wordBuilder: WordBuilder,
  wordCollector: WordCollector,
  wordSmith: WordSmith,
  hamburger: Hamburger,
  exercises: Exercises,
  goals: Goals,
  milestoneNew: MilestoneNew,
  feedbackLow: FeedbackLow,
  feedbackMedium: FeedbackMedium,
  feedbackHigh: FeedbackHigh,
  edit: Edit,
  trash: Trash,
  trash2: Trash2,
  lens: Lens,
  toggleActive: ToggleActive,
  toggleDeActive: ToggleDeActive,
  info: Info,
  copy2: Copy2,
  usersGroup: UsersGroup,
  catalog: Catalog,
  fileUpload: FileUpload,
  userWithPlus: UserWithPlus,
  circleTick: CircleTick,
  copy3: Copy3,
  arrowBack: ArrowBack,
  close: Close,
  awaiting: Awaiting,
  sandGlass: SandGlass,
  copy4: Copy4,
  openLink: OpenLink,
  leftChevron: LeftChevron,
  clock: Clock,
  shortAnswer: ShortAnswer,
  paragraph: Paragraph,
  circle: Circle,
  square: Square,
  sixDots: SixDots,
  checklist: Checklist,
  link: Link,
  dividerLine: DividerLine,
  face1: Face1,
  face2: Face2,
  face3: Face3,
  face4: Face4,
  expand: Expand,
  assign: Assign,
  export: Export,
  rightChevron: RightChevron,
  bell: Bell,
  startPath: StartPath,
  leftProgressToRightStep: LeftProgressToRightStep,
  leftStepToRightStep: LeftStepToRightStep,
  righSteptToLeftStep: RighSteptToLeftStep,
  rightProgressToLeftStep: RightProgressToLeftStep,
  rtepToProgress: StepToProgress,
  edit2: Edit2,
  edit4: Edit4,
  threePersons: ThreePersons,
  activeUser: ActiveUser,
  userWithPlusFill: UserWithPlusFill,
  userMenu: UserMenu,
  logo: Logo,
  cmidLogo: LogoCMID,
  cmidIcon: CmidIcon,
  message: Message,
  send: Send,
  warningFilled: WarningFilled,
  edit3: Edit3,
  mic: Mic,
  play: Play,
  pause: Pause,
  eraser: Eraser,
  arrowDropDown: ArrowDropDown,
  arrowDropUp: ArrowDropUp,
  triangle: Triangle,
  pencil: Pencil,
};

const Parent = styled.div`
  ${setMargin}
  display: flex;
  align-items: center;
  height: ${({ height }) => height || 'auto'};
  justify-content: ${({ jc }) => jc || 'flex-start'};
  cursor: ${({ pointer, disabled }) => {
    if (!disabled) {
      return pointer ? 'pointer' : 'unset';
    }
    return 'not-allowed';
  }};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`;

const Icon = (props) => {
  const theme = useTheme();
  const { icon, color, text, weight = 'bold', pointer, disabled } = props;

  if (!IconMap[icon]) {
    // eslint-disable-next-line no-console
    console.warn(`<Icon /> called with invalid icon prop "${icon}"`);
    return null;
  }

  const StyledIcon = IconMap[icon];

  return (
    <Parent
      {...props}
      onClick={props.onClick}
      pointer={pointer || props.onClick}
    >
      <StyledIcon
        {...props}
        color={
          disabled
            ? theme.colors.neutralMain
            : theme.colors[color] || color || 'currentColor'
        }
        aria-disabled={disabled ? 'true' : 'false'}
      />
      {text && (
        <T.P weight={weight} ml="1" color={color}>
          {text}
        </T.P>
      )}
    </Parent>
  );
};

export default Icon;
