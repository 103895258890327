import React, { useRef, useState } from 'react';
import CanvasDraw from 'react-canvas-draw';
import theme from 'theme';
import { Col, Row } from '../../../../components/Grid';
import { ClearButton, PlayButton } from '../../../../components/Button';
import StepFooterButtons from '../StepFooterButtons';
import * as T from '../../../../components/Typography';
import * as S from './style';
import * as SharedStyles from '../shared/style';

const Draw = ({
  data: { textAudio, textAudioUrl, question, questionAudioUrl } = {},
  imageUrl,
  handleNext,
  disabled,
  stuckAtReview,
  preview,
}) => {
  const canvasRef = useRef(null);
  const [enableNext, setEnableNext] = useState(false);

  return (
    <S.Wrapper>
      <Row jc="center" mb={2}>
        {question && (
          <Col w={[4, 7, 7]} mt={6}>
            <SharedStyles.ButtonWrapper>
              <SharedStyles.TextAudioWrapper mt="6" mb="3">
                {questionAudioUrl && (
                  <PlayButton
                    audioUrl={questionAudioUrl}
                    width="56px"
                    height="56px"
                    iconProps={{ width: '32px', height: '32px' }}
                    fallbackText={question}
                  />
                )}
                <T.P ml={4} weight="bold" size="med">
                  {question}
                </T.P>
              </SharedStyles.TextAudioWrapper>
            </SharedStyles.ButtonWrapper>
          </Col>
        )}
      </Row>
      <Row jc="center" mb={2}>
        {textAudio && (
          <Col w={[4, 8, 8]} jc="center">
            {textAudioUrl ? (
              <>
                <PlayButton
                  audioUrl={textAudioUrl}
                  width="56px"
                  height="56px"
                  iconProps={{ width: '32px', height: '32px' }}
                  fallbackText={textAudio}
                />
                <T.P ml={3} weight="regular" size="extraLarge">
                  {textAudio}
                </T.P>
              </>
            ) : (
              <T.P size="extraLarge" weight="bold">
                {textAudio}
              </T.P>
            )}
          </Col>
        )}
      </Row>
      <Row jc="center" mb={6}>
        <Col w={[4, 7, 7]} mt={6}>
          <CanvasDraw
            brushColor={`${theme.colors.neutralMain}`}
            imgSrc={imageUrl}
            gridColor={theme.colors.neutral80}
            hideGrid={false}
            hideGridX={false}
            hideGridY={false}
            hideInterface={true}
            canvasWidth={800}
            canvasHeight={400}
            gridSizeX={15}
            gridSizeY={15}
            gridLineWidth={0.5}
            ref={canvasRef}
            onChange={() => {
              setEnableNext(true);
            }}
            brushRadius={5}
            lazyRadius={0}
          />
        </Col>
        <Col w={[4, 7, 7]} mt={6}>
          <ClearButton
            handleClick={() => {
              canvasRef.current.clear();
              setEnableNext(false);
            }}
          />
        </Col>
      </Row>
      <Row jc="center" mb={6}>
        <Col w={[4, 7, 7]} mt={6}>
          <StepFooterButtons
            stuckAtReview={stuckAtReview}
            handleNext={handleNext}
            preview={preview}
            disabled={disabled}
            enableNext={enableNext}
          />
        </Col>
      </Row>
    </S.Wrapper>
  );
};

export default Draw;
