import { string, array, object, number } from 'yup';

export const validationSchema = {
  imageKey: string().notRequired(),
  textAudio: string().required(),
  textAudioKey: string().required(),
  options: array()
    .of(
      object()
        .shape({
          id: number().required(),
          prefix: string().required('required field'),
          answer: string().required('required field'),
          suffix: string().required('required field'),
        })
        .required()
    )
    .required('You must provide at least two questions')
    .min(2, 'You must provide at least two questions')
    .max(8, "You can't use add more than 12 questions"),
};
