import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

const sentryInit = () => {
  if (process.env.NODE_ENV === 'production') {
    // SENTRY
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      integrations: [new BrowserTracing()],
      environment: window.location.host.includes('turningpages.co.uk')
        ? 'production'
        : 'staging',

      tracesSampleRate: 0.5,
    });
  }
};

export default sentryInit;
