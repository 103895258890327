import { string, array, object, number, boolean } from 'yup';

export const validationSchema = {
  textAudio: string().required('Explainer Text is required'),
  textAudioKey: string().required('Explainer Text file key is required'),
  numberOfInputs: number()
    .required('Number of inputs is required')
    .min(1, 'Number of inputs must be more than 0')
    .max(10, 'Number of inputs maximum is 10'),
  inputs: array()
    .of(
      object().shape({
        id: string().required('ID is required'),
        value: number().typeError('required').required('Value is required'),
        showValue: boolean().required('Show value is required'),
      })
    )
    .min(1, 'At least one input is required'),
};
