import { Fragment, useEffect, useState } from 'react';

import * as T from '../../../../components/Typography';
import { PlayButton } from '../../../../components/Button';
import { Markdown } from '../../../../components';
import { useLocation } from 'react-router-dom';
import StepFooterButtons from '../StepFooterButtons';
import { Row } from '../../../../components/Grid';

import * as S from './style';
import * as SharedStyles from '../shared/style';
import { questionAndAnswerVariations } from 'constants';
import InputsWrapper, { NormalAnswerInput } from './InputsWrapper';

const QuestionAndAnswer = ({
  data: {
    variation,
    textAudio,
    textAudioUrl,
    questions = [],
    correctOptions,
    paragraphs = [],
  } = {},
  imageUrl,
  handleNext,
  stuckAtReview,
  disabled,
  preview,
}) => {
  const [enableNext, setEnableNext] = useState(false);
  const [userAnswers, setUserAnswers] = useState(
    new Array(correctOptions?.length).fill('')
  );
  const correctAnswers = correctOptions?.map((co) => {
    return { ...co, answer: co.option };
  });
  const location = useLocation();

  useEffect(() => {
    setEnableNext(false);
  }, [location]);

  const isCorrectAnswersProvided =
    correctAnswers?.length > 0 && correctAnswers?.every((ans) => !!ans?.answer);

  useEffect(() => {
    if (isCorrectAnswersProvided) {
      const allAnswersCorrect = userAnswers.every(
        (text, index) => text?.toLowerCase() === correctAnswers[index]?.answer
      );
      setEnableNext(allAnswersCorrect);
    }
  }, [userAnswers, isCorrectAnswersProvided, correctAnswers, setEnableNext]);

  if (!variation) {
    return (
      <S.Wrapper>
        <Row jc="center" mb={6}>
          <T.P color="error" m="0" mt="1">
            Please choose a variation
          </T.P>
        </Row>
      </S.Wrapper>
    );
  }
  // use same logic from CreateStep/StepForms/QuestionAndAnswer.js
  const showImage =
    variation !== questionAndAnswerVariations.variations.NO_IMAGE;
  const showQuestion =
    questionAndAnswerVariations.showQuestionVariations.includes(variation);
  const noImage = variation === questionAndAnswerVariations.variations.NO_IMAGE;

  return (
    <S.Wrapper>
      <S.ContentWrapper mb={noImage ? 7 : 'auto'}>
        {!!textAudio && (
          <S.TextAudioWrapper mt="2" mb="4">
            {textAudioUrl ? (
              <>
                <PlayButton
                  audioUrl={textAudioUrl}
                  width="56px"
                  height="56px"
                  iconProps={{ width: '32px', height: '32px' }}
                  fallbackText={textAudio}
                />
                <T.P ml={4} weight="regular" size="regular">
                  {textAudio}
                </T.P>
              </>
            ) : (
              <T.P weight="regular" size="regular">
                {textAudio}
              </T.P>
            )}
          </S.TextAudioWrapper>
        )}
        {showImage ? (
          <S.ImageWrapper>
            <S.Img src={imageUrl} alt={questions[0]?.question} />
          </S.ImageWrapper>
        ) : (
          paragraphs.map(
            ({ text, id }) =>
              !!text && (
                <SharedStyles.OuterWrapper
                  bg="neutralLight"
                  key={`paragraph-${id}`}
                  mb={4}
                >
                  <Markdown
                    text={text}
                    customStyles={{
                      p: {
                        position: 'relative',
                        zIndex: 4,
                        lh: '30px',
                        padding: '0.15rem',
                        size: 'regular',
                      },
                      li: {
                        size: 'regular',
                      },
                    }}
                  />
                </SharedStyles.OuterWrapper>
              )
          )
        )}
        <S.QuestionAndAnswerWrapper mt={noImage ? 'auto' : 'unset'}>
          {showQuestion &&
            questions.map(({ id, question, questionAudioUrl }, index) => (
              <Fragment key={`question-${id}`}>
                <S.TextAudioWrapper mt="7" mb={2}>
                  <PlayButton
                    audioUrl={questionAudioUrl}
                    width="56px"
                    height="56px"
                    iconProps={{ width: '32px', height: '32px' }}
                    fallbackText={question}
                  />
                  <T.P ml={4} weight="regular" size="med">
                    {question}
                  </T.P>
                </S.TextAudioWrapper>
                {noImage && (
                  <NormalAnswerInput
                    index={index}
                    isWithEnding={false}
                    setUserAnswers={setUserAnswers}
                    userAnswers={userAnswers}
                    correctAnswers={correctAnswers}
                  />
                )}
              </Fragment>
            ))}
          {correctOptions && !noImage && (
            <InputsWrapper
              variation={variation}
              isCorrectAnswersProvided={isCorrectAnswersProvided}
              userAnswers={userAnswers}
              setUserAnswers={setUserAnswers}
              correctAnswers={correctAnswers}
              showQuestion={showQuestion}
            />
          )}
        </S.QuestionAndAnswerWrapper>
      </S.ContentWrapper>

      <StepFooterButtons
        stuckAtReview={stuckAtReview}
        handleNext={handleNext}
        preview={preview}
        disabled={disabled}
        enableNext={enableNext}
      />
    </S.Wrapper>
  );
};

export default QuestionAndAnswer;
