import styled from '@emotion/styled';

// Outer circle container
export const WheelContainer = styled.div`
  position: relative;
  width: 300px;
  height: 300px;
  border: 2px solid #ccc;
  border-radius: 50%;
`;

// Inner circle for numbers
export const InnerCircle = styled.div`
  position: absolute;
  width: 200px;
  height: 200px;
  border: 2px solid #ccc;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

// Outer circle for numbers
export const OuterCircle = styled.div`
  position: absolute;
  width: 300px;
  height: 300px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

// Center text inside the wheel
export const CenterText = styled.div`
  width: ${({ centerValue }) => (centerValue.length >= 4 ? '120px' : '90px')};
  height: ${({ centerValue }) => (centerValue.length >= 4 ? '120px' : '90px')};
  text-align: center;
  position: absolute;
  z-index: 10;
  font-size: 28px;
  font-weight: bold;
  color: #000;
  border: 2px solid #ccc;
  border-radius: 50%;
  background: #fff;
  padding: 20px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
`;

// Slice borders for outer circle
export const OuterSliceBorder = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  ${(props) => `
    transform: rotate(${props.rotation}deg);
  `}

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    height: 100%;
    border-left: 1px solid #ccc;
    transform: translateX(-50%);
  }
`;

// Number placement inside the circles
export const Number = styled.div`
  position: absolute;
  font-size: 18px;
  font-weight: bold;
  ${(props) => {
    const inPositionRotation =
      props.rotation > 0 ? -props.rotation : Math.abs(props.rotation);
    const distantFromCenter = props.circleType === 'inner' ? 70 : 120;
    return `transform: rotate(${props.rotation}deg) translate(${distantFromCenter}px) rotate(${inPositionRotation}deg);`;
  }}
`;
