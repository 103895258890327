import styled from '@emotion/styled';

export const BottomNumbersWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacings[4]};
  display: flex;
  gap: ${({ theme }) => theme.spacings[2]};
`;

export const OperationWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacings[4]};
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacings[2]};
`;
