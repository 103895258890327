import * as S from './style';
import { badgesGroups } from '../../constants/badges';
import Badge from '../Badge';
import * as T from '../Typography';
import { BasicButton } from 'components/Button';
import { useState } from 'react';

const BadgesList = ({ badges }) => {
  const groupedBadges = {};
  const [limit, setLimit] = useState(8);

  const showMore = () => {
    setLimit(limit + 8);
  };

  if (!badges?.length) return <T.P> No badges yet </T.P>;

  badges?.length &&
    badges.forEach((badge) => {
      const group = badgesGroups[badge.type];
      if (group) {
        if (!groupedBadges[group])
          groupedBadges[group] = {
            title: group,
            levels: [],
          };
        groupedBadges[group].levels.push(badge.type);
      } else {
        groupedBadges[badge.type] = {
          title: badge.type,
          numberOfOccurrences: badge.numberOfOccurrences,
        };
      }
    });

  const listLength = Object.values(groupedBadges).length;
  const showMoreButton = listLength > 8 && limit < listLength;
  const restCount = listLength - limit;

  return (
    <>
      <S.BadgesList mt="4">
        {Object.values(groupedBadges)
          .slice(0, limit)
          .map((group) => (
            <S.Wrapper>
              <Badge
                type={group.title}
                levels={group.levels}
                numberOfOccurrences={group.numberOfOccurrences}
              />
            </S.Wrapper>
          ))}
      </S.BadgesList>
      {showMoreButton ? (
        <BasicButton
          handleClick={showMore}
          variant="tertiary"
          maxWidth="330px"
          mt="4"
          customColor="#405ED6"
        >
          {restCount} more badges
        </BasicButton>
      ) : null}
    </>
  );
};

export default BadgesList;
