import React from 'react';

const masterInTheMaking = ({
  width,
  height,
  color,
  numberOfOccurrences = 1,
  ...props
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || '64'}
    height={height || '64'}
    viewBox="0 0 64 64"
    fill="none"
    {...props}
  >
    <circle cx="32" cy="32" r="32" fill="#050D30" />
    <circle
      cx="32"
      cy="32"
      r="30"
      stroke="white"
      strokeOpacity="0.1"
      strokeWidth="4"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M39.68 37.76C40.0722 37.76 40.437 37.8776 40.7409 38.0795L42.862 29.5952C43.0003 29.0421 42.4007 28.5978 41.9118 28.8912L36.1044 32.3756L32.5735 25.3138C32.3379 24.8426 31.6657 24.8419 31.4292 25.3127L27.8805 32.3755L22.0911 28.8916C21.6022 28.5974 21.0018 29.0417 21.1402 29.5952L23.261 38.0782C23.5645 37.8771 23.9286 37.76 24.32 37.76H39.68ZM23.68 39.68C23.68 39.3265 23.9665 39.04 24.32 39.04H39.68C40.0335 39.04 40.32 39.3265 40.32 39.68V43.52C40.32 43.8734 40.0335 44.16 39.68 44.16H24.32C23.9665 44.16 23.68 43.8734 23.68 43.52V39.68Z"
      fill="#FDFCF9"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.0001 26.24C30.2327 26.24 28.8001 24.8073 28.8001 23.04C28.8001 21.2727 30.2327 19.84 32.0001 19.84C33.7674 19.84 35.2001 21.2727 35.2001 23.04C35.2001 24.8073 33.7674 26.24 32.0001 26.24ZM21.1201 30.08C19.7062 30.08 18.5601 28.9338 18.5601 27.52C18.5601 26.1061 19.7062 24.96 21.1201 24.96C22.5339 24.96 23.6801 26.1061 23.6801 27.52C23.6801 28.9338 22.5339 30.08 21.1201 30.08ZM40.3201 27.52C40.3201 28.9338 41.4662 30.08 42.8801 30.08C44.2939 30.08 45.4401 28.9338 45.4401 27.52C45.4401 26.1061 44.2939 24.96 42.8801 24.96C41.4662 24.96 40.3201 26.1061 40.3201 27.52Z"
      fill="#FDFCF9"
    />
    <rect x="24.5" y="12.5" width="15" height="15" rx="7.5" fill="white" />
    <rect x="24.5" y="12.5" width="15" height="15" rx="7.5" stroke="#050D30" />
    <text
      x="32"
      y="20.5"
      textAnchor="middle"
      fontFamily="Arial, sans-serif"
      fontWeight="bold"
      fontSize="10"
      fill="#050D30"
      dominantBaseline="central"
    >
      {numberOfOccurrences}
    </text>
  </svg>
);

export default masterInTheMaking;
