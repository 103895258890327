import styled from '@emotion/styled';
import { courseCodeTypes } from '../../../../constants';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  padding: 0 ${({ theme }) => '80px'};
  margin: 0 auto;
  flex-direction: column;
  flex: 1;
`;

export const Grid = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: ${({ theme }) => theme.spacings[5]};
  padding-bottom: ${({ theme }) => theme.spacings[5]};
  background: ${({ theme }) => theme.colors.neutralLight};
  left: 0;
  position: relative;
  grid-gap: ${({ theme }) => theme.spacings[4]};

  :before {
    position: absolute;
    background: ${({ theme }) => theme.colors.neutralLight};
    height: 100%;
    width: 200vw;
    content: '';
    left: -50vw;
    right: 0;
    top: 0;
    z-index: -1;
  }
`;
export const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  opacity: ${({ disabled }) => (disabled ? '0.3' : '1')};
  align-items: center;
`;

export const OptionsGridWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: ${({ ai = 'center' }) => ai};
`;
export const OptionsGrid = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  max-width: 323px;
  margin: ${({ theme }) => theme.spacings[5]} auto;
  justify-content: ${({ jc }) => jc || 'start'};
`;

export const TitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ItemsWrapper = styled.div`
  display: flex;
  flex-direction: ${({ courseCode }) =>
    courseCode === courseCodeTypes.CMID ? 'column' : 'row'};
  flex-wrap: wrap;
  width: 100%;
`;

export const Item = styled.div`
  display: flex;
  width: ${({ courseCode }) =>
    courseCode === courseCodeTypes.CMID ? '100%' : '50%'};
  justify-content: ${({ courseCode }) =>
    courseCode === courseCodeTypes.CMID ? 'space-around' : 'center'};
  align-items: center;
  border: 1px solid #c4c4c457;
  padding: 15px;
`;
