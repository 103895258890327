import { useRef } from 'react';
import { BasicInput } from '../../../../../components/Inputs';
import { MediaKeyInput } from '../../../../components/MediaKeyInput';
import { mediaInputLabels, mediaTypes } from '../../../../../constants';
import { useStepForm } from 'CMS/Providers/StepFormProvider';
import * as T from '../../../../../components/Typography';
import * as S from './style';
import { Checkbox } from 'antd';

export { validationSchema } from './validation';

const initialData = {
  textAudio: '',
  textAudioKey: '',
  numberOfInputs: 1,
  inputs: [{ id: '', value: null, showValue: false }],
};

const FillGaps = () => {
  const initialRef = useRef(initialData);
  const { state, updateDataFields, updateFormFields, updateFormMetaData } =
    useStepForm(initialRef.current);
  const { form, data = initialData, validationErrs } = state;

  const handleNumberOfInputsChange = ({ numberOfInputs = 1 }) => {
    if (numberOfInputs < 1 || numberOfInputs > 10) {
      updateFormMetaData({
        validationErrs: {
          ...validationErrs,
          numberOfInputs:
            numberOfInputs < 1
              ? 'Number of inputs must be more than 0'
              : numberOfInputs > 10
              ? 'Number of inputs maximum is 10'
              : '',
        },
      });
      updateDataFields({ numberOfInputs });

      return;
    }

    const newInputs = Array.from({ length: numberOfInputs }).map(
      (_, inputIndex) => ({
        id: inputIndex,
        value: data.inputs?.[inputIndex]?.value ?? null,
        showValue: data.inputs?.[inputIndex]?.showValue ?? false,
      })
    );

    updateDataFields({
      numberOfInputs,
      inputs: newInputs,
    });
    updateFormMetaData({
      validationErrs: {
        ...validationErrs,
        numberOfInputs: '',
      },
    });
  };

  const onInputChange = ({ value, id }) => {
    const newInputs = data.inputs?.map((e) => {
      if (e.id !== id) return e;
      return {
        ...e,
        value,
      };
    });
    updateDataFields({ inputs: newInputs });
  };

  const onShowInputChange = (value, id) => {
    updateDataFields({
      inputs: data.inputs.map((e) => {
        if (e.id !== id) return e;
        return { ...e, showValue: value };
      }),
    });
  };

  return (
    <div>
      <BasicInput
        label="Explainer Text"
        value={data.textAudio}
        handleChange={(textAudio) => updateDataFields({ textAudio })}
        m={{ mt: 5 }}
        error={validationErrs?.textAudio}
      />
      <MediaKeyInput
        type={mediaTypes.TEXT_AUDIO}
        label="Explainer Text file Key"
        value={data.textAudioKey}
        handleChange={(textAudioKey) => updateDataFields({ textAudioKey })}
        m={{ mt: 5 }}
        error={validationErrs?.textAudioKey}
      />

      <MediaKeyInput
        label={mediaInputLabels.IMAGE_LABEL}
        type={mediaTypes.IMAGE}
        value={form.imageKey}
        handleChange={(imageKey) => {
          updateFormFields({ imageKey });
        }}
        m={{ mt: 5 }}
        error={validationErrs?.imageKey}
      />

      <BasicInput
        m={{ mt: 5 }}
        label="Number Of Inputs"
        value={data.numberOfInputs}
        type="number"
        handleChange={(numberOfInputs) =>
          handleNumberOfInputsChange({ numberOfInputs })
        }
        error={validationErrs?.numberOfInputs}
      />

      <T.P mt={6} mb={2} size="large" weight="bold">
        Inputs
      </T.P>
      <S.InputsWrapper>
        {data.inputs?.map((input, index) => (
          <div key={`${input.id}-${index}`}>
            <BasicInput
              m={{ mt: 5 }}
              value={input.value}
              type="number"
              handleChange={(value) =>
                onInputChange({ value: Number(value), id: input.id })
              }
              error={validationErrs?.inputs?.[index]?.value}
              placeholder="0"
            />
            <Checkbox
              checked={input.showValue}
              onChange={(value) =>
                onShowInputChange(value.target.checked, input.id)
              }
            >
              Show
            </Checkbox>
          </div>
        ))}
      </S.InputsWrapper>
    </div>
  );
};

export default FillGaps;
