import { useEffect, useReducer, useRef } from 'react';
import * as T from '../../../components/Typography';
import {
  BasicInput,
  Checkbox,
  PasswordInput,
} from '../../../components/Inputs';
import { BasicButton } from '../../../components/Button';
import validate from '../../../validation/schemas/coach-signup';
import { useSiteSettings } from 'context/site-settings';
import { useAddCoachForPrison } from 'api-calls/coaches.queries';
import handleError from 'api-calls/format-error';
import * as S from './style';
import InPrisonSuccessModal from './InPrisonSuccessModal';

const initialState = {
  form: {
    firstName: '',
    lastName: '',
    password: '',
    giveConsent: false,
    alreadyTrained: true, // for future use, now it is always true

    // store after successful submission
    coachUniqueId: '',
  },
  isSuccessModalOpen: false,
  httpError: '',
  validationErrs: {},
};

function reducer(state, newState) {
  let value = newState;
  if (typeof newState === 'function') {
    value = newState(state);
  }
  return { ...state, ...value };
}

const AddCoachForPrisonCard = () => {
  const { courseName } = useSiteSettings();
  const [state, setState] = useReducer(reducer, initialState);
  const submitAttempt = useRef(false);
  const {
    mutate: addCoachForPrison,
    isLoading,
    isError,
  } = useAddCoachForPrison();

  const {
    form: {
      firstName,
      lastName,
      password,
      giveConsent,
      alreadyTrained,
      coachUniqueId,
    },
    validationErrs,
    httpError,
    isSuccessModalOpen,
  } = state;

  useEffect(() => {
    if (submitAttempt.current) {
      validateForm();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstName, lastName, password, giveConsent]);

  const setFormData = (data) => {
    setState((prevState) => ({
      httpError: '',
      form: { ...prevState.form, ...data },
    }));
  };

  const reset = (data) => {
    setState({ ...initialState, ...data });
    submitAttempt.current = false;
  };

  const validateForm = () => {
    try {
      validate({ ...state.form, isWorkingInPrisonEnvironment: true });
      setState({ validationErrs: { hasError: false } });
      return true;
    } catch (error) {
      if (error.name === 'ValidationError') {
        setState({
          validationErrs: { ...error.inner, hasError: true },
        });
      }
      return false;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    submitAttempt.current = true;

    const isValid = validateForm();
    if (isValid) {
      addCoachForPrison(state.form, {
        onSuccess: (data) => {
          setFormData({ coachUniqueId: data?.coachUniqueId });
          setState({ isSuccessModalOpen: true });
        },
        onError: (error) => {
          const formattedError = handleError(error);
          setState({ httpError: formattedError });
        },
      });
    }
  };

  return (
    <>
      <S.CardWrapper>
        <T.H2 color="neutralMain" mb="4">
          Add New Coach
        </T.H2>
        <T.P mb="5">
          Please only add a coach after they have completed their training. Any
          coaches added will automatically be ready to be assigned learners.
        </T.P>
        <S.FormWrapper>
          <BasicInput
            label="First name"
            placeholder="Enter first name..."
            value={firstName}
            name="firstName"
            handleChange={(firstName) => setFormData({ firstName })}
            error={validationErrs.firstName}
          />
          <BasicInput
            label="Last name"
            placeholder="Enter last name..."
            value={lastName}
            name="lastName"
            handleChange={(lastName) => setFormData({ lastName })}
            error={validationErrs.lastName}
          />
          <PasswordInput
            label="Password"
            placeholder="Password..."
            password={password}
            handleChange={(password) => setFormData({ password })}
            error={validationErrs.password}
          />
          <T.P ml="2" mt="-8px">
            You can change the coach's password at any time.
          </T.P>
          {/* keep it for future use */}
          {/* <Checkbox
            mt="4"
            plain
            font="regular"
            checked={alreadyTrained}
            handleChange={(alreadyTrained) => setFormData({ alreadyTrained })}
            label={
              <T.P color="neutralMain">This coach has already been trained</T.P>
            }
            error={validationErrs.alreadyTrained}
          /> */}
          <Checkbox
            mt="4"
            plain
            font="regular"
            checked={giveConsent}
            handleChange={(giveConsent) => setFormData({ giveConsent })}
            label={
              <T.P color="neutralMain">
                By registering a coach, I confirm that I have their consent to
                provide their information to {courseName}. The information will
                be processed according to the privacy policy available within
                this application
              </T.P>
            }
            error={validationErrs.giveConsent}
          />
        </S.FormWrapper>
        {isError && (
          <T.P color="error" mt="2" mb="-20px">
            {httpError?.message || 'Something went wrong'}
          </T.P>
        )}
        <BasicButton mt="20px" onClick={handleSubmit} loading={isLoading}>
          <T.P color="white" weight="semi">
            Create Coach Profile
          </T.P>
        </BasicButton>
      </S.CardWrapper>
      <InPrisonSuccessModal
        visible={isSuccessModalOpen}
        reset={reset}
        coachUniqueId={coachUniqueId}
        password={password}
        status={alreadyTrained ? 'Active' : 'Awaiting Training'}
      />
    </>
  );
};

export default AddCoachForPrisonCard;
