const GENERAL = {
  HOME: '/',
  UNAUTHORIZED: '/unauthorized',
  FORGET_PASSWORD: '/forget-password',
  RESET_PASSWORD: '/reset-password/:token',
  NOT_FOUND: '/not-found',
  ERROR_PAGE: '/error',
  LOGIN: '/login',
  INTEREST: `/:uniqueSlug/sign-up-as-coach`, // organisation unique slug
  INTEREST_THANK_YOU: `/sign-up-as-coach/thank-you`,
  REFER_A_LEARNER: '/:uniqueSlug/refer-a-learner',
  REFER_A_LEARNER_DEFAULT: '/refer-a-learner',
  REFER_A_LEARNER_THANK_YOU: '/refer-a-learner/thank-you',
  HEADER_COMPONENT: '/header',
  TEST_SPEECH: '/test-speech',
  REDIRECTION_TO_TURNING_PAGES: '/redirection',
  TWO_FACTOR_AUTH: '/two-factor-auth',
  PRIVACY_AND_POLICY: '/privacy-and-policy',
};

const COMMON = {
  COACH_PROFILE: `/coaches/:statusType/:coachId`,
  COACH_NOTES: `/coaches/:statusType/:coachId/notes`,
  EDIT_NOTE: `/coaches/editNote`,
  ADD_LEARNER: `/add-learner`,
  LEARNERS: `/learners`,
  ADD_COACHES: `/add-coaches`,
  COACHES: `/coaches/:statusType`,
  LEARNER_REFERRAL: `/learners/:id/referral`,
  LEARNER: `/learners/:userId`,
  APPROVE_COACH: `/coaches/:coachId/approve/:token`,
  COACH_INVITE_LINK: `/coaches/:coachId/invite/:token`,
  MANAGE_COACH_APPLICATION: `/coaches/:coachId/manage/:action`,
  ASSIGN_COACH: `/learner/:id/assign`,
  COACH_DETAILS: `/coaches/:statusType/:coachId/details`,
  LEARNER_PAGE_PREVIEW: `/:uniqueSlug/preview`,
  EXERCISES_PLAYGROUND: `/playground`,
  ACCOUNT_DETAILS: `/account-details`,
  LEARNER_REACHED_MILESTONES: `/learner/:learnerId/learner-reached-milestones`,
  EDIT_ORGANISATION_RECRUITMENT_FORM: `/recruitment-form`,
  SPLASH_SCREEN: `/splash-screen`,
  SELECT_ORGANISATION: `/select-organisation`,
  LEARNER_NOTES: `/learners/:learnerId/notes`,
  EDIT_COACH_NOTE: `/coaches/editNote`,
  EDIT_LEARNER_NOTE: `/learners/editNote`,
  FACILITATORS: `/facilitators`,
  LEARNER_SESSIONS: `/learner/:learnerId/sessions`,
  SWITCH_COURSE: `/switch-course`,
  AUTO_LOGIN: `/auto-login`,
};

const admin = '/admin';
const ADMIN = {
  BASE: `${admin}`,
  ORGANISATIONS: `${admin}/organisations`,
  ADD_ORGANISATION: `${admin}/organisations/add`,
  EDIT_ORGANISATION: `${admin}/organisations/:id/edit`,
  ORGANISATION_ADDED: `${admin}/organisations/add/success`,
  DASHBOARD: `${admin}/dashboard`,
  UPDATE_FACILITATORS_ORGANISATIONS: `${admin}/facilitators/:id`,
};

const learner = '/learner';
const LEARNER = {
  MENU: `${learner}/menu`,
  PROFILE: `${learner}/profile`,
  LOGIN: `${learner}/login`,
  LANDING_PAGE: `/:uniqueSlug/home`,
  SIGNUP: `/:uniqueSlug${learner}/signup`,
  SIGNUP_EXISTING_ACCOUNT: `/:uniqueSlug${learner}/signup-existing-account`,
  HAVE_ACCOUNT: `/:uniqueSlug${learner}/have-account`,
  WELCOME_SELF_SIGNUP: `/:uniqueSlug${learner}/welcome`,
  DASHBOARD: `${learner}/dashboard`,
  WELCOME: `${learner}/welcome`,
  SOUND_BOARD: `${learner}/sound-board`,
  ICONS: `${learner}/icons`,
  COLOURED_OVERLAY: `${learner}/coloured-overlay`,
  SEND_THANKS: `${learner}/send-thanks`,
  LEARNER_ID: `${learner}/learner-id/:userId`,
  ONBOARDING_1: `${learner}/onboarding-1`,
  ONBOARDING_4: `${learner}/onboarding-4`,
  ONBOARDING_2: `${learner}/onboarding-2`,
  ONBOARDING_5: `${learner}/onboarding-5`,
  SETUP_PROCESS: `${learner}/setup-process/:id`,
  PROGRESS_FIRST_PAGE: `${learner}/progress-page-1`,
  PROGRESS_REPORT_QUESTIONS: `${learner}/progress-report-questions/:id`,
  PROGRESS_REPORT_COMPLETED: `${learner}/progress-report-completed`,
  SELECT_STARTING_POINT: `${learner}/:learnerId/select-starting-point`,
  SELECT_MILESTONE: `${learner}/:learnerId/select-milestone`,
  LEARNING_BY_YOURSELF_OR_WITH_COACH: `${learner}/learn-by-yourself-or-with-coach`,
  COACH_CODE: `${learner}/coach-code`,
  PROGRESS_CHECK_THANK_YOU: `${learner}/progress-check-thank-you`,
  PROGRESS_PROFILE_QUESTIONS: `${learner}/progress-profile-questions/:id/:milestoneId`,
  PROGRESS_CHECK_QUESTIONS: `${learner}/progress-check-questions/:stepId/:milestoneId`,
  SUCCESS_MILESTONE: `${learner}/success/:id`,
  GOALS: `${learner}/goals`,
  CHAT_WITH_COACH: `${learner}/chat/:receiverId`,
  MY_COACHING_SESSIONS: `${learner}/my-coaching-sessions`,
};

const exerciseBase = '/exercise/:exerciseId';

const EXERCISES = {
  BASE: `${exerciseBase}`,
  START: `${exerciseBase}/start`,
  COACH_NOTES: `${exerciseBase}/coach-notes`,
  LEARNER_NOTES: `${exerciseBase}/learner-notes`,
  SELECT_WITH_COACH_OR_NOT: `${exerciseBase}/select-with-coach-or-not`,
  SINGLE_STEP: `${exerciseBase}/steps/:stepId`,
  SINGLE_STEP_PREVIEW: `${exerciseBase}/steps/:stepId/preview`,
  EXERCISE_EXPLAINER: `${exerciseBase}/explain`,
  EXERCISE_FEEDBACK: `${exerciseBase}/feedback`,
  EXERCISE_COMPLETED: `${exerciseBase}/:milestoneId/completed`,
  REVIEW_COMPLETED_SL: `${exerciseBase}/review/completed`,
  WON_NEW_BADGE: `${exerciseBase}/new-badge/:badgeType`,
};

const facilitator = '/facilitator';

const FACILITATOR = {
  BASE: `${facilitator}`,
  LOGIN: `${facilitator}/login`,
  SIGNUP: `${facilitator}/signup/:inviteToken`,
  DASHBOARD: `${facilitator}/dashboard`,
  MY_LEARNERS: `${facilitator}/my-learners`,
  MY_COACHES: `${facilitator}/my-coaches`,
  ABOUT: `${facilitator}/about-shannon-trust`,
  TESTIMONIALS: `${facilitator}/testimonials`,
  COACH: `${facilitator}/coach/:inviteToken`,
  LEARNER_PROGRESS_REPORTS: `${facilitator}/learner/:id/progress-reports`,
  ADD_LEARNER_PROGRESS_REPORTS: `${facilitator}/learner/:id/progress-reports/add`,
  TRAINING: `${facilitator}/training`,
  TRAINING_COMPLETE: `${facilitator}/training/complete`,
  TRAINING_STEP: `${facilitator}/training/step/:stepId`,
  TRAINING_REVIEW: `${facilitator}/training/review`,
  TRAINING_CUSTOMIZATION: `${facilitator}/training/customization`,
  TRAINING_CUSTOMIZATION_ADD_STEP: `${facilitator}/training/customization/step/new`,
  TRAINING_CUSTOMIZATION_EDIT_STEP: `${facilitator}/training/customization/step/:stepId/edit`,
  TRAINING_ADD_RESOURCES: `${facilitator}/training/customization/resources/new`,
  TRAINING_EDIT_RESOURCES: `${facilitator}/training/customization/resources/:resourceId/edit`,
};

const coach = '/coach';

const COACH = {
  ABOUT: `${coach}/about-shannon-trust`,
  DASHBOARD: `${coach}/dashboard`,
  PROFILE: `${coach}/profile`,
  STUCK: `${coach}/stuck`,
  LOGIN: `${coach}/login`,
  SIGNUP: `${coach}/signup/:inviteToken`,
  NEW_LEARNER: `${coach}/new-learner/:userId`,
  INTRODUCTORY_MEETING: `${coach}/new-learner/:userId/introductory-meeting`,
  LEARNERS: `${coach}/learners`,
  LEARNERS_STUCK_AT: `${coach}/learners-stuck-at`,
  REVIEW_STUCK_AT: `${coach}${exerciseBase}/steps/:stepId/review-stuck-at`,
  REVIEW_LEARNER_SELF_LEARNING: `${coach}/learners/:id/review-self-learning`,
  COLOURED_OVERLAY: `${coach}/coloured-overlay`,
  ONBOARDING_1: `${coach}/onboarding-1`,
  ONBOARDING_2: `${coach}/onboarding-2`,
  ONBOARDING_3: `${coach}/onboarding-3`,
  ONBOARDING_4: `${coach}/onboarding-4`,
  ONBOARDING_5: `${coach}/onboarding-5`,
  ONBOARDING_6: `${coach}/onboarding-6`,
  LEARNER_DASHBOARD: `${coach}/learners/:id/dashboard`,
  COACH_PROFILE: `${coach}/profile`,
  TRAINING_CHECKLIST: `${coach}/training-checklist`,
  DASHBOARD_ADVICE: `${coach}/dashboard/advice`,
  CREATE_COACHING_SESSION: `${coach}/dashboard/session/new`,
  EDIT_COACHING_SESSION: `${coach}/dashboard/session/edit/:sessionIdP/:learnerIdP`,
  ALL_SESSIONS: `${coach}/dashboard/all-sessions`,
  DASHBOARD_TRAINING_CHECKLIST: `${coach}/dashboard/training-checklist`,
  DASHBOARD_SAFEGUARDING: `${coach}/dashboard/safeguarding`,
  DASHBOARD_QUALITIES: `${coach}/dashboard/qualities`,
  TRAINING: `${coach}/training`,
  TRAINING_COMPLETE: `${coach}/training/complete`,
  TRAINING_STEP: `${coach}/training/step/:stepId`,
  TRAINING_REVIEW: `${coach}/training/review`,
  PREPARE_MILESTONE: `${coach}/milestone/:milestoneId/prepare`,
  CHAT_WITH_LEARNER: `${coach}/chat/:receiverId`,
  CHAT_SAFEGUARD: `${coach}/chat-safeguard/:receiverId`,
};

const organisationAdmin = '/organisation-admin';

const ORGANISATION_ADMIN = {
  BASE: `${organisationAdmin}`,
  DASHBOARD: `${organisationAdmin}/dashboard`,
  ADD_FACILITATORS: `${organisationAdmin}/add-facilitators`,
  CUSTOMISE: `${organisationAdmin}/customise`,
  SIGNUP: `${organisationAdmin}/signup/:inviteToken`,
  WELCOME: `${organisationAdmin}/welcome`,
};

const cms = '/cms';
const CMS = {
  BASE: `${cms}`,
  CREATE_MILESTONE: `${cms}/milestones/new`,
  VIEW_MILESTONES: `${cms}/milestones/all`,
  VIEW_EXERCISES: `${cms}/exercises/all`,
  VIEW_EXERCISE: `${cms}/exercises/:id`,
  CREATE_EXERCISES: `${cms}/exercises/new`,
  VIEW_EXERCISE_STEPS: `${cms}/exercises/:id/steps/all`,
  SINGLE_STEP: `${cms}/exercises/:id/steps/:stepId`,
  VIEW_STEP: `${cms}/exercises/:id/steps/:stepId`,
  MEDIA: `${cms}/media`,
  MEDIA_UPDATE: `${cms}/media/:id`,
};

const EXTERNAL = {
  ONBOARDING_LEARNERS_VIDEO:
    'https://www.loom.com/embed/97306ad3e4fe45afa826472f9cb9fb45',
  TURNING_PAGES_APP_VIDEO: 'https://www.youtube.com/embed/TDfNyAolcfY',
  HOW_TO_USE_VIDEO:
    'https://www.loom.com/embed/56d6221148f749de9940364c42c1d66c',
  TERMS_OF_USE:
    'https://drive.google.com/file/d/1MCIlPUjjQkMw1m2e9Wpo_nxVbCZUdNrp/view',
  PRIVACY_POLICY:
    'https://docs.google.com/document/d/1jN_VCgQitvs4BnnSxeNl_VlaiFnI-H8z/edit?usp=sharing&ouid=114487763822736370607&rtpof=true&sd=true',
  // CONTACT_US: 'chloe.bradbury@shannontrust.org.uk',
  CONTACT_US: 'scott.haines@shannontrust.org.uk',
  REFER_A_LEARNER:
    'https://www.shannontrust.org.uk/get-started-with-turning-pages',
  MOODLE: 'https://shannontrust.clcmoodle.org/local/roadmaplogin/login.php',
  MONTHLY_WEBINAR:
    'https://www.eventbrite.com/e/turning-pages-webinar-tickets-626272025487',
  JOIN_OUR_COMMUNITY: 'https://www.eventbrite.com/o/shannon-trust-65072600813',
};

export {
  GENERAL,
  EXTERNAL,
  LEARNER,
  COACH,
  FACILITATOR,
  EXERCISES,
  CMS,
  ADMIN,
  ORGANISATION_ADMIN,
  COMMON,
};
