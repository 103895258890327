import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col } from '../../../components/Grid';
import Modal from '../../../components/Modal';
import BasicButton from '../../../components/Button/Basic';
import AssignColumn from '../../../components/CommonTables/AssignColumn';
import * as T from '../../../components/Typography';
import * as S from './style';
import {
  useGetLearnerUniqueId,
  useUpdateLearnerCoach,
} from '../../../api-calls/learners.queries';

import { navRoutes } from '../../../constants';
import { BackButton } from 'components/BackButton';
import BasicCoachTable from '../Coaches/BasicTable';
import { useSiteSettings } from 'context/site-settings';

const AssignCoach = () => {
  const [coach, setCoach] = useState();
  const [isModalVisible1, setIsModalVisible1] = useState(false);
  const [isModalVisible2, setIsModalVisible2] = useState(false);
  const { id: learnerUserId } = useParams();
  const { data: learner } = useGetLearnerUniqueId({ learnerUserId });
  const {
    mutateAsync: updateLearnerCoachMutate,
    error,
    isLoading: updating,
  } = useUpdateLearnerCoach();
  const { courseCode, isWorkingInPrisonEnvironment } = useSiteSettings();

  const updateLearnerCoach = async () => {
    await updateLearnerCoachMutate({
      learnerUserId,
      coachId: coach.id,
    });

    setIsModalVisible1(false);
    setIsModalVisible2(true);
  };

  const assignedCoach = learner?.coaches.find(
    (c) => c.courseCode === courseCode
  );

  return (
    <S.Wrapper>
      <BackButton />
      <Row>
        <BasicCoachTable
          tableTitle=" Assign a coach"
          assignToLearnerUserId={learnerUserId}
          assignCoachActionColumn={{
            title: (
              <T.P weight="bold" color="neutralMain">
                Assign a coach
              </T.P>
            ),
            render: (id, coachData) => {
              return (
                <AssignColumn
                  handleClick={() => {
                    setIsModalVisible1(true);
                    setCoach(coachData);
                  }}
                  alreadyAssigned={Number(id) === Number(assignedCoach?.id)}
                  previouslyAssigned={coachData?.previouslyAssigned}
                />
              );
            },
            dataIndex: 'id',
            key: 'id',
          }}
        />
      </Row>
      <Modal
        visible={isModalVisible1}
        setIsModalVisible={(e) => {
          setIsModalVisible1(e);
        }}
      >
        <Row>
          <Col w={[4, 12, 12]} mb={4}>
            {error?.message ? (
              <>
                <T.H3 color="neutralSurface">Error:</T.H3>
                <T.H3 color="neutralSurface"> {error?.message}</T.H3>
              </>
            ) : (
              <T.H3 color="neutralSurface">
                Are you sure you want to assign {coach?.fullName} as{' '}
                {learner?.fullName}'s coach
              </T.H3>
            )}
          </Col>
        </Row>

        <BasicButton
          to={'#'}
          variant="secondary"
          loading={updating}
          handleClick={() => {
            updateLearnerCoach();
          }}
        >
          <T.P color="white" weight="bold">
            Yes, I'm sure
          </T.P>
        </BasicButton>
        <BasicButton
          mt={3}
          to={'#'}
          variant="tertiary"
          disabled={updating}
          handleClick={() => {
            setIsModalVisible1(false);
            setCoach(null);
          }}
        >
          No, go back
        </BasicButton>
      </Modal>

      <Modal visible={isModalVisible2} setIsModalVisible={setIsModalVisible2}>
        <Row>
          <Col w={[4, 12, 12]} mb={4}>
            <T.H3 color="neutralSurface">
              {isWorkingInPrisonEnvironment
                ? 'Please notify the coach that they have now been assigned a learner.'
                : 'Thanks, the coach will be notified'}
            </T.H3>
          </Col>
        </Row>

        <BasicButton
          to={navRoutes.COMMON.LEARNERS}
          variant="secondary"
          handleClick={() => {
            setIsModalVisible2(false);
          }}
        >
          <T.P color="white" weight="bold">
            Return to Learners page
          </T.P>
        </BasicButton>
      </Modal>
    </S.Wrapper>
  );
};

export default AssignCoach;
