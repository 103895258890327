import { useEffect, useState } from 'react';

import * as T from '../../../../components/Typography';
import { PlayButton } from '../../../../components/Button';
import { useLocation } from 'react-router-dom';
import StepFooterButtons from '../StepFooterButtons';

import * as S from './style';
import * as SharedStyles from '../shared/style';
import { BasicInput } from 'components/Inputs';
import { getOperatorSymbol } from 'helpers';
import TryAgainModal from 'components/Modal/TryAgainModal';

const Formulae = ({
  data: {
    textAudio,
    textAudioUrl,
    inputsPerRow,
    formulaType,
    rows = [],
    answers: _answers = [],
  } = {},
  handleNext,
  stuckAtReview,
  disabled,
  preview,
}) => {
  const [enableNext, setEnableNext] = useState(false);
  const [showTryAgain, setShowTryAgain] = useState(false);
  const [answers, setAnswers] = useState(
    _answers?.map((a) => ({ ...a, value: '' }))
  );

  const location = useLocation();
  useEffect(() => {
    setEnableNext(false);
  }, [location]);

  useEffect(() => {
    if (answers?.length) {
      const allInputsHaveAnswers = answers?.every((a) => !!answers[a.id].value);
      setEnableNext(allInputsHaveAnswers);
    }
  }, [answers, _answers]);

  const onAnswerChange = ({ value, id }) => {
    const newAnswers = answers?.map((e) => {
      if (e.id !== id) return e;
      return {
        ...e,
        value,
      };
    });
    setAnswers(newAnswers);
  };

  const onNext = () => {
    const allCorrect = answers?.every(
      (a) => answers[a.id].value === _answers[a.id].value
    );

    if (allCorrect || stuckAtReview) {
      handleNext();
    } else {
      setShowTryAgain(true);
    }
  };

  return (
    <S.Wrapper>
      <S.ContentWrapper>
        {!!textAudio && (
          <S.TextAudioWrapper mt="2" mb="4">
            {textAudioUrl ? (
              <>
                <PlayButton
                  audioUrl={textAudioUrl}
                  width="56px"
                  height="56px"
                  iconProps={{ width: '32px', height: '32px' }}
                  fallbackText={textAudio}
                />
                <T.P ml={4} weight="regular" size="med">
                  {textAudio}
                </T.P>
              </>
            ) : (
              <T.P weight="regular" size="regular">
                {textAudio}
              </T.P>
            )}
          </S.TextAudioWrapper>
        )}

        <S.RowsWrapper jc="space-between">
          {rows?.map((rowInputs, rowIndex) => (
            <S.RowWrapper key={rowIndex} inputsPerRow={inputsPerRow}>
              {rowIndex === rows.length - 1 && (
                <S.FormulaeBox id="formulaBox">
                  <T.P size="large">{getOperatorSymbol(formulaType)}</T.P>
                </S.FormulaeBox>
              )}
              <S.RowInputsWrapper inputsPerRow={inputsPerRow}>
                {rowInputs?.map((input) => {
                  const id = input.id;
                  const value = input.value;

                  return (
                    <S.FormulaeBox key={id} id="formulaBox">
                      <T.P size="large">{value}</T.P>
                    </S.FormulaeBox>
                  );
                })}
              </S.RowInputsWrapper>
            </S.RowWrapper>
          ))}
        </S.RowsWrapper>

        <SharedStyles.Divider />

        <S.RowWrapper inputsPerRow={inputsPerRow} mr={5}>
          <S.RowInputsWrapper inputsPerRow={inputsPerRow}>
            {answers?.map((answer, answerIndex) => {
              const id = answer?.id;

              return (
                <BasicInput
                  key={id}
                  placeholder=""
                  value={answer?.value}
                  handleChange={(value) => {
                    onAnswerChange({
                      value,
                      id,
                    });
                  }}
                  hideErrorMsg
                  borderColor="#3D6FB6"
                  borderWidth="2px"
                />
              );
            })}
          </S.RowInputsWrapper>
        </S.RowWrapper>
      </S.ContentWrapper>

      <StepFooterButtons
        stuckAtReview={stuckAtReview}
        handleNext={onNext}
        preview={preview}
        disabled={disabled}
        enableNext={enableNext}
      />

      <TryAgainModal
        isModalVisible={showTryAgain}
        setIsModalVisible={setShowTryAgain}
      />
    </S.Wrapper>
  );
};

export default Formulae;
