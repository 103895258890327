import { InvitesTable } from 'components/CommonTables';
import { Col, Row } from '../../../components/Grid';
import { BasicButton } from '../../../components/Button';
import * as T from '../../../components/Typography';
import Modal from './Modal';
import * as S from './style';
const InPrisonSuccessModal = ({
  visible,
  reset,
  coachUniqueId,
  password,
  status,
}) => {
  return (
    <Modal visible={visible}>
      <S.CoachesInvitesTable>
        <Row ai="center" mb={5}>
          <Col w={[4, 12, 12]}>
            <S.TableTitleWrapper>
              <T.H2 color="neutralMain" size="medLarge" mb={4}>
                Coach Profile Created Successfully!
              </T.H2>
              <T.P mb={6}>
                Share the Coach ID and password with the coach so they can log
                in.
              </T.P>
            </S.TableTitleWrapper>
          </Col>
          <Col w={[4, 12, 12]}>
            <S.TableWrapper>
              <InvitesTable
                hideInviteLink
                hideErrorColumn
                showCoachUniqueId
                showCoachPassword
                dataSource={[
                  {
                    coachUniqueId,
                    password,
                    status,
                  },
                ]}
              />
            </S.TableWrapper>
          </Col>
        </Row>
      </S.CoachesInvitesTable>
      <S.ButtonWrapper>
        <BasicButton
          handleClick={() => {
            reset();
          }}
          variant="secondary"
          maxWidth="280px"
        >
          <T.P color="neutralSurface" weight="bold">
            Back
          </T.P>
        </BasicButton>
      </S.ButtonWrapper>
    </Modal>
  );
};

export default InPrisonSuccessModal;
