const EyeSlash = ({ width, height, color, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || '32'}
    height={height || '32'}
    viewBox="0 0 32 32"
    fill="none"
    {...props}
  >
    <path
      d="M30.9124 15.6C30.8749 15.5 29.8124 13.15 27.4624 10.7875C24.3249 7.66251 20.3624 6.00001 15.9999 6.00001C15.0785 5.99834 14.1587 6.0736 13.2499 6.22501C13.0743 6.25792 12.9105 6.3367 12.7752 6.45335C12.6399 6.57001 12.5378 6.7204 12.4794 6.88924C12.421 7.05808 12.4084 7.23937 12.4427 7.4147C12.477 7.59003 12.5571 7.75315 12.6749 7.88751L25.3374 21.8125C25.431 21.9149 25.5449 21.9966 25.6719 22.0526C25.7989 22.1085 25.9361 22.1375 26.0749 22.1375C26.3219 22.1342 26.5603 22.0459 26.7499 21.8875C29.5999 19.325 30.8624 16.525 30.9124 16.4125C30.9677 16.2842 30.9963 16.146 30.9963 16.0063C30.9963 15.8665 30.9677 15.7283 30.9124 15.6Z"
      fill={color || 'currentColor'}
    />
    <path
      d="M6.73736 4.32502C6.5567 4.13601 6.30919 4.02528 6.04787 4.01655C5.78655 4.00782 5.5322 4.10179 5.33932 4.27832C5.14644 4.45485 5.03038 4.6999 5.01599 4.96097C5.00161 5.22205 5.09005 5.47837 5.26236 5.67502L7.66236 8.31252C3.12486 11.1 1.17486 15.4 1.08736 15.6C1.03198 15.7283 1.00342 15.8665 1.00342 16.0063C1.00342 16.146 1.03198 16.2842 1.08736 16.4125C1.12486 16.5 2.18736 18.85 4.53736 21.2125C7.67486 24.3375 11.6374 26 15.9999 26C18.2417 26.0153 20.4613 25.5552 22.5124 24.65L25.2624 27.675C25.356 27.7774 25.47 27.8592 25.5969 27.9151C25.7239 27.9711 25.8611 28 25.9999 28C26.2493 27.9976 26.4893 27.9042 26.6749 27.7375C26.7732 27.6498 26.8529 27.5433 26.9094 27.4242C26.9659 27.3052 26.998 27.1761 27.0038 27.0444C27.0097 26.9128 26.9891 26.7814 26.9433 26.6578C26.8976 26.5342 26.8275 26.4211 26.7374 26.325L6.73736 4.32502ZM15.9999 20.5C15.1775 20.4995 14.371 20.2736 13.668 19.8469C12.965 19.4203 12.3924 18.8091 12.0123 18.0799C11.6322 17.3506 11.4592 16.5312 11.5121 15.7105C11.565 14.8899 11.8418 14.0994 12.3124 13.425L18.2124 19.9125C17.5392 20.299 16.7761 20.5016 15.9999 20.5Z"
      fill={color || 'currentColor'}
    />
  </svg>
);
export default EyeSlash;
