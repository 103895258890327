import styled from '@emotion/styled';
import { Row, Col } from '../../../components/Grid';
import { Typography } from '../../../components';

const { P } = Typography;

export const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const ButtonsWrapper = styled(Row)`
  flex: 0;
  align-items: flex-end;
  ${({ theme }) => theme.media.tablet} {
    flex: 1;
  }
`;

export const ButtonContainer = styled(Col)`
  align-items: flex-end;
`;

export const Link = styled(P)`
  text-decoration: underLine;
  padding-top: 8px;
  padding-left: 8px;
  &:hover {
    cursor: pointer;
  }
`;

export const ResetPasswordLink = styled.a`
  text-decoration: underLine !important;
  color: ${({ theme }) => theme.colors.neutralMain} !important;
  &:hover {
    cursor: pointer;
  }
`;

export const ErrorWrapper = styled.div`
  display: flex;
`;
