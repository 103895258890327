import { string, number, array } from 'yup';

export const validationSchema = {
  textAudio: string()
    .required('Explainer Text is required')
    .min(1, 'Explainer Text must not be empty'),
  textAudioKey: string()
    .required('Explainer Text file key is required')
    .min(1, 'Explainer Text file key must not be empty'),
  rowsLabels: string()
    .required('Rows labels are required')
    .test('rows-length', 'Rows labels must be between 1 and 9', (value) => {
      const rowsLength = value.split(',').length;
      return rowsLength >= 1 && rowsLength <= 9;
    }),
  colsLabels: string()
    .required('Cols labels are required')
    .test('cols-length', 'Cols labels must be between 1 and 5', (value) => {
      const colsLength = value.split(',').length;
      return colsLength >= 1 && colsLength <= 5;
    }),
  colsLabelsValues: array()
    .of(
      number().required('Value is required').min(0, 'Value must be at least 0')
    )
    .test(
      'cols-values-length',
      'Cols labels values must match the number of cols labels',
      function (value) {
        const { colsLabels } = this.parent;
        const colsLength = colsLabels.split(',').length;
        return value.length === colsLength;
      }
    ),
};
