import { useRef } from 'react';

import * as T from '../../../../../components/Typography';
import { BasicInput } from '../../../../../components/Inputs';
import { Checkbox } from 'antd';
import * as S from '../style';
import { Icon } from '../../../../../components';
import { useStepForm } from 'CMS/Providers/StepFormProvider';
import { MediaKeyInput } from 'CMS/components/MediaKeyInput';
import { mediaInputLabels, mediaTypes } from '../../../../../constants';
import { AnswersInputsWrapper, AnswerInputWrapper } from './style';

export { validationSchema } from './validation';

const initialData = {
  textAudio: '',
  textAudioKey: '',
  correctOptions: [{ id: 0, value: '', showValue: false, audioKey: '' }],
};

const WordJoin3 = () => {
  // Use useRef to keep the initial state constant across renders
  const initialRef = useRef(initialData);
  const { state, updateDataFields } = useStepForm(initialRef.current);
  const { data, validationErrs } = state;

  const correctOptions = data?.correctOptions?.map((e, i) => ({
    ...e,
    id: i,
  })) || [{ id: 0, value: '', showValue: false }];

  const addCorrectOptions = () => {
    const maxId = Math.max(...correctOptions.map((e) => e.id), 0);
    updateDataFields({
      correctOptions: [
        ...correctOptions,
        { id: maxId + 1, value: '', showValue: false },
      ],
    });
  };
  const removeCorrectOptions = (id) => {
    updateDataFields({
      correctOptions: correctOptions.filter((e) => e.id !== id),
    });
  };

  const onCorrectOptionsChange = (data, id) => {
    updateDataFields({
      correctOptions: correctOptions.map((e) => {
        if (e.id !== id) return e;
        return {
          ...e,
          ...data,
        };
      }),
    });
  };

  const onAudioKeyChange = (value, id) => {
    updateDataFields({
      correctOptions: correctOptions.map((e) => {
        if (e.id !== id) return e;
        return { ...e, audioKey: value };
      }),
    });
  };

  return (
    <div>
      <BasicInput
        value={data.textAudio}
        handleChange={(textAudio) => updateDataFields({ textAudio })}
        label="Explainer Text"
        error={validationErrs.textAudio}
        m={{ mt: 8 }}
      />

      <MediaKeyInput
        label="Explainer Audio"
        type={mediaTypes.TEXT_AUDIO}
        value={data.textAudioKey}
        handleChange={(textAudioKey) => updateDataFields({ textAudioKey })}
        m={{ mt: 5 }}
        error={validationErrs.textAudioKey}
        requireVo
      />

      <T.P mt={8} size="large" weight="bold">
        Correct Options
      </T.P>
      {validationErrs?.correctOptions &&
        typeof validationErrs.correctOptions === 'string' && (
          <T.P mb={2} mt={2} color="error">
            {validationErrs.correctOptions}
          </T.P>
        )}
      <AnswersInputsWrapper>
        {correctOptions.map((correctOption, i) => {
          return (
            <AnswerInputWrapper
              key={correctOption.id}
              w={correctOptions.length > 7 ? '45%' : '100%'}
            >
              <BasicInput
                value={correctOption.value}
                handleChange={(value) => {
                  onCorrectOptionsChange({ value }, correctOption.id);
                }}
                m={{ mb: 2, mt: 3 }}
                error={
                  validationErrs?.correctOptions &&
                  validationErrs?.correctOptions[i] &&
                  validationErrs?.correctOptions[i].value
                }
              />

              <Checkbox
                checked={correctOption?.showValue}
                onClick={(value) =>
                  onCorrectOptionsChange(
                    { showValue: value.target.checked },
                    correctOption.id
                  )
                }
                disabled={!correctOption.value}
              >
                Show
              </Checkbox>

              <MediaKeyInput
                index={i}
                type={mediaTypes.ANSWER_AUDIO}
                label={`Option ${i + 1} ${mediaInputLabels.AUDIO_LABEL}`}
                value={correctOption.audioKey}
                handleChange={(value) => {
                  onAudioKeyChange(value, correctOption.id);
                }}
                m={{ mt: 4 }}
                error={validationErrs?.options?.[i]?.audioKey}
              />

              {correctOptions.length === 1 && i === 0 ? null : (
                <S.Button
                  type="link"
                  danger
                  ghost
                  mt={1}
                  onClick={() => removeCorrectOptions(correctOption.id)}
                  ml="auto"
                >
                  <Icon icon="cross" />
                </S.Button>
              )}
            </AnswerInputWrapper>
          );
        })}
      </AnswersInputsWrapper>

      <S.Button
        type="link"
        ghost
        mt={5}
        mb={5}
        onClick={() => addCorrectOptions()}
        ml="auto"
        mr="auto"
      >
        <Icon icon="plus" /> Add Answer
      </S.Button>
    </div>
  );
};

export default WordJoin3;
