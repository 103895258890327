import { useReducer, useEffect, useRef, useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Redirect from '../../components/Route/Redirect';
import {
  Grid,
  Typography as T,
  Inputs as I,
  Button,
  ReCaptcha,
} from '../../components';
import * as S from './style';
import validate from '../../validation/schemas/login';
import { getCSRFToken, useLogin } from '../../api-calls/users.queries';
import { useAuth } from '../../context/auth';
import { useGeneralState } from '../../context/general-state';

import { navRoutes as R, audio } from '../../constants';
import { cleanEmail, getMediaUrl } from '../../helpers';
import Divider from '../../components/Divider/Divider';
import { useMediaQuery } from 'react-responsive';
import theme from 'theme';
import { useSiteSettings } from 'context/site-settings';

const { Row, Col } = Grid;
const { PlayButton } = Button;

const initialState = {
  email: '',
  password: '',
  validationErrs: {},
};

function reducer(state, newState) {
  return { ...state, ...newState };
}

const Login = () => {
  const submitAttempt = useRef(false);
  const [state, setState] = useReducer(reducer, initialState);
  const { email, password, validationErrs } = state;
  const navigate = useNavigate();
  const { user, setUser } = useAuth();
  const refreshedCSRF = useRef(false);
  const { state: generalState } = useGeneralState();
  const isMobile = useMediaQuery({
    query: `(max-width: ${theme.breakpoints.mobile})`,
  });
  const { mutateAsync: login, error: httpError, isLoading } = useLogin(null);
  const { loaded: siteSettingsLoaded, isWorkingInPrisonEnvironment } =
    useSiteSettings();

  useEffect(() => {
    if (submitAttempt.current) {
      validateForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, password]);

  useLayoutEffect(() => {
    if (siteSettingsLoaded && !isWorkingInPrisonEnvironment) {
      const script = document.createElement('script');

      script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`;
      const body = document.querySelector('body');
      body.appendChild(script);

      return () => {
        body.removeChild(script);
      };
    }
  }, [isWorkingInPrisonEnvironment, siteSettingsLoaded]);

  const validateForm = () => {
    try {
      validate({
        email: cleanEmail(email),
        password,
        isWorkingInPrisonEnvironment,
      });
      setState({ validationErrs: {} });
      return true;
    } catch (error) {
      if (error.name === 'ValidationError') {
        setState({ validationErrs: error.inner });
      }
      return false;
    }
  };

  const handleLogin = async (values) => {
    await login(values, {
      onSuccess: async (data) => {
        if (data.isCoachPrisonLogin) {
          setUser(data);
          navigate(R.COACH.DASHBOARD);
          return;
        }
        navigate(R.GENERAL.TWO_FACTOR_AUTH);
      },
      onError: async (error) => {
        if (error?.data?.invalidCSRF && !refreshedCSRF.current) {
          refreshedCSRF.current = true;
          await getCSRFToken();
          return handleLogin(values);
        }
      },
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    submitAttempt.current = true;

    const isValid = validateForm();
    if (isValid) {
      const inNotPrisonEnvironment =
        siteSettingsLoaded && !isWorkingInPrisonEnvironment;
      if (inNotPrisonEnvironment && process.env.NODE_ENV === 'production') {
        window.grecaptcha.ready(() => {
          window.grecaptcha
            .execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, {
              action: 'login',
            })
            .then((reToken) => {
              handleLogin({
                email: cleanEmail(email),
                password,
                reToken,
              });
            });
        });
      } else {
        handleLogin({
          email: cleanEmail(email),
          password,
        });
      }
    }
  };

  if (user.id && user.role === '') {
    return <Redirect to={R.COMMON.SELECT_ORGANISATION} />;
  }

  return (
    <S.Form onSubmit={handleSubmit}>
      <Row mb={6}>
        <Col w={[4, 12, 12]} ai="center">
          <PlayButton
            width={'56px'}
            height={'56px'}
            radius={12}
            iconProps={{
              height: '32px',
              width: '32px',
            }}
            color="neutralMain"
            backgroundColor="white"
            audioUrl={getMediaUrl(
              audio.welcome,
              true,
              generalState?.preferredVoice
            )}
            fallbackText={'Welcome'}
          />
          <T.H1 weight="bold" color="neutralMain" ml="3" mt="1">
            Welcome
          </T.H1>
        </Col>
      </Row>
      <Row mb="3">
        <Col w={[4, 12, 12]}>
          <T.P
            size={isMobile ? 'med' : 'medLarge'}
            weight="bold"
            color="neutralMain"
          >
            If you are a facilitator, coach, or admin, please log in here to
            access your account.
          </T.P>
        </Col>
      </Row>
      <Row>
        <Col w={[4, 12, 12]} mt="5">
          <I.BasicInput
            id="email"
            label={
              isWorkingInPrisonEnvironment
                ? 'Email Address or Coach ID'
                : 'Email Address'
            }
            placeholder={
              isWorkingInPrisonEnvironment
                ? 'Enter your email address or Coach ID...'
                : 'Enter your email...'
            }
            margins={{ mt: '2', mb: '1' }}
            type={isWorkingInPrisonEnvironment ? 'text' : 'email'}
            value={email}
            autoFocus
            handleChange={(input) => setState({ email: input })}
            error={validationErrs.email}
          />
        </Col>
      </Row>
      <Row mt="5">
        <Col w={[4, 12, 12]}>
          <I.BasicInput
            id="password"
            label="Password"
            placeholder="Enter your password..."
            margins={{ mt: '2', mb: '1' }}
            type="password"
            value={password}
            handleChange={(input) => setState({ password: input })}
            error={validationErrs.password}
          />
          {isWorkingInPrisonEnvironment ? (
            <T.P mt="4" ml="2" color="neutral90">
              If you’re a coach and have forgotten your Coach ID or password,
              please reach out to your program facilitator for assistance.
              <br />
              <br />
              If you’re an administrator or facilitator, you can
              <S.Link onClick={() => navigate(R.GENERAL.FORGET_PASSWORD)}>
                reset your password here
              </S.Link>
              .
            </T.P>
          ) : (
            <S.Link onClick={() => navigate(R.GENERAL.FORGET_PASSWORD)}>
              Forgot your password?
            </S.Link>
          )}
        </Col>
      </Row>
      <Row mt="6">
        <Col w={[4, 12, 12]}>
          {isLoading ? <T.P mb="2">...loading</T.P> : null}
          {httpError?.message && (
            <T.P mb="2" color="error">
              {httpError?.message}
            </T.P>
          )}

          <Button.BasicButton
            variant="primary"
            disabled={false}
            loading={isLoading}
            type="submit"
            id="login-button"
          >
            Log in
          </Button.BasicButton>
        </Col>
      </Row>
      <Row mt="1">
        <Col w={[4, 12, 12]}>
          <ReCaptcha />
        </Col>
      </Row>
      <Divider showText />
      <Row mt="4" mb="7">
        <Col w={[4, 12, 12]}>
          <Button.BasicButton
            id="login-as-learner-button"
            variant="transparent"
            customColor="neutralMain"
            bgColor="neutralSurface"
            borderColor="neutral50"
            disabled={false}
            loading={isLoading}
            type="submit"
            to={R.LEARNER.LOGIN}
            width="100%"
            showSound
            soundBgColor="neutral50"
            soundColor="neutralMain"
            audioUrl={getMediaUrl(
              audio.logInAsLearner, // TODO: update audio file
              true,
              generalState?.preferredVoice
            )}
            padding="0"
            fallbackText={'Log in as learner'}
          >
            Log in as learner
          </Button.BasicButton>
        </Col>
      </Row>
    </S.Form>
  );
};

export default Login;
