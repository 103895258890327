import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import reportWebVitals from './reportWebVitals';
import ErrorBoundary from './ErrorBoundary';
import { queryClient } from './api-calls/queryClient';
// eslint-disable-next-line no-unused-vars
import MaintenanceMode from './MaintenanceMode';

const container = document.getElementById('root');

const root = createRoot(container);
root.render(
  <QueryClientProvider client={queryClient}>
    <ErrorBoundary>
      <App />

      {/* MAINTENANCE MODE */}
      {/* <MaintenanceMode /> */}
    </ErrorBoundary>
    {process.env.NODE_ENV === 'development' ? (
      <ReactQueryDevtools initialIsOpen={false} buttonPosition="top-left" />
    ) : null}
  </QueryClientProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
