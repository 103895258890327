import React, { useMemo } from 'react';
import * as S from './style';
import * as T from 'components/Typography';
import { BasicButton } from 'components/Button';
import Image from 'components/Image';
import { Divider } from 'components/Divider';
import { CopyLink, Icon, InfoCard, Loading, StatsChart } from 'components';
import {
  useGetLearnerProfile,
  useGetLearnerStuckAt,
} from 'api-calls/learners.queries';
import { useNavigate, useParams } from 'react-router-dom';
import { useCallback } from 'react';
import { navRoutes, userRoles } from '../../../../constants';
import LearnerStatus from './LearnerStatus';
import DeleteAccount from './DeleteAccount';
import ProgressReports from './ProgressReports';
import { useAuth } from 'context/auth';
import { Col, Row } from 'components/Grid';
import { InfoCardWithButton } from 'components/InfoCard';
import Badges from './Badges';
import StartCoachingButton from 'components/StartCoachingButton';
import { useOrganisationDetails } from 'context/organisation-details';
import LearnersNotes from '../LearnersNotes';
import { COACH } from 'constants/nav-routes';
import GenericCommonTable from 'components/CommonTables/GenericCommonTable';
import TabsCommonTable from 'components/CommonTables/TabsCommonTable';
import moment from 'moment';
import Tag from 'components/Tag';
import { useGetLearnerSessions } from 'api-calls/learner-coach-sessions.queries';
import ViewAnotherCourseProfile from 'components/ViewAnotherCourseProfile';
import { useSiteSettings } from 'context/site-settings';

const NewLearnerProfile = () => {
  const { user } = useAuth();
  const { userId } = useParams();
  const navigate = useNavigate();
  const { learnerProfile = {}, isLoading } = useGetLearnerProfile({
    learnerUserId: userId,
  });
  const { loaded: siteSettingsLoaded, isWorkingInPrisonEnvironment } =
    useSiteSettings();
  const isNotInPrisonEnvironment =
    siteSettingsLoaded && !isWorkingInPrisonEnvironment;
  const dashboardLink = navRoutes[user?.role].DASHBOARD;
  const { stuckAt } = useGetLearnerStuckAt({ learnerUserId: userId });
  const { organisationDetails } = useOrganisationDetails();

  const isCoach = user?.role === userRoles.COACH;
  const isAdmin = user?.role === userRoles.ADMIN;

  const showBadges = [
    userRoles.COACH,
    userRoles.LEARNER,
    userRoles.FACILITATOR,
    userRoles.ORGANISATION_ADMIN,
    userRoles.ADMIN,
  ].includes(user?.role);

  const learner = useMemo(() => learnerProfile?.learner, [learnerProfile]);
  const coach = useMemo(() => learnerProfile?.coach, [learnerProfile]);
  const stats = useMemo(() => learnerProfile?.stats, [learnerProfile]);
  const latestProgressReport = useMemo(
    () => learnerProfile?.latestProgressReport,
    [learnerProfile]
  );

  const { data: sessions, isLoading: getSessionsLoading } =
    useGetLearnerSessions(
      {
        id: userId,
        status: 'upcoming',
      },
      { enabled: !!coach }
    );
  const currentMilestone =
    stats?.milestonesReached?.find((m) => m.status === 'current') ||
    stats?.milestonesReached?.find((m) => m.id === 1);

  const nextMilestone =
    stats?.milestonesReached?.[
      stats?.milestonesReached?.findIndex(
        (m) => m.id === currentMilestone?.id
      ) + 1
    ];

  const reassignCoach = useCallback(() => {
    navigate(navRoutes.COMMON.ASSIGN_COACH.replace(':id', userId));
  }, [navigate, userId]);

  const viewDetails = useCallback(() => {
    navigate(navRoutes.COMMON.LEARNER_REFERRAL.replace(':id', userId));
  }, [navigate, userId]);

  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const columns = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
      render: (text) => <T.P style={{ whiteSpace: 'nowrap' }}>{text}</T.P>,
    },
    {
      title: 'Session Date',
      dataIndex: 'start',
      key: 'start',
      render: (start) => (
        <T.P style={{ whiteSpace: 'nowrap' }}>
          {moment(start).format('DD MMM YYYY')}
        </T.P>
      ),
    },
    {
      title: 'Time',
      dataIndex: 'start',
      key: 'start',
      render: (start) => (
        <T.P style={{ whiteSpace: 'nowrap' }}>
          {moment(start).format('hh:mm A')}
        </T.P>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => {
        return (
          <div>
            {status === 'PENDING' ? (
              <Tag color="quinaryDark">
                <T.P color="white" weight="bold">
                  PENDING
                </T.P>
              </Tag>
            ) : status === 'CANCELED' ? (
              <Tag color="quaternaryMainHex">
                <T.P color="white" weight="bold">
                  Cancelled
                </T.P>
              </Tag>
            ) : status === 'BOOKED' ? (
              <Tag color="quaternaryMainHex">
                <T.P color="white" weight="bold">
                  BOOKED
                </T.P>
              </Tag>
            ) : null}
          </div>
        );
      },
    },
    {
      title: 'Location',
      dataIndex: 'link',
      key: 'link',
      render: (_, row) => {
        const isOnline = row.type === 'ONLINE';

        let locationTxt = isOnline
          ? row.link
          : `${row.addressLine1}, ${row.town}, ${row.postcode}`;
        return (
          <CopyLink
            text={locationTxt}
            title={isOnline ? 'Copy Link' : 'Copy Address'}
          />
        );
      },
    },
    isCoach
      ? {
          title: 'Action',
          key: 'action',
          render: (_, record) => (
            <BasicButton
              variant="tertiary"
              height="40px"
              width="130px"
              icon="edit3"
              to={COACH.EDIT_COACHING_SESSION.replace(
                ':sessionIdP',
                record.id
              ).replace(':learnerIdP', record.learnerId)}
            >
              View/Edit
            </BasicButton>
          ),
        }
      : {},
  ];
  if (isLoading) return <Loading />;

  return (
    <S.PageWrapper>
      <S.CoverWrapper>
        <S.CoverBackButtonWrapper>
          <BasicButton
            handleClick={goBack}
            variant="tertiary"
            icon={'leftChevron'}
          >
            Back
          </BasicButton>
        </S.CoverBackButtonWrapper>
        <S.CoverBackgroundWrapper>
          <Image image="profileCover" width="100%" height="100%" />
        </S.CoverBackgroundWrapper>
      </S.CoverWrapper>
      <S.Content>
        <S.DetailsWrapper>
          <S.AvatarNameWrapper>
            <S.AvatarWrapper>
              <T.P size="small" color="white">
                ID:
              </T.P>
              <T.P size="med" color="white">
                {learner?.learnerUniqueId}
              </T.P>
            </S.AvatarWrapper>
            <S.NameWrapper>
              <S.NameStuckLabelWrapper>
                <T.H1>{learner?.fullName}</T.H1>
                {stuckAt?.length ? (
                  <S.Stucklabel>Stuck learner</S.Stucklabel>
                ) : null}
              </S.NameStuckLabelWrapper>
              <S.TagsWrapper awaitCoach={!coach}>
                {!!coach ? (
                  <T.P size="med">Coach: {coach?.fullName || 'N/A'}</T.P>
                ) : (
                  <S.Tag bgColor="neutral50" fontColor="neutralMain">
                    Awaiting Coach
                  </S.Tag>
                )}
                {isCoach && isNotInPrisonEnvironment && (
                  <S.ChatLink
                    to={navRoutes.COACH.CHAT_SAFEGUARD.replace(
                      ':receiverId',
                      learner.id
                    )}
                  >
                    <Icon icon="message" /> <span>Send Message</span>
                  </S.ChatLink>
                )}
                {learner.isSelfSignedUp && (
                  <S.Tag bgColor="quaternaryLightBlue" fontColor="primaryMain">
                    Self-Referral
                  </S.Tag>
                )}
              </S.TagsWrapper>
              <ViewAnotherCourseProfile
                isCmidCourse={learner?.isCmidCourse}
                isTpdCourse={learner.isTpdCourse}
              />
              {/* <T.P size="med">Coach: {coach?.fullName || 'N/A'}</T.P> */}
            </S.NameWrapper>
          </S.AvatarNameWrapper>
          {isCoach && learner.onboarded && (
            <StartCoachingButton
              id={userId}
              user={user}
              learner={learner}
              learnerUniqueId={learner.learnerUniqueId}
              margin={'32px'}
            />
          )}
          {isCoach && !learner.onboarded && (
            <BasicButton
              variant="secondary"
              maxWidth={'fit-content'}
              to={COACH.NEW_LEARNER.replace(
                ':firstName',
                learner.firstName
              ).replace(':userId', learner.id)}
            >
              <T.P color="white" weight="semi">
                Onboard {learner?.firstName}
              </T.P>
            </BasicButton>
          )}
          {!isAdmin && !isCoach && (
            <BasicButton
              handleClick={reassignCoach}
              maxWidth="220px"
              variant="tertiary"
              icon={'assign'}
            >
              {coach?.id ? 'Reassign coach' : 'Assign coach'}
            </BasicButton>
          )}
        </S.DetailsWrapper>
        <Divider my="6" />

        <>
          <T.H1 size="med" mb="5">
            {learner?.firstName}’s status
          </T.H1>
          <Row inner>
            <Col w={[4, 6, 6]}>
              <InfoCard
                firstColumnLabel="Current milestone"
                firstColumnValue={currentMilestone?.title}
                buttonText={isCoach && 'Prepare'}
                to={
                  isCoach &&
                  navRoutes.COACH.PREPARE_MILESTONE.replace(
                    ':milestoneId',
                    currentMilestone?.id
                  )
                }
                noIcon={!isCoach}
              />
            </Col>
            <Col w={[4, 6, 6]}>
              <InfoCard
                firstColumnLabel="Next milestone"
                firstColumnValue={nextMilestone?.title}
                buttonText={isCoach && 'Prepare'}
                to={
                  isCoach &&
                  navRoutes.COACH.PREPARE_MILESTONE.replace(
                    ':milestoneId',
                    nextMilestone?.id
                  )
                }
                noIcon={!isCoach}
              />
            </Col>
          </Row>
          {isNotInPrisonEnvironment && (
            <>
              <Divider my="6" />
              <S.TableTitle>
                <div
                  style={{
                    flex: 2,
                  }}
                >
                  <T.H2 style={{ textWrap: 'nowrap' }}>Coaching Sessions</T.H2>
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    flex: 1,
                    gap: '1rem',
                    margin: '10px 0px',
                    justifyContent: 'flex-end',
                  }}
                >
                  {isCoach && (
                    <BasicButton
                      icon="calendar"
                      variant="tertiary"
                      to={`${
                        navRoutes.COACH.CREATE_COACHING_SESSION
                      }${`?learnerUserId=${userId}`}`}
                      style={{ whiteSpace: 'nowrap' }}
                    >
                      Arrange Session
                    </BasicButton>
                  )}
                  <T.Link
                    to={navRoutes.COMMON.LEARNER_SESSIONS.replace(
                      ':learnerId',
                      userId
                    )}
                  >
                    <T.H3
                      style={{
                        textWrap: 'nowrap',
                        textDecoration: 'underline',
                        whiteSpace: 'nowrap',
                        color: '#405ED6',
                        cursor: 'pointer',
                      }}
                    >
                      All sessions
                    </T.H3>
                  </T.Link>
                </div>
              </S.TableTitle>
              <GenericCommonTable
                columns={columns}
                dataSource={sessions}
                isResponsiveScroll={true}
                isLoading={getSessionsLoading}
                paginationDisabled={true}
                emptyState={{
                  title: 'No coaching sessions found',
                  subTitle:
                    isCoach &&
                    'Click on arrange session to create a new session',
                  buttonText: isCoach && 'Arrange Session',
                  buttonLink:
                    isCoach &&
                    `${
                      navRoutes.COACH.CREATE_COACHING_SESSION
                    }${`?learnerUserId=${userId}`}`,
                }}
              />
              <TabsCommonTable page={1} />
            </>
          )}
          <Divider my="6" />
          {learner?.onboarded && (
            <>
              <T.H1 size="med" mb="5">
                Exercises {learner.firstName} is finding difficult
              </T.H1>
              {stuckAt?.length ? (
                <Row gap="24px 0" inner>
                  {stuckAt.map((learner, i) => {
                    return (
                      <Col w={[4, 6, 6]}>
                        <InfoCardWithButton
                          adminPreview={!isCoach}
                          firstColumnLabel="Stuck on"
                          firstColumnValue={
                            learner?.stepData?.title?.toUpperCase() +
                            ', ' +
                            learner.title
                          }
                          handleClick={async () => {
                            navigate(
                              navRoutes.COACH.REVIEW_STUCK_AT.replace(
                                ':exerciseId',
                                learner.exerciseId
                              ).replace(':stepId', learner.stepId),
                              {
                                state: {
                                  type: learner.stepType,
                                  to: navRoutes.COMMON.LEARNER.replace(
                                    ':userId',
                                    userId || user.selectedLearnerId
                                  ),
                                },
                              }
                            );
                          }}
                        />
                      </Col>
                    );
                  })}
                </Row>
              ) : (
                <InfoCardWithButton />
              )}
              <Divider my="6" />
            </>
          )}
        </>

        <T.H1 mb="5">{learner?.firstName}’s stats</T.H1>
        <S.StatsWrapper>
          <S.StatsColWrapper>
            <StatsChart
              backgroundMode="progress"
              statChartType="progress"
              value={stats?.completedExercisesPerc}
            />
            <StatsChart
              backgroundMode="quaternary"
              statChartType="completionTaskLastDate"
              value={stats?.dateOfLastCoachingSession}
            />
          </S.StatsColWrapper>
          <StatsChart
            backgroundMode="light"
            statChartType="learningTime"
            selfLearningPercentage={stats?.selfLearningPercentage}
            value={stats?.totalSessionTimesTotal}
          />
          <S.StatsColWrapper>
            <StatsChart
              backgroundMode="light"
              statChartType="sessionDate"
              value={stats?.dateOfLastCoachingSession}
            />
            <StatsChart
              backgroundMode="dark"
              statChartType="milestone"
              value={stats?.milestoneReached}
              actionLink={navRoutes.COMMON.LEARNER_REACHED_MILESTONES.replace(
                ':learnerId',
                userId
              )}
            />
          </S.StatsColWrapper>
        </S.StatsWrapper>
        <Divider my="6" />
        <ProgressReports
          stats={stats}
          latestProgressReport={latestProgressReport}
        />
        <Divider my="6" />
        {!showBadges ? null : (
          <>
            <Badges />
            <Divider my="6" />
          </>
        )}
        {!isCoach && (
          <>
            <T.H2 size="med" mb="2">
              Details
            </T.H2>
            <T.P mb="6">
              Here you can view and update all relevant details for the learner.
            </T.P>
            <BasicButton
              handleClick={viewDetails}
              variant="tertiary"
              maxWidth="255px"
            >
              View details
            </BasicButton>
            <Divider my="6" />
          </>
        )}
        <LearnerStatus learner={learner} />
        <Divider my="6" />
        {!isCoach ? null : (
          <>
            <S.HandOverCard
              jc={isWorkingInPrisonEnvironment ? 'start' : 'center'}
            >
              <div>
                <T.H2 size="med" mb="2">
                  I need to handover my learner
                </T.H2>
                <T.P>
                  {isWorkingInPrisonEnvironment
                    ? 'To arrange the handover your learner please contact your program facilitator'
                    : 'To arrange the handover your learner you need to email your organisation'}
                </T.P>
              </div>
              {isNotInPrisonEnvironment && (
                <BasicButton
                  maxWidth="230px"
                  mtM="4"
                  external
                  to={`mailto:${organisationDetails?.email}?subject=Handover%20my%20learner%20${learner?.fullName}&body=Hi%20${organisationDetails?.name}%2C%0D%0A%0D%0AI%20would%20like%20to%20handover%20my%20learner%20${learner?.fullName}%20to%20another%20coach.%0D%0A%0D%0AKind%20regards%2C%0D%0A${user?.firstName}%20${user?.lastName}%0D%0A${user?.email}`}
                >
                  Email organisation
                </BasicButton>
              )}
            </S.HandOverCard>
            <Divider my="6" />
          </>
        )}
        {isNotInPrisonEnvironment && (
          <>
            <LearnersNotes learnerId={userId} />
            <Divider my="6" />
          </>
        )}
        {!isCoach && <DeleteAccount />}
        <BasicButton
          to={dashboardLink}
          icon={'back'}
          variant="secondary"
          maxWidth="225px"
        >
          Back to dashboard
        </BasicButton>
      </S.Content>
    </S.PageWrapper>
  );
};

export default NewLearnerProfile;
