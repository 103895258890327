import theme from '../../../theme';

const Pencil = ({ width, height, color, ...props }) => (
  <svg
    fill={theme.colors[color] || color || 'none'}
    width={width || '20'}
    height={height || '20'}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.0673 0.182992C15.9501 0.0658221 15.7911 0 15.6254 0C15.4597 0 15.3007 0.0658221 15.1835 0.182992L13.1248 2.24174L17.7585 6.87549L19.8173 4.81799C19.8755 4.75993 19.9216 4.69097 19.9531 4.61503C19.9847 4.5391 20.0009 4.4577 20.0009 4.37549C20.0009 4.29328 19.9847 4.21188 19.9531 4.13595C19.9216 4.06002 19.8755 3.99105 19.8173 3.93299L16.0673 0.182992ZM16.8748 7.75924L12.241 3.12549L4.11601 11.2505H4.37476C4.54052 11.2505 4.69949 11.3163 4.8167 11.4335C4.93391 11.5508 4.99976 11.7097 4.99976 11.8755V12.5005H5.62476C5.79052 12.5005 5.94949 12.5663 6.0667 12.6835C6.18391 12.8008 6.24976 12.9597 6.24976 13.1255V13.7505H6.87476C7.04052 13.7505 7.19949 13.8163 7.3167 13.9335C7.43391 14.0508 7.49976 14.2097 7.49976 14.3755V15.0005H8.12476C8.29052 15.0005 8.44949 15.0663 8.5667 15.1835C8.68391 15.3008 8.74976 15.4597 8.74976 15.6255V15.8842L16.8748 7.75924ZM7.53976 17.0942C7.51345 17.0243 7.49991 16.9502 7.49976 16.8755V16.2505H6.87476C6.709 16.2505 6.55003 16.1846 6.43282 16.0674C6.31561 15.9502 6.24976 15.7913 6.24976 15.6255V15.0005H5.62476C5.459 15.0005 5.30003 14.9346 5.18282 14.8174C5.06561 14.7002 4.99976 14.5413 4.99976 14.3755V13.7505H4.37476C4.209 13.7505 4.05003 13.6846 3.93282 13.5674C3.81561 13.4502 3.74976 13.2913 3.74976 13.1255V12.5005H3.12476C3.05004 12.5003 2.97595 12.4868 2.90601 12.4605L2.68226 12.683C2.62269 12.743 2.57592 12.8144 2.54476 12.893L0.0447576 19.143C-0.000705742 19.2566 -0.0118351 19.381 0.0127492 19.5008C0.0373335 19.6207 0.0965504 19.7307 0.183059 19.8172C0.269567 19.9037 0.379562 19.9629 0.499407 19.9875C0.619252 20.0121 0.743678 20.001 0.857258 19.9555L7.10726 17.4555C7.18584 17.4243 7.25728 17.3776 7.31726 17.318L7.53976 17.0942Z"
      fill="black"
    />
  </svg>
);

export default Pencil;
