import { useState } from 'react';

import * as T from '../../../../components/Typography';
import { PlayBig, PlayButton } from '../../../../components/Button';
import Markdown from '../../../../components/Markdown';
import { createH1 } from '../../../../utils/markdown';
import TryAgainModal from '../../../../components/ModalNew/TryAgainModal';
import OptionButton from './OptionButton';
import * as S from './style';
import * as SharedStyles from '../shared/style';
import StepFooterButtons from '../StepFooterButtons';
import { multipleChoiceVariations } from '../../../../constants';

const Step = ({
  data: {
    title,
    paragraph,
    options,
    textAudio,
    textAudioUrl,
    question,
    questionAudioUrl,
    variation = multipleChoiceVariations.variations.DEFAULT,
  } = {},
  audioUrl,
  imageUrl,
  handleNext,
  disabled,
  stuckAtReview,
  preview,
  bubble,
  isLoading,
}) => {
  const [showTryAgain, setShowTryAgain] = useState(false);
  const [selected, setSelected] = useState({
    option: '',
    id: '',
    isCorrect: null,
  });

  const handleClick = () => {
    if (selected.isCorrect || stuckAtReview) {
      handleNext();
    } else {
      setShowTryAgain(true);
    }
  };

  // use same logic in /StepForms/MultipleChoice/index.js
  const showPlaySound =
    !variation || variation === multipleChoiceVariations.variations.DEFAULT;
  const applyNewStyle =
    variation && variation !== multipleChoiceVariations.variations.DEFAULT;
  const optionsHaveImages =
    variation === multipleChoiceVariations.variations.OPTIONS_HAVE_IMAGES ||
    variation ===
      multipleChoiceVariations.variations.TEXT_AND_PARAGRAPH_AT_THE_TOP;
  const txtImageAtTop =
    variation === multipleChoiceVariations.variations.TEXT_AND_IMAGE_AT_THE_TOP;
  const txtParagraphAtTop =
    variation ===
    multipleChoiceVariations.variations.TEXT_AND_PARAGRAPH_AT_THE_TOP;

  return (
    <S.Wrapper>
      {textAudio && !bubble ? (
        <S.ButtonWrapper>
          {textAudioUrl ? (
            <S.TextAudioWrapper mb={4}>
              <PlayButton
                audioUrl={textAudioUrl}
                width="56px"
                height="56px"
                iconProps={{ width: '32px', height: '32px' }}
                fallbackText={textAudio}
              />
              <T.P ml={4} weight="regular" size="med">
                {textAudio}
              </T.P>
            </S.TextAudioWrapper>
          ) : (
            <T.P weight="regular" size="med" mb={4}>
              {textAudio}
            </T.P>
          )}
        </S.ButtonWrapper>
      ) : null}
      {bubble ? (
        <S.ButtonWrapper>
          {textAudioUrl ? (
            <PlayButton
              audioUrl={textAudioUrl}
              height="56px"
              iconProps={{ width: '32px', height: '32px' }}
              weight="regular"
              mb={7}
              bubble
              fallbackText={textAudio}
            >
              {textAudio}
            </PlayButton>
          ) : (
            <T.P weight="regular" size="med" mb={7}>
              {textAudio}
            </T.P>
          )}
        </S.ButtonWrapper>
      ) : null}
      {title ? (
        <Markdown
          text={createH1(title)}
          customStyles={{
            h1: {
              size: 'extraLarge',
              ta: 'center',
              weight: 'regular',
              mb: 3,
              mt: 0,
            },
          }}
        />
      ) : null}
      {imageUrl ? (
        <S.ImageWrapper applyNewStyle={applyNewStyle}>
          <img
            alt={imageUrl.split('/').pop().split('.')[0]}
            src={imageUrl}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'contain',
            }}
          />
        </S.ImageWrapper>
      ) : null}
      {!bubble && showPlaySound && (
        <S.ButtonWrapper>
          <PlayBig label={'Play sound'} audioUrl={audioUrl} mt="3" mb="3" />
        </S.ButtonWrapper>
      )}

      {txtParagraphAtTop && (
        <>
          <SharedStyles.OuterWrapper
            bg="neutralLight"
            w="311px"
            mt={4}
            mb={5}
            mx="auto"
          >
            <Markdown
              text={paragraph}
              customStyles={{
                p: {
                  position: 'relative',
                  zIndex: 4,
                  lh: '24px',
                  padding: '0.15rem',
                  size: 'regular',
                },
                li: {
                  size: 'regular',
                },
              }}
            />
          </SharedStyles.OuterWrapper>
          <S.ButtonWrapper>
            <S.TextAudioWrapper mt="6" mb="3">
              <PlayButton
                audioUrl={questionAudioUrl}
                width="56px"
                height="56px"
                iconProps={{ width: '32px', height: '32px' }}
                fallbackText={question}
              />
              <T.P ml={4} weight="bold" size="med">
                {question}
              </T.P>
            </S.TextAudioWrapper>
          </S.ButtonWrapper>
        </>
      )}
      <S.OptionsWrapper applyNewStyle={applyNewStyle}>
        {options?.map(
          ({ imageUrl, audioUrl: optionAudioUrl, option, isCorrect }, i) => (
            <OptionButton
              mb={3}
              grid={
                options.length <= 3
                  ? false
                  : applyNewStyle
                  ? true
                  : options.length > 3 && !bubble
              }
              index={i}
              key={option + i}
              option={option}
              handleClick={setSelected}
              selected={selected}
              imageUrl={imageUrl}
              audioUrl={optionAudioUrl}
              isCorrect={isCorrect}
              bubble={bubble}
              optionsHaveImages={optionsHaveImages}
              variant={txtImageAtTop ? (options.length > 4 ? 'small' : '') : ''}
            />
          )
        )}
      </S.OptionsWrapper>
      <S.ButtonsContainer applyNewStyle={applyNewStyle}>
        <StepFooterButtons
          stuckAtReview={stuckAtReview}
          handleNext={handleClick}
          preview={preview}
          disabled={disabled}
          enableNext={selected.option}
          isLoading={isLoading}
        />
      </S.ButtonsContainer>
      <TryAgainModal
        aria-modal="true"
        isModalVisible={showTryAgain}
        setIsModalVisible={setShowTryAgain}
      />
    </S.Wrapper>
  );
};

export default Step;
