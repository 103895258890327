import { navRoutes } from '../constants';

export const getOrganisationInviteUrl = ({
  inviteToken,
  subdomain,
  isWorkingInPrisonEnvironment,
}) => {
  let inviteLink = `${
    window.location.origin
  }${navRoutes.ORGANISATION_ADMIN.SIGNUP.replace(':inviteToken', inviteToken)}`;

  if (isWorkingInPrisonEnvironment) {
    const [protocol, link] = inviteLink.split('://');
    inviteLink = `${protocol}://${subdomain}.${link}`;
  }

  return inviteLink;
};

export const getOrganisationCustomUrl = ({ subdomain }) => {
  const [protocol, link] = window.location.origin.split('://');
  const customLink = `${protocol}://${subdomain}.${link}`;

  return customLink;
};
