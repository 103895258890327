import { questionAndAnswerVariations } from '../../../../../constants';
import { string, array, object, number } from 'yup';

export const validationSchema = {
  variation: string()
    .oneOf(Object.values(questionAndAnswerVariations.variations))
    .required(),
  textAudio: string().when('variation', {
    is: (variation) =>
      variation !== questionAndAnswerVariations.variations.IMAGE_ONLY,
    then: string().required('required field'),
    otherwise: string().notRequired(),
  }),
  textAudioKey: string().notRequired(),
  imageKey: string().when('variation', {
    is: (variation) =>
      variation !== questionAndAnswerVariations.variations.NO_IMAGE,
    then: string().required('required field'),
    otherwise: string().notRequired(),
  }),
  paragraphs: array().when('variation', {
    is: (variation) =>
      variation === questionAndAnswerVariations.variations.NO_IMAGE,
    then: array()
      .of(
        object().shape({
          id: number().required('required field'),
          text: string().required('required field'),
        })
      )
      .max(2, 'You can have a maximum of 2 paragraphs')
      .required('required field'),
    otherwise: array().notRequired(),
  }),
  questions: array().when('variation', {
    is: (variation) =>
      questionAndAnswerVariations.showQuestionVariations.includes(variation),
    then: array()
      .of(
        object().shape({
          id: number().required('required field'),
          question: string().required('required field'),
          questionAudioKey: string().required('required field'),
        })
      )
      .min(1, 'You should have at least one question')
      .max(6, 'You can have a maximum of 6 questions')
      .required('required field'),
    otherwise: array().notRequired(),
  }),
  correctOptions: array()
    .of(
      object()
        .shape({
          id: number().notRequired(),
          option: string().required('required field'),
          ending: string().test(
            'ending-required',
            'Provide ending is required',
            function (value) {
              // Access parent variation through this.parent
              const parentObject =
                (this.from && this.from[1] && this.from[1].value) || {};
              const variation = parentObject.variation;

              switch (variation) {
                case questionAndAnswerVariations.variations.WITH_ENDING:
                case questionAndAnswerVariations.variations
                  .TWO_INPUTS_WITH_ENDINGS:
                  return Boolean(value);

                default:
                  return true;
              }
            }
          ),
        })
        .required()
    )
    .required('you must provide answers')
    .when('variation', (variation, schema) => {
      // Handle array length validation
      let validatedSchema = schema;
      if (questionAndAnswerVariations.allowThreeAnswers.includes(variation)) {
        validatedSchema = schema.length(
          3,
          'you must provide exactly three answers'
        );
      } else if (
        questionAndAnswerVariations.allowTwoAnswers.includes(variation)
      ) {
        validatedSchema = schema.length(
          2,
          'you must provide exactly two answers'
        );
      } else if (
        questionAndAnswerVariations.variations.NO_IMAGE === variation
      ) {
        validatedSchema = schema
          .min(1, 'You must provide at least one answer')
          .max(6, 'You can have a maximum of 6 answers');
      } else {
        validatedSchema = schema.length(1, 'only one answer allowed');
      }

      return validatedSchema;
    }),
};
