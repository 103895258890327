import styled from '@emotion/styled';
import { Link } from 'components/Typography';

export const Header = styled.header`
  /* put back in if wanting to customise the header */
  /* background: ${({ theme, bgColor }) =>
    bgColor ? theme.colors[bgColor] || bgColor : theme.colors.neutralMain}; */
  background: ${({ theme }) => theme.colors.neutralMain};
  border: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 300;
  position: sticky;
  width: 100%;
  top: 0;
  min-height: ${({ theme }) => theme.constants.layout.header.height};
`;

export const MobileHeader = styled.header`
  /* put back in if wanting to customise the header */
  /* background: ${({ theme, bgColor }) =>
    bgColor ? theme.colors[bgColor] || bgColor : theme.colors.neutralMain}; */
  background: ${({ theme, customLogo }) =>
    customLogo ? theme.colors.secondaryMain : theme.colors.neutralMain};
  border: none;
  justify-content: space-between;
  z-index: 300;
  position: sticky;
  width: 100%;
  top: 0;
  min-height: ${({ theme }) => theme.constants.layout.header.mobileHeight};
`;

export const SubMenuWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  padding: 12px 24px;
  margin: 0px -24px;
  cursor: pointer;
`;

export const SubMenu = styled.div`
  position: absolute;
  top: 100%;
  right: 20%;
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;

  border-radius: 8px;
  overflow: hidden;
  border: 1px solid ${({ theme }) => theme.colors.secondaryLight};
  background: ${({ theme }) => theme.colors.neutral50};
`;

export const SubMenuItem = styled(Link)`
  display: flex;
  min-width: 176px;
  max-width: 300px;
  min-height: 60px;
  padding: 0px 16px !important;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral40};
  transition: all 0.5s ease-in-out;
  background: ${({ theme }) => theme.colors.neutral50};

  &:hover {
    color: ${({ theme }) => theme.colors.secondaryMain};
    background: ${({ theme }) => theme.colors.neutral40};
  }
`;

export const TitleContainer = styled(Link)`
  display: flex;
`;

export const IconsContainer = styled.div`
  display: flex;
`;

export const ProgressWrapper = styled.header`
  display: flex;
  height: ${({
    theme: {
      constants: { layout },
    },
  }) => layout.header.progressHeight};
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 1000;
  padding: ${({ theme }) => `${theme.spacings[0]} ${theme.spacings[5]}`};
  background: ${({ theme, bgColor }) =>
    bgColor ? theme.colors[bgColor] || bgColor : theme.colors.white};

  .ant-progress-inner {
    background-color: ${({ theme }) => theme.colors.neutral30};
  }
`;

export const IconButton = styled.button`
  border: none;
  background: none;
  outline: none;
`;

export const MenuWrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => `${theme.spacings[3]}`};
  justify-content: space-between;
  flex-direction: row;
  color: white;
  & div > a {
    padding: 6px 8px;
  }
  margin-left: ${({ theme }) => `${theme.spacings[5]}`};
`;

export const CustomLogoWrapperMobile = styled.div`
  background: ${({ theme }) => theme.colors.neutralMain};
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  height: 56px;

  padding: 0
    ${({ theme: { constants } }) => constants.layout.side.leftPadding.desktop};

  ${({ theme }) => theme.media.desktop} {
    height: 80px;
  }

  ${({ theme }) => theme.media.tablet} {
    height: 80px;
    padding: 0
      ${({ theme: { constants } }) => constants.layout.side.leftPadding.tablet};
  }
  ${({ theme }) => theme.media.mobile} {
    height: 56px;
    padding: 0
      ${({ theme: { constants } }) => constants.layout.side.leftPadding.mobile};
  }
`;

export const LogoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => `${theme.spacings[5]}`};
  align-items: center;
`;

export const CustomLogo = styled.img`
  max-width: 236px;
  max-height: 70px;
  object-fit: cover;

  /* mobile */
  ${({ theme }) => theme.media.mobile} {
    max-height: 40px;
    width: auto;
  }
`;

export const HeaderContent = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  height: 80px;

  padding: 0
    ${({ theme: { constants } }) => constants.layout.side.leftPadding.desktop};

  ${({ theme }) => theme.media.desktop} {
    height: ${({ customLogo }) => customLogo && '64px'};
  }
  ${({ theme }) => theme.media.tablet} {
    height: ${({ customLogo }) => customLogo && '64px'};
    padding: 0
      ${({ theme: { constants } }) => constants.layout.side.leftPadding.tablet};
  }
  ${({ theme }) => theme.media.mobile} {
    height: 56px;
    padding: 0
      ${({ theme: { constants } }) => constants.layout.side.leftPadding.mobile};
  }
`;

export const Overlay = styled.div`
  /* background: ${({ theme, bgColor }) =>
    bgColor ? theme.colors[bgColor] || bgColor : theme.colors.neutralMain}; */
  background: ${({ theme, customLogo }) => theme.colors.neutralMain};
  position: sticky;
  width: 100%;
  top: 0;
  height: 100vh;
  padding-top: ${({ theme }) => `${theme.spacings[2]}`};
  z-index: 150;
  opacity: 1;

  ${({ theme }) => theme.media.tablet} {
    padding-top: ${({ theme }) => `${theme.spacings[6]}`};
    padding-left: ${({ theme }) =>
      theme.constants.layout.side.leftPadding.tablet};
  }
  ${({ theme }) => theme.media.mobile} {
    padding-top: ${({ theme }) => `${theme.spacings[4]}`};
    padding-left: ${({ theme }) =>
      theme.constants.layout.side.leftPadding.mobile};
  }
`;

export const OverlayContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => `${theme.spacings[6]}`};
  padding-top: ${({ theme }) => `${theme.spacings[7]}`};
  padding-left: ${({ theme }) =>
    theme.constants.layout.side.leftPadding.desktop};

  ${({ theme }) => theme.media.tablet} {
    padding-top: ${({ theme }) => `${theme.spacings[6]}`};
    padding-left: ${({ theme }) =>
      theme.constants.layout.side.leftPadding.tablet};
  }
  ${({ theme }) => theme.media.mobile} {
    padding-top: ${({ theme }) => `${theme.spacings[2]}`};
    padding-left: ${({ theme }) =>
      theme.constants.layout.side.leftPadding.mobile};
    padding-bottom: ${({ theme }) => `${theme.spacings[4]}`};
  }
`;
