import React, { useState } from 'react';
import BasicInput from '../BasicInput';
import Icon from '../../Icon';

const PasswordInput = ({
  label,
  placeholder,
  password,
  handleChange,
  error,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <BasicInput
      id="password"
      label={label || 'Create new password'}
      placeholder={placeholder || 'Password...'}
      suffix={
        <Icon
          width="28px"
          height="28px"
          icon={showPassword ? 'eyeSlash' : 'eye'}
          onClick={() => setShowPassword(!showPassword)}
        />
      }
      margins={{ mt: '2', mb: '1' }}
      type={showPassword ? 'text' : 'password'}
      value={password}
      autoFocus
      handleChange={handleChange}
      error={error}
    />
  );
};

export default PasswordInput;
