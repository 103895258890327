import { string, number, array, object } from 'yup';

export const validationSchema = object({
  textAudio: string()
    .required('Explainer Text is required')
    .min(1, 'Explainer Text must not be empty'),
  textAudioKey: string()
    .required('Explainer Text file key is required')
    .min(1, 'Explainer Text file key must not be empty'),
  topNumber: number()
    .required('Top number is required')
    .min(0, 'Top number must be at least 0'),
  bottomNumbers: array()
    .of(
      object().shape({
        id: number().required(),
        value: number().required(),
      })
    )
    .required('Bottom numbers are required')
    .min(2, 'Bottom numbers must have at least 2 values')
    .max(5, 'Bottom numbers must have at most 5 values'),
  operation: string()
    .required('Operation is required')
    .min(1, 'Operation must not be empty'),
  correctAnswer: string()
    .required('Correct Answer is required')
    .min(1, 'Correct Answer must not be empty'),
});
