import styled from '@emotion/styled';

export const InputsWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.neutralLightNew};
  padding: ${({ theme }) => `${theme.spacings[4]} ${theme.spacings[5]}`};
`;

export const ImageWrapper = styled.div`
  margin: ${({ theme }) => theme.spacings[5]} auto;
  overflow: hidden;
`;
export const Img = styled.img`
  margin-top: 1rem;
  width: 100%;
  min-height: 184px;
  max-height: 300px;
  object-fit: cover;
`;
