import { useEffect, useReducer, useRef, useState } from 'react';
import { useAuth } from 'context/auth';
import { PageIconBanner, Icon } from 'components';
import * as T from 'components/Typography';
import { Row, Col } from 'components/Grid';
import * as S from './style';
import { BasicInput, ColorPicker } from 'components/Inputs';
import { BasicButton } from 'components/Button';
import { Upload } from 'antd';
import { useUpdateOrganisationCustomisation } from '../../../api-calls/organisations.queries';
import { mediaTypes, navRoutes } from './../../../constants';
import { useGetOrganisationByIdOrUniqueSlug } from '../../../api-calls/organisations.queries';
import { getOrgLogoUrl } from '../../../helpers';
import { formatQueryError } from '../../../utils/queries';
import { organisationCustomisation as validate } from '../../../validation/schemas';
import { useOrganisationDetails } from '../../../context/organisation-details';
import { Media } from '../../../api-calls';
import Modal from '../../../components/Modal';
import { BackButton } from 'components/BackButton';
import isColorBright from 'helpers/isColorBright';
import { useTheme } from '@emotion/react';

const initState = {
  id: null,
  uniqueSlug: '',
  type: mediaTypes.ORG_LOGO,
  imageFileList: [],
  logoKey: '',
  validationErrs: {},
  loading: false,
  httpError: '',
  uploadError: '',
  mainBrandColour: '',
  secondaryBrandColour: '',
  logoDeleted: false,
};

function reducer(state, newState) {
  return { ...state, ...newState };
}

const CustomiseTool = () => {
  const [state, setState] = useReducer(reducer, initState);
  const { user } = useAuth();
  const { organisationId } = user;
  const submitAttempt = useRef(false);
  const { organisationDetails, setOrganisationDetails } =
    useOrganisationDetails();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [stateChanging, setStateChanging] = useState(false);
  const theme = useTheme();

  const {
    mutateAsync: updateOrganisation,
    error: updateError,
    isLoading: updateLoading,
  } = useUpdateOrganisationCustomisation({ id: organisationId });

  const { data: organisation, isSuccess: getOrgSuccess } =
    useGetOrganisationByIdOrUniqueSlug({ id: organisationId });
  const handleUpload = async (f) => {
    setState({ loading: true });
    const { data: signedUrl, error } = await Media.getSignedUrl({
      fileName: f.file.name,
      fileType: f.file.type,
      fileSize: f.file.size,
      fileCategory: state.type,
      key: state.cleanKey || state.logoKey || undefined,
    });
    setStateChanging(true);

    if (error) {
      setState({
        uploadError: error.message,
        loading: false,
      });
      f.onError(error.message);
      return;
    }

    const { error: s3Error } = await Media.uploadFileToS3({
      signedUrl: signedUrl.url,
      file: f.file,
    });

    if (s3Error) {
      setState({
        uploadError: s3Error.message,
        loading: false,
      });
      f.onError(s3Error.message);
      return;
    }

    setState({
      logoKey: signedUrl.key,
      loading: false,
      cleanKey: signedUrl.cleanKey,
    });
    f.onSuccess();
  };

  const handleFileChange = ({ fileList: newFileList }) => {
    // if file is larger than 5mb, reject it
    if (newFileList[0]?.size >= 5242880) {
      setState({
        uploadError: 'File size must be 5mb or less',
      });
      return;
    }

    setState({
      imageFileList: newFileList,
      logoDeleted: newFileList?.length ? false : !!state?.logoKey,
    });
  };

  const onPreview = async (file) => {
    let src = file.url;

    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);

        reader.onload = () => resolve(reader.result);
      });
    }

    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    submitAttempt.current = true;

    const isValid = validateForm();
    if (!isValid) return;

    const {
      uniqueSlug,
      mainBrandColour,
      secondaryBrandColour,
      logoKey,
      logoDeleted,
    } = state;

    await updateOrganisation(
      {
        uniqueSlug,
        mainBrandColour,
        secondaryBrandColour,
        logoKey,
        logoDeleted,
      },
      {
        onSuccess: (data) => {
          setOrganisationDetails({
            ...organisationDetails,
            mainBrandColour,
            secondaryBrandColour,
            logoKey:
              logoKey?.replace('temp/', '') || organisationDetails.logoKey,
          });
          setState({
            httpError: '',
            logoKey: state.cleanKey,
          });
        },
      }
    );

    setIsModalVisible(true);
  };

  useEffect(() => {
    if (getOrgSuccess) {
      setState({
        uniqueSlug: organisation?.uniqueSlug,
        mainBrandColour: organisation?.mainBrandColour,
        secondaryBrandColour: organisation?.secondaryBrandColour,
        logoKey: organisation?.logoKey,
        imageFileList: organisation?.logoKey
          ? [
              {
                uid: '-1',
                name: 'image.png',
                status: 'done',
                url: `${getOrgLogoUrl(organisation?.logoKey)}?t=${Date.now()}`,
              },
            ]
          : [],
      });
    }
  }, [getOrgSuccess, organisation]);

  const validateForm = () => {
    try {
      validate(state);
      setState({ validationErrs: { hasError: false } });
      return true;
    } catch (error) {
      if (error.name === 'ValidationError') {
        setState({
          validationErrs: { ...error.inner, hasError: true },
        });
      }
      return false;
    }
  };

  useEffect(() => {
    const httpError = formatQueryError({
      error: updateError,
      isError: !!updateError?.code,
    });

    if (httpError) {
      if (httpError.statusCode === 409) {
        setState({
          validationErrs: {
            [httpError.data.field]: httpError.message,
          },
          httpError: httpError.message,
        });
      } else {
        setState({ httpError: httpError.message });
      }
    }
  }, [updateError]);

  useEffect(() => {
    if (submitAttempt.current) {
      validateForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.uniqueSlug, state.mainBrandColour, state.secondaryBrandColour]);

  const previewLink = navRoutes.COMMON.LEARNER_PAGE_PREVIEW.replace(
    ':uniqueSlug',
    state.uniqueSlug ? `${state.uniqueSlug}` : `${organisation?.uniqueSlug}`
  );
  const dashboardLink = navRoutes[user?.role].DASHBOARD;
  const isNotWorkingInPrisonEnvironment =
    !organisationDetails?.isWorkingInPrisonEnvironment;

  return (
    <>
      <BackButton link={dashboardLink} mb="7" />
      <PageIconBanner
        color="primaryMain"
        icon="catalog"
        title="Customise my Turning Pages tool"
      >
        <T.P maxWidth="725px">
          So your learners and coaches recognise and feel comfortable with the
          tool, we recommend customising it with your logo and brand colours.
          Please note this is completely optional.
        </T.P>
      </PageIconBanner>
      <Row mt={6} inner>
        <Col w={[4, 12, 6]}>
          <S.UniqueLinkWrapper>
            <T.H2 mb={4}>My unique link</T.H2>
            <T.P mb={5} color="neutral90">
              This is the unique link you share with coaches and learners to use
              your version of the tool
            </T.P>
            <T.P size="med" weight="bold" mb="4">
              {window.location.host} /
            </T.P>
            {isNotWorkingInPrisonEnvironment && (
              <BasicInput
                value={state.uniqueSlug}
                onChange={(e) => {
                  setState({ uniqueSlug: e.target.value.toLowerCase() });
                  setStateChanging(true);
                }}
                w="auto"
                wrapperStyle={{
                  display: 'flex',
                  flex: 1,
                  flexWrap: 'wrap',
                }}
                error={state.validationErrs?.uniqueSlug}
              />
            )}
          </S.UniqueLinkWrapper>
        </Col>
        <Col w={[4, 12, 6]} mtT="6">
          <S.ColorsDivWrapper>
            <T.P display="inline">
              <T.H2 display="inline">My organisation colours </T.H2>
              (optional)
            </T.P>
            <T.P mb={5} mt={4} color="neutral90">
              Here’s where you can change the colours of the Turning Pages tool
            </T.P>
            <ColorPicker
              color={state.mainBrandColour || ''}
              onChange={(v) => {
                setState({ mainBrandColour: v });
                setStateChanging(true);
              }}
              label="Main brand colour"
              m={{ mb: 5 }}
              error={state.validationErrs.mainBrandColour}
            />
            <ColorPicker
              color={state.secondaryBrandColour || ''}
              onChange={(v) => {
                setState({ secondaryBrandColour: v });
                setStateChanging(true);
              }}
              label="Secondary brand colour"
              error={state.validationErrs.secondaryBrandColour}
            />
          </S.ColorsDivWrapper>
        </Col>
      </Row>
      <Row mtT="6">
        <Col w={[4, 12, 6]}>
          <S.LogoDivWrapper>
            <T.P display="inline">
              <T.H2 display="inline">My logo </T.H2>
              (optional)
            </T.P>
            <T.P mb={4} mt={4} color="neutral90">
              Here you can upload your logo. Double check your logo looks
              correct on your brand colour. We advise it should be in white.
            </T.P>
            {/* w / h aspect ratio */}
            <T.P color="error">{state.uploadError}</T.P>
            <Upload
              customRequest={handleUpload}
              listType="picture-card"
              fileList={state.imageFileList}
              defaultFileList={state.imageFileList}
              onChange={(e) => handleFileChange({ ...e })}
              onPreview={onPreview}
              className="upload-image-style"
              accept="image/png"
              maxCount={1}
              onRemove={() => {
                setState({
                  imageFileList: [],
                  logoDeleted: true,
                });
                setStateChanging(true);
              }}
            >
              <S.DragAndDropContainer>
                <Icon
                  icon="fileUpload"
                  color="neutralMain"
                  width="24"
                  height="24"
                  mb={3}
                />
                <T.P color="primaryMain" weight="bold" display="inline">
                  Upload a file
                  <T.P color="neutralMain" display="inline" weight="bold">
                    {' '}
                    or drag and drop
                  </T.P>
                </T.P>
                <T.P color="neutral70" mt={1}>
                  PNG up to 5MB
                </T.P>
              </S.DragAndDropContainer>
            </Upload>
            {!state?.imageFileList?.[0] && (
              <T.P mt={4}>File: No logo uploaded currently</T.P>
            )}
          </S.LogoDivWrapper>

          <Row inner>
            <Col w={[4, 12, 6]}>
              <BasicButton
                variant="secondary"
                onClick={handleSubmit}
                loading={updateLoading}
                disabled={state.loading}
              >
                {organisation?.completedCustomisation
                  ? 'Save changes'
                  : 'Mark step as complete'}
              </BasicButton>
            </Col>
            <Col w={[4, 12, 6]} mtT="4">
              {organisation?.completedCustomisation && stateChanging && (
                <BasicButton
                  variant="tertiary"
                  to={previewLink}
                  loading={state.loading}
                  disabled={state.loading}
                  external
                  icon={'eye'}
                  iconProps={{
                    width: '20px',
                    height: '20px',
                  }}
                  iconMR={1}
                >
                  Preview changes
                </BasicButton>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
      <Modal visible={isModalVisible} setIsModalVisible={setIsModalVisible}>
        <Row>
          <Col w={[4, 12, 12]} mb={4}>
            <T.P
              color={
                isColorBright(theme.colors.primaryMain)
                  ? 'neutralMain'
                  : 'white'
              }
              weight="bold"
              size="med"
              mb={4}
            >
              Success
            </T.P>
            <T.H3
              color={
                isColorBright(theme.colors.primaryMain)
                  ? 'neutralMain'
                  : 'white'
              }
              mb={4}
            >
              You have updated your portal. See what it looks like here
            </T.H3>
            <a href={previewLink} target="_blank" rel="noopener noreferrer">
              <T.P
                color={
                  isColorBright(theme.colors.primaryMain)
                    ? 'neutralMain'
                    : 'white'
                }
              >{`${window.location.host}${previewLink}`}</T.P>
            </a>
          </Col>
        </Row>

        <BasicButton
          handleClick={() => {
            setIsModalVisible(false);
          }}
          variant="secondary"
        >
          Close
        </BasicButton>
      </Modal>
    </>
  );
};

export default CustomiseTool;
