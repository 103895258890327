import { ClearButton } from 'components/Button';
import { Col, Row } from 'components/Grid';
import React, { useEffect, useRef, useState } from 'react';
import CanvasDraw from 'react-canvas-draw';
import theme from 'theme';
import * as S from './style';
import Icon from 'components/Icon';

// TODO: Check how we can fix this warning in console react-dom.development.js:6878 Unable to preventDefault inside passive event listener invocation.
const DrawingPad = ({ drawingData, setDrawingData, setShowDrawingPad }) => {
  const canvasRef = useRef(null);
  const [canvasWidth, setCanvasWidth] = useState(345); // Default width for mobile

  // Function to update canvas width based on screen size
  const updateCanvasWidth = () => {
    if (window.innerWidth >= 1024) {
      setCanvasWidth(800); // Desktop width
    } else if (window.innerWidth >= 768) {
      setCanvasWidth(600); // Tablet width
    } else {
      setCanvasWidth(345); // Mobile width
    }
  };

  const handleDrawingDataSave = () => {
    if (canvasRef.current) {
      const data = canvasRef.current.getSaveData();
      if (data && JSON.parse(data).lines.length > 0) {
        setDrawingData(data);
      }
    }
  };

  // Listen for screen size changes
  useEffect(() => {
    updateCanvasWidth(); // Set initial width
    window.addEventListener('resize', updateCanvasWidth);

    return () => {
      window.removeEventListener('resize', updateCanvasWidth);
    };
  }, []);

  return (
    <S.Container>
      <Row jc="center" mb={6}>
        <Col w={[4, 7, 7]} mt={6} ml={6}>
          <S.IconButton
            onClick={() => setShowDrawingPad(false)}
            arai-label="hide-drawing-pad-button"
            title="hide-drawing-pad-button"
          >
            <Icon
              icon="back"
              color="neutralMain"
              ml={1}
              style={{ cursor: 'pointer' }}
            />
          </S.IconButton>
        </Col>
        <Col w={[4, 7, 7]} mt={6} jc="center">
          <CanvasDraw
            brushColor="rgba(0, 0, 0, 1)"
            gridColor={theme.colors.neutral80}
            hideGrid={false}
            hideInterface={true}
            canvasWidth={canvasWidth}
            canvasHeight={523}
            gridSizeX={30}
            gridSizeY={30}
            gridLineWidth={0.5}
            hideGridX={false}
            hideGridY={false}
            brushRadius={1}
            lazyRadius={0}
            saveData={drawingData} // Load drawing from parent state
            ref={canvasRef}
            onChange={handleDrawingDataSave}
            immediateLoading
          />
        </Col>
        <Col w={[4, 7, 7]} mt={6} mr={6}>
          <ClearButton
            handleClick={() => {
              canvasRef.current.clear();
              setDrawingData('{"lines":[],"width":345,"height":523}');
            }}
            jc="flex-end"
          />
        </Col>
      </Row>
    </S.Container>
  );
};

export default DrawingPad;
