import { useRef, useReducer, useEffect } from 'react';
import { learnerReferral as validate } from '../../../validation/schemas';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import * as S from './style';
import Form from './../../ReferLearner/Form';
import { Col, Row } from '../../../components/Grid';
import { Textarea, Checkbox, Dropdown } from '../../../components/Inputs';
import * as T from '../../../components/Typography';
import Loading from '../../../components/Loading';
import { BasicButton } from '../../../components/Button';
import Modal from '../../../components/Modal';

import {
  useCreateLearner,
  useUpdateLearnerReferral,
  useGetLearnerReferralByLearner,
} from '../../../api-calls/learners.queries';
import { useDeleteUser } from '../../../api-calls/users.queries';
import { useGetOrgProgress } from 'Hooks';

import { COMMON, FACILITATOR } from '../../../constants/nav-routes';
import {
  referralTypeOptions,
  referralStatuses,
  userRoles,
} from '../../../constants/data-types';

import RejectModal from './RejectModal';
import { cleanEmail } from '../../../helpers';
import {
  hiddenFieldsWhenOrganisationWorkingInPrison,
  useOrganisationDetails,
} from 'context/organisation-details';
import { BackButton } from 'components/BackButton';
import AlreadyEnrolled from './AlreadyEnrolled';
import { useSiteSettings } from 'context/site-settings';

const initialState = {
  form: {
    firstName: '',
    lastName: '',
    hasDeviceInternetAccess: '',
    involvedBefore: '',
    preReleased: true,
    nomisId: '',
    delius: '',
    email: '',
    dateOfBirth: null,
    probationOfficerName: '',
    probationOfficerEmail: '',
    availability: '',
    additionalInfo: '',
    requiredCoachGender: '',
    additionalNotes: '',
    isReferral: false,
    referrer: null,
    referralType: '',
    // here in case admin views a referral and wishes to update
    learnerOrganisationId: '',
    preferredSessionsType: '',
  },
  validationErrs: {},
  submitLoading: false,
  rejectModalVisible: false,
  rejectSuccessModalVisible: false,
};

function reducer(state, newState) {
  let value = newState;
  if (typeof newState === 'function') {
    value = newState(state);
  }

  return { ...state, ...value };
}

const LearnerReferral = () => {
  const { organisationDetails } = useOrganisationDetails();
  const { loaded: siteSettingsLoaded, isWorkingInPrisonEnvironment } =
    useSiteSettings();
  const isNotInPrisonEnvironment =
    siteSettingsLoaded && !isWorkingInPrisonEnvironment;
  const isWorkingInPrison = organisationDetails?.isWorkingInPrison;
  const isOrgInMultipleCourses =
    organisationDetails?.isTpdCourse && organisationDetails?.isCmidCourse;
  const [searchParams] = useSearchParams();
  const createNewLearner = searchParams.get('createNewLearner');

  const { id } = useParams();
  const navigate = useNavigate();

  const submitAttempt = useRef(false);
  const [state, setState] = useReducer(reducer, initialState);
  const { loading: getOrgProgressLoading, coachesCount } = useGetOrgProgress();

  const { mutateAsync: deleteUser, error: deleteUserError } = useDeleteUser({
    id,
    role: userRoles.LEARNER,
  });

  const { mutateAsync: createLearner, createLearnerError } =
    useCreateLearner(null);

  const {
    isLoading: getReferralLoading,
    isSuccess,
    data,
  } = useGetLearnerReferralByLearner({ learnerUserId: id }, { enabled: !!id });

  const { mutateAsync: updateLearnerReferral, error: updateReferralError } =
    useUpdateLearnerReferral({
      learnerUserId: id,
    });

  const {
    form: {
      firstName,
      lastName,
      hasDeviceInternetAccess,
      involvedBefore,
      preferredSessionsType,
      postcode,
      preReleased,
      nomisId,
      delius,
      email,
      dateOfBirth,
      probationOfficerName,
      probationOfficerEmail,
      availability,
      additionalInfo,
      requiredCoachGender,
      additionalNotes,
      isReferral,
      referrer,
      referralType,
      status,
    },
    form,
    submitLoading,
    validationErrs,
    rejectModalVisible,
    rejectSuccessModalVisible,
  } = state;

  const httpError =
    createLearnerError || updateReferralError || deleteUserError;
  const setFormData = (data) =>
    setState((prevState) => ({ form: { ...prevState.form, ...data } }));

  useEffect(() => {
    if (submitAttempt.current) {
      validateForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    firstName,
    lastName,
    hasDeviceInternetAccess,
    involvedBefore,
    preferredSessionsType,
    postcode,
    preReleased,
    nomisId,
    delius,
    email,
    probationOfficerName,
    probationOfficerEmail,
    availability,
    additionalInfo,
    dateOfBirth,
    requiredCoachGender,
    additionalNotes,
    referralType,
  ]);

  const validateForm = () => {
    try {
      validate(state.form, isWorkingInPrison, isWorkingInPrisonEnvironment);
      setState({ validationErrs: { hasError: false } });
      return true;
    } catch (error) {
      if (error.name === 'ValidationError') {
        setState({
          validationErrs: { ...error.inner, hasError: true },
        });
      }
      return false;
    }
  };

  const manageRejection = async () => {
    await updateLearnerReferral(
      {
        learnerUserId: id,
        status: referralStatuses.REJECTED,
      },
      {
        onError: (error) => {
          setState({
            validationErrs:
              error?.data?.field === 'email'
                ? {
                    ...validationErrs,
                    email: error.message,
                  }
                : { ...validationErrs },
          });
        },
      }
    );

    await deleteUser({
      id,
      role: userRoles.LEARNER,
    });

    setState({ rejectSuccessModalVisible: true });
  };

  const updateLearner = async (_status) => {
    /*
     * change status only if it's not a referral
     * or if status has been changed to approved
     **/
    const status =
      (id && !isReferral) || !_status ? undefined : referralStatuses.APPROVED;

    await updateLearnerReferral(
      {
        learnerUserId: id,
        ...state.form,
        status,
        email: cleanEmail(email),
      },
      {
        onError: (error) => {
          setState({
            validationErrs:
              error?.data?.field === 'email'
                ? {
                    ...validationErrs,
                    email: error.message,
                  }
                : { ...validationErrs },
          });
        },
      }
    );

    if (status) {
      navigate(COMMON.LEARNER.replace(':userId', id));
    }
  };

  const onCreateLearner = async () => {
    createLearner(
      {
        ...state.form,
        email: cleanEmail(email),
      },
      {
        onSuccess: (data) => {
          navigate(COMMON.LEARNER.replace(':userId', data.id));
        },
        onError: (error) => {
          setState({
            validationErrs:
              error?.data?.field === 'email'
                ? {
                    ...validationErrs,
                    email: error.message,
                  }
                : { ...validationErrs },
          });
        },
      }
    );
  };

  const handleUpdate = (e, _status) => {
    e.preventDefault();
    // directly reject user without validating form data
    if (_status === referralStatuses.REJECTED) {
      return manageRejection();
    }

    submitAttempt.current = true;
    const isValid = validateForm();
    if (isValid) {
      updateLearner(_status);
    }
  };

  const handleCreate = (e) => {
    e.preventDefault();
    submitAttempt.current = true;

    const isValid = validateForm();
    if (isValid) {
      onCreateLearner();
    }
  };

  useEffect(() => {
    if (isSuccess) {
      setState({
        form: {
          ...data,
          phoneNumber: data?.phoneNumber?.trim(),
          // If ADMIN viewing/updating a referral we need the organisationId from the learner data
          learnerOrganisationId: data?.organisationId,
        },
      });
    }
  }, [isSuccess, data]);

  if (!id && isOrgInMultipleCourses && !createNewLearner) {
    return (
      <S.Wrapper>
        <BackButton />
        <AlreadyEnrolled />
      </S.Wrapper>
    );
  }

  if (getReferralLoading || getOrgProgressLoading) return <Loading />;

  return (
    <S.Wrapper>
      <BackButton />
      <Row mt="6">
        <Col w={[4, 12, 12]}>
          <T.H1 color="neutralMain" weight="bold" mt={1}>
            Onboard a new learner
          </T.H1>
        </Col>

        <Col w={[4, 12, 12]}>
          <T.H2 color="neutralMain" weight="bold" mt={5}>
            Learner details
          </T.H2>
        </Col>
      </Row>
      <Form
        hiddenFields={
          isWorkingInPrison && hiddenFieldsWhenOrganisationWorkingInPrison
        }
        setFormData={setFormData}
        state={form}
        validationErrs={validationErrs}
      />
      <Row>
        {isNotInPrisonEnvironment && (
          <>
            <Col w={[4, 12, 12]}>
              <T.P
                size="regular"
                color="neutralMain"
                weight="bold"
                ml={2}
                mt={5}
              >
                Further important information{' '}
                <T.P size="small" color="neutral80" display="inline">
                  (optional)
                </T.P>
              </T.P>
            </Col>
            <Col w={[4, 6, 6]} mt="4">
              <Checkbox
                label={
                  <T.P size="small" m="0" ml="1">
                    Requires a female coach
                  </T.P>
                }
                checked={requiredCoachGender === 'FEMALE'}
                handleChange={(checked) =>
                  setFormData({ requiredCoachGender: checked ? 'FEMALE' : '' })
                }
              />
            </Col>
            <Col w={[4, 6, 6]} mt="4">
              <Checkbox
                label={
                  <T.P size="small" m="0" ml="1">
                    Requires a male coach
                  </T.P>
                }
                checked={requiredCoachGender === 'MALE'}
                handleChange={(checked) =>
                  setFormData({ requiredCoachGender: checked ? 'MALE' : '' })
                }
              />
            </Col>
            {validationErrs.requiredCoachGender && (
              <Row>
                <Col w={[4, 12, 12]} mt={3}>
                  <T.P color="error">{validationErrs.requiredCoachGender}</T.P>
                </Col>
              </Row>
            )}
          </>
        )}
        <Col w={[4, 12, 12]} mt="6">
          <Textarea
            label="Any additional notes?"
            placeholder="Additional notes..."
            value={additionalNotes}
            handleChange={(additionalNotes) => {
              setFormData({ additionalNotes });
            }}
            error={validationErrs.additionalNotes}
          />
        </Col>

        {isWorkingInPrison &&
          hiddenFieldsWhenOrganisationWorkingInPrison.includes(
            'referralType'
          ) && (
            <Col w={[4, 6, 6]} mt="6">
              <Dropdown
                label="Type of referral"
                options={referralTypeOptions}
                selected={referralTypeOptions.find(
                  (o) => o.value === referralType
                )}
                handleChange={(referralType) => setFormData({ referralType })}
                error={validationErrs.referralType}
              />
            </Col>
          )}
      </Row>
      {referrer ? (
        <>
          <Row>
            <Col w={[4, 12, 12]}>
              <T.H2 color="neutralMain" weight="bold" mt={5}>
                Referrer details
              </T.H2>
            </Col>
          </Row>
          <Row mt={2}>
            <Col w={[4, 12, 12]}>
              <T.P color="neutralMain" weight="bold">
                Name:
              </T.P>
            </Col>
            <Col w={[4, 12, 12]}>
              <T.P color="neutralMain">
                {referrer.firstName} {referrer.lastName}
              </T.P>
            </Col>
          </Row>
          <Row mt={2}>
            <Col w={[4, 12, 12]}>
              <T.P color="neutralMain" weight="bold">
                Email:
              </T.P>
            </Col>
            <Col w={[4, 12, 12]}>
              <T.P color="neutralMain">{referrer.email}</T.P>
            </Col>
          </Row>
          <Row mt={2}>
            <Col w={[4, 12, 12]}>
              <T.P color="neutralMain" weight="bold">
                Phone number:
              </T.P>
            </Col>
            <Col w={[4, 12, 12]}>
              <T.P color="neutralMain">{referrer.phoneNumber}</T.P>
            </Col>
          </Row>
          <Row mt={2}>
            <Col w={[4, 12, 12]}>
              <T.P color="neutralMain" weight="bold">
                Relationship to learner:
              </T.P>
            </Col>
            <Col w={[4, 12, 12]}>
              <T.P color="neutralMain">{referrer.relationship}</T.P>
            </Col>
          </Row>
        </>
      ) : null}

      <Row mt="6">
        {httpError?.message && (
          <Col w={[4, 12, 12]}>
            <T.P color="error" mb={3}>
              {httpError?.message}
            </T.P>
          </Col>
        )}
        {validationErrs.hasError && (
          <Col w={[4, 12, 12]}>
            <T.P color="error" mb={3}>
              Please fill all the required fields
            </T.P>
          </Col>
        )}
      </Row>
      {/* NEW */}
      {!id && !status && (
        <Row>
          <Col w={[4, 6, 6]}>
            <BasicButton
              variant="primary"
              disabled={validationErrs.hasError}
              loading={submitLoading}
              handleClick={handleCreate}
            >
              <T.P color="white" weight="semi">
                Submit
              </T.P>
            </BasicButton>
          </Col>
        </Row>
      )}
      {/* BUTTON SECTION */}
      {id && (
        <Row mb="6">
          <Col w={[4, 6, 6]}>
            <BasicButton
              variant="primary"
              disabled={validationErrs.hasError}
              loading={submitLoading}
              handleClick={(e) => handleUpdate(e)}
            >
              <T.P color="white" weight="semi">
                Update
              </T.P>
            </BasicButton>
          </Col>
        </Row>
      )}

      {/* NEW REFERRAL */}
      {id && isReferral && status === referralStatuses.PENDING && (
        <Row>
          <Col w={[4, 6, 6]}>
            <BasicButton
              variant="secondary"
              disabled={validationErrs.hasError}
              loading={submitLoading}
              handleClick={() => setState({ rejectModalVisible: true })}
            >
              <T.P color="white" weight="semi">
                Reject
              </T.P>
            </BasicButton>
          </Col>
          <Col w={[4, 6, 6]}>
            <BasicButton
              variant="primary"
              disabled={validationErrs.hasError}
              loading={submitLoading}
              handleClick={(e) => handleUpdate(e, referralStatuses.APPROVED)}
            >
              <T.P color="white" weight="semi">
                Approve
              </T.P>
            </BasicButton>
          </Col>
        </Row>
      )}

      <RejectModal
        visible={rejectModalVisible}
        setIsModalVisible={(visible) =>
          setState({ rejectModalVisible: visible })
        }
        rejectSuccessModalVisible={rejectSuccessModalVisible}
        handleReject={(e) => handleUpdate(e, referralStatuses.REJECTED)}
        loading={submitLoading}
        error={httpError}
      />
      {/* SUCCESS DELETED MODAL */}
      <Modal visible={rejectSuccessModalVisible}>
        <S.ModalContent>
          <T.H1 mb={3} color="neutralSurface">
            Done! 🎉
          </T.H1>
          <T.P mb={3} color="neutralSurface">
            All personal data related to this user was deleted.
          </T.P>
          <BasicButton
            to={FACILITATOR.DASHBOARD}
            linkState={{ refetch: userRoles.LEARNER }}
            variant="secondary"
          >
            <T.P color="white" weight="bold">
              Return to dashboard
            </T.P>
          </BasicButton>
        </S.ModalContent>
      </Modal>
      <Modal visible={!coachesCount} maskColor="rgba(255, 255, 255, 0.5)">
        <T.H2 mb={4} color="quaternaryLight">
          You need a coach first
        </T.H2>
        <T.P mb={4} color="quaternaryLight">
          You need to add a coach before you can add a learner. if you have
          already added a coach, please wait for them to accept your invitation.
        </T.P>
        <BasicButton to={COMMON.ADD_COACHES} variant="secondary">
          <T.P color="neutralSurface" weight="bold">
            Add a coach
          </T.P>
        </BasicButton>
      </Modal>
    </S.Wrapper>
  );
};

export default LearnerReferral;
