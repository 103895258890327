import styled from '@emotion/styled';

export const QuestionContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacings[2]};
  background: ${({ theme }) => theme.colors.neutralLightNew};
  padding: ${({ theme }) => theme.spacings[5]};
  align-items: center;
  margin-bottom: ${({ theme }) => theme.spacings[7]};
`;
