import { useRef } from 'react';
import { BasicInput, Dropdown } from '../../../../../components/Inputs';
import { MediaKeyInput } from '../../../../components/MediaKeyInput';
import {
  mediaTypes,
  pictogramVariations,
  pictogramShapes,
} from '../../../../../constants';
import { useStepForm } from 'CMS/Providers/StepFormProvider';
import { convertSnakeCaseToSpaces } from 'helpers';
import { getDropdownObjectOptions } from 'CMS/utils';
import * as T from '../../../../../components/Typography';
import * as S from '../style';
import { Icon } from 'components';
import { Checkbox } from 'antd';

export { validationSchema } from './validation';

const initialData = {
  variation: pictogramVariations.variations.FILL_IN_SHAPES,
  textAudio: '',
  textAudioKey: '',
  keyValue: 1,
  keyName: '',
  shape: pictogramShapes.CIRCLE,
  numberOfShapes: 0,
  shapes: [{ id: 0, isSelected: false }],
  total: 0,
  tally: 0,
  tallyOptions: [{ id: 0, value: false, isCorrect: false }],
};

const Pictogram = () => {
  const initialRef = useRef(initialData);
  const { state, updateDataFields, updateFormMetaData } = useStepForm(
    initialRef.current
  );
  const { data = initialData, validationErrs } = state;

  const handleNumberOfShapesChange = ({ numberOfShapes = 1 }) => {
    if (numberOfShapes < 1 || numberOfShapes > 64) {
      updateFormMetaData({
        validationErrs: {
          ...validationErrs,
          numberOfShapes:
            numberOfShapes < 1
              ? 'Total must be more than 1'
              : numberOfShapes > 64
              ? 'Total shapes rows maximum is 8 rows'
              : '',
        },
      });
      updateDataFields({ numberOfShapes });

      return;
    }

    const newShapes = Array.from({ length: numberOfShapes }).map(
      (_, shapeIndex) => ({
        id: shapeIndex,
        isSelected: data.shapes?.[shapeIndex]?.isSelected ?? false,
        shapeIndex,
      })
    );

    updateDataFields({
      numberOfShapes,
      shapes: newShapes,
    });
    updateFormMetaData({
      validationErrs: {
        ...validationErrs,
        numberOfShapes: '',
      },
    });
  };

  const handleTotalOrTallyChange = ({ key = 'total', value = 1 }) => {
    const maxTotalOrTallyValue = data.numberOfShapes * data.keyValue;
    if (value < 1 || value > maxTotalOrTallyValue) {
      updateFormMetaData({
        validationErrs: {
          ...validationErrs,
          [key]: `${key} cannot be more less than 1 or more than the maximum possible value`,
        },
      });
      return updateDataFields({ [key]: value });
    }

    updateDataFields({
      [key]: value,
    });
    updateFormMetaData({
      validationErrs: {
        ...validationErrs,
        [key]: '',
      },
    });
  };

  const handleKeyValueChange = ({ keyValue }) => {
    if (keyValue < 1) {
      updateFormMetaData({
        validationErrs: {
          ...validationErrs,
          keyValue: `Key value cannot be less than 1`,
        },
      });
      return updateDataFields({ keyValue });
    }

    updateDataFields({
      keyValue,
    });
    updateFormMetaData({
      validationErrs: {
        ...validationErrs,
        keyValue: '',
      },
    });
  };

  const shapeOptions = getDropdownObjectOptions(pictogramShapes);

  const variationOptions = getDropdownObjectOptions(
    pictogramVariations.variations
  );

  const onTallyOptionChange = (value, id) => {
    updateDataFields({
      tallyOptions: data.tallyOptions.map((to) => {
        if (to.id !== id) return to;
        return { ...to, value };
      }),
    });
  };

  const addTallyOption = () => {
    const maxId = Math.max(...data.tallyOptions.map((e) => e.id), 0);
    updateDataFields({
      tallyOptions: [
        ...data.tallyOptions,
        { id: maxId + 1, value: 0, isCorrect: false },
      ],
    });
  };

  const onIsCorrectChange = (isCorrect, id) => {
    updateDataFields({
      tallyOptions: data.tallyOptions.map((to) => {
        if (to.id !== id) return { ...to, isCorrect: false };
        return { ...to, isCorrect };
      }),
    });
  };

  return (
    <div>
      <Dropdown
        label="Choose a variation"
        options={variationOptions}
        selected={
          data.variation
            ? {
                value: data.variation,
                label: convertSnakeCaseToSpaces(data.variation),
              }
            : null
        }
        handleChange={(variation) => updateDataFields({ variation })}
        error={validationErrs?.variation}
      />

      <BasicInput
        label="Explainer Text"
        value={data.textAudio}
        handleChange={(textAudio) => updateDataFields({ textAudio })}
        m={{ mt: 8 }}
        error={validationErrs?.textAudio}
      />
      <MediaKeyInput
        type={mediaTypes.TEXT_AUDIO}
        label="Explainer Text file Key"
        value={data.textAudioKey}
        handleChange={(textAudioKey) => updateDataFields({ textAudioKey })}
        m={{ mt: 5 }}
        error={validationErrs?.textAudioKey}
      />

      <BasicInput
        label="Key Name"
        value={data.keyName}
        handleChange={(keyName) => updateDataFields({ keyName })}
        m={{ mt: 8 }}
        error={validationErrs?.keyName}
      />

      <BasicInput
        label="Key Value"
        value={data.keyValue}
        handleChange={(keyValue) => handleKeyValueChange({ keyValue })}
        m={{ mt: 8 }}
        type="number"
        error={validationErrs?.keyValue}
      />

      <Dropdown
        label="Choose a shape"
        options={shapeOptions}
        selected={
          data.shape
            ? {
                value: data.shape,
                label: convertSnakeCaseToSpaces(data.shape),
              }
            : initialData.type
        }
        handleChange={(shape) => updateDataFields({ shape })}
        error={validationErrs?.shape}
        m={{ mt: 5 }}
      />

      <BasicInput
        label="Number Of Shapes"
        value={data.numberOfShapes}
        handleChange={(numberOfShapes) => {
          handleNumberOfShapesChange({
            numberOfShapes: Number(numberOfShapes),
          });
        }}
        m={{ mt: 8 }}
        type="number"
        error={validationErrs.numberOfShapes}
      />
      {(data.variation === pictogramVariations.variations.FILL_IN_SHAPES ||
        data.variation ===
          pictogramVariations.variations.FILL_IN_THE_TOTAL) && (
        <BasicInput
          label="Total"
          value={data.total}
          handleChange={(total) => {
            handleTotalOrTallyChange({
              key: 'total',
              value: Number(total),
            });
          }}
          m={{ mt: 8 }}
          type="number"
          error={validationErrs.total}
        />
      )}
      {data.variation ===
        pictogramVariations.variations.FILL_IN_SHAPES_FROM_TALLY && (
        <BasicInput
          label="Tally"
          value={data.tally}
          handleChange={(tally) => {
            handleTotalOrTallyChange({
              key: 'tally',
              value: Number(tally),
            });
          }}
          m={{ mt: 8 }}
          type="number"
          error={validationErrs.tally}
        />
      )}

      {data.variation === pictogramVariations.variations.SELECT_TALLY && (
        <>
          <T.P mt={8} size="large" weight="bold">
            Tally Options
          </T.P>
          {validationErrs?.tallyOptions &&
            typeof validationErrs.tallyOptions === 'string' && (
              <T.P mb={2} color="error">
                {validationErrs.tallyOptions}
              </T.P>
            )}

          {data.tallyOptions.map((to, i) => (
            <>
              <BasicInput
                key={to.id}
                label={`Tally Option ${i + 1}`}
                value={to.value}
                handleChange={(val) => {
                  onTallyOptionChange(Number(val), to.id);
                }}
                m={{ mt: 8 }}
                type="number"
                error={
                  validationErrs?.tallyOptions &&
                  validationErrs?.tallyOptions[i]?.option
                }
              />
              <Checkbox
                checked={to.isCorrect}
                onChange={(value) =>
                  onIsCorrectChange(value.target.checked, to.id)
                }
                disabled={!to.value}
              >
                correct option?
              </Checkbox>
            </>
          ))}
          <S.Button
            type="link"
            ghost
            mt={5}
            mb={5}
            onClick={addTallyOption}
            disabled={data.tallyOptions.find((e) => !e.value)}
            ml="auto"
            mr="auto"
          >
            <Icon icon="plus" /> Add Option
          </S.Button>
        </>
      )}
    </div>
  );
};

export default Pictogram;
