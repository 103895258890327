import styled from '@emotion/styled';
import { Link as RouterLink } from 'react-router-dom';
import setMargin from '../../helpers/set-margin';
import { Typography } from 'antd';
const { Title, Paragraph } = Typography;

const weights = {
  bold: 'bold !important',
  regular: 'normal !important',
};

const fontSizes = {
  extraLarge: '5rem !important', // 80px
  xxLarge: '3rem !important', // 48px
  larger: '2.5rem !important', // 40px
  xLarge: '1.875rem !important', // 30px
  large: '1.75rem!important', // 28px
  medLarge: '1.5rem !important', // 24px
  med: '1.25rem !important', // 20px
  regular: '1rem !important', // 16px
  small: '0.875rem !important', // 14px
};

const lineHeights = {
  extraLarge: '120% !important',
  larger: '120% !important',
  large: '120% !important',
  medLarge: '120% !important',
  med: '120% !important',
  regular: '150% !important',
  small: '150% !important',
};

const commonStyle = ({ theme, color, caps, ta, fontFamily, display }) => `
font-style: normal !important;
letter-spacing: 0.2px !important;
font-family: ${
  fontFamily === 'garamond' ? 'BasicCommercial' : 'BasicCommercial'
};
color: ${theme.colors[color] || color || theme.colors.black} !important;
text-transform: ${caps ? 'uppercase' : 'initial'} !important;
text-align: ${ta || 'left'} !important;
${display ? `display: ${display} !important;` : ''}
${display === 'block' ? 'width: 100% !important;' : ''};
`;

const Head1 = styled(Title)`
  ${setMargin};
  ${commonStyle};
  font-size: ${({ size }) => (size ? fontSizes[size] : '1.75rem!important')};
  line-height: ${({ size }) =>
    size ? lineHeights[size] : '33.6px !important'};
  font-weight: ${({ weight }) => (weight ? weights[weight] : '700 !important')};
`;
export const H1 = (props) => <Head1 {...props} level={1} />;

const Head2 = styled(Title)`
  ${setMargin};
  ${commonStyle};
  font-size: ${({ size }) => (size ? fontSizes[size] : '1.25rem !important')};
  line-height: ${({ size }) => (size ? lineHeights[size] : '30px !important')};
  font-weight: ${({ weight }) => (weight ? weights[weight] : '600 !important')};
`;
export const H2 = (props) => <Head2 {...props} level={2} />;

const Head3 = styled(Title)`
  ${setMargin};
  ${commonStyle};
  font-size: ${({ size }) => (size ? fontSizes[size] : '1rem!important')};
  line-height: ${({ size }) => (size ? lineHeights[size] : '24px !important')};
  font-weight: ${({ weight }) => (weight ? weights[weight] : '600 !important')};
  text-decoration: ${({ td }) => td || 'none'};
`;
export const H3 = (props) => <Head3 {...props} level={3} />;

export const P = styled((props) => <Paragraph {...props} />)`
  ${setMargin};
  ${commonStyle};
  color: ${({ color, theme }) => (color ? theme.colors[color] : '')};
  font-size: ${({ size }) => (size ? fontSizes[size] : '1rem !important')};
  line-height: ${({ size, lh }) =>
    lh || (size ? lineHeights[size] : '24px !important')};
  font-weight: ${({ weight }) => (weight ? weights[weight] : '400 !important')};
  text-decoration: ${({ textDecoration }) =>
    textDecoration ? textDecoration : 'none'};

  /* width: 100%; */
  word-break: normal;
  padding: ${({ padding }) => padding || 0};
  max-width: ${({ maxWidth }) => maxWidth || '100%'};
  pre {
    background: none;
    border: none;
    padding: 0;
    margin: 0;
  }
`;

export const Pre = styled((props) => <pre {...props} />)`
  ${setMargin};
  ${commonStyle};
  font-size: ${({ size }) => (size ? fontSizes[size] : '1rem !important')};
  line-height: ${({ size }) => (size ? lineHeights[size] : '24px !important')};
  font-weight: ${({ weight }) => (weight ? weights[weight] : '400 !important')};

  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
`;
export const Ul = styled((props) => <ul {...props} />)`
  ${setMargin};
  ${commonStyle};
  font-size: ${({ size }) => (size ? fontSizes[size] : '1rem!important')};
  line-height: ${({ size }) => (size ? lineHeights[size] : '24px !important')};
  font-weight: ${({ weight }) => (weight ? weights[weight] : '400 !important')};
`;

export const Li = styled((props) => <li {...props} />)`
  ${setMargin};
  ${commonStyle};
  font-size: ${({ size }) => (size ? fontSizes[size] : '1rem !important')};
  line-height: ${({ size }) => (size ? lineHeights[size] : '24px !important')};
  font-weight: ${({ weight }) => (weight ? weights[weight] : '400 !important')};
  list-style: ${({ listStyle }) => (listStyle ? listStyle : 'none')};
`;

const AntdLink = ({
  to,
  external = false,
  underline,
  target = '_blank',
  ...props
}) => {
  return external ? (
    <Typography.Link target={target} href={to} {...props} />
  ) : (
    <RouterLink to={to} {...props}>
      {props.children}
    </RouterLink>
  );
};
export const Link = styled(AntdLink)`
  ${setMargin};
  ${commonStyle};
  color: ${({ color, theme }) =>
    color ? theme.colors[color] : theme.colors['neutralMain']} !important;
  font-size: 1rem !important;
  line-height: 24px !important;
  font-weight: ${({ weight }) =>
    weight ? weights[weight] : 'bold !important'};
  text-decoration: ${({ underline }) =>
    underline ? 'underline' : 'none'} !important;

  pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'auto')};
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
  opacity: ${({ isDisabled }) => (isDisabled ? '0.5' : '1')};

  :hover {
    color: ${({ theme, hoverColor }) =>
      hoverColor
        ? theme.colors[hoverColor] + '!important'
        : theme.colors.primaryMain + '!important'};
  }

  :focus {
    outline-style: solid;
    outline-width: 3px;
    outline-color: ${({ theme }) => theme.colors.primaryMain};
    outline-offset: 0.25em;
  }

  ${({ ellipses }) =>
    ellipses
      ? `
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
      `
      : ''}
`;
