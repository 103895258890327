import { wheelOperationTypes } from 'constants';
import { string, array, number, mixed, boolean } from 'yup';

export const validationSchema = {
  textAudio: string(),
  textAudioKey: string(),
  operationType: mixed()
    .oneOf(Object.values(wheelOperationTypes))
    .required('Operation type is required'),
  centerNumber: number()
    .required('Center number is required')
    .typeError('Center number must be a number'),
  innerNumbers: array()
    .of(string())
    .required('Inner numbers are required')
    .test(
      'match-length',
      'Inner and outer numbers must have the same length',
      function (innerNumbers) {
        const outerNumbers = this.parent.outerNumbers;
        return (
          outerNumbers &&
          innerNumbers &&
          innerNumbers.length === outerNumbers.length
        );
      }
    ),
  hideInnerNumbers: boolean(),
  outerNumbers: array()
    .of(string())
    .required('Outer numbers are required')
    .test(
      'match-length',
      'Inner and outer numbers must have the same length',
      function (innerNumbers) {
        const outerNumbers = this.parent.outerNumbers;
        return (
          outerNumbers &&
          innerNumbers &&
          innerNumbers.length === outerNumbers.length
        );
      }
    ),
  hideOuterNumbers: boolean().test(
    'at-least-one-hidden',
    'Either Inner Numbers or Outer Numbers must be hidden',
    function () {
      const { hideInnerNumbers, hideOuterNumbers } = this.parent;
      return hideInnerNumbers || hideOuterNumbers;
    }
  ),
};
