import { fractionOperationTypes } from '../../../../../constants';
import { string, array, object, number, boolean } from 'yup';

export const validationSchema = {
  textAudio: string().required('Explainer Text is required'),
  textAudioKey: string().required('Explainer Text file key is required'),
  numberOfCircles: number()
    .required('Number of circles is required')
    .integer('Number of circles must be an integer')
    .positive('Number of circles must be greater than 0')
    .test(
      'min-circle-count',
      'Circles must be more than 1',
      (value) => value >= 1
    )
    .test(
      'max-circle-count',
      'Circles rows maximum is 8 rows',
      (value) => value <= 64
    ),
  circles: array()
    .of(
      object({
        id: number().required('Circle ID is required'),
        isSelected: boolean().required('Circle selection status is required'),
      })
    )
    .required('Circles array is required')
    .min(1, 'At least one circle is required'),
  fractionOperationType: string()
    .oneOf(Object.values(fractionOperationTypes), 'required field')
    .required('required field'),
  topNumber: number().when('fractionOperationType', {
    is: fractionOperationTypes.FRACTION_OF,
    then: number().required('Top number is required'),
    otherwise: number().nullable(),
  }),
  bottomNumber: number().when('fractionOperationType', {
    is: fractionOperationTypes.FRACTION_OF,
    then: number().required('Bottom number is required'),
    otherwise: number().nullable(),
  }),
  decimalNumber: number().when('fractionOperationType', {
    is: fractionOperationTypes.DECIMAL_OF,
    then: number()
      .required('Decimal number is required')
      .typeError('Decimal number must be a valid number'),
    otherwise: number().nullable(),
  }),
  fractionOfNumber: number()
    .required('Fraction of number is required')
    .typeError('Fraction of number must be a valid number'),
  correctAnswer: number()
    .required('Correct answer is required')
    .typeError('Correct answer must be a valid number'),
};
