import {
  useFetch,
  usePost,
  usePatch,
  formatQueryError,
} from '../utils/queries';
import { COACHES_BASE } from '../constants';

function useAddCoaches(_, options = {}) {
  return usePost({
    url: `${COACHES_BASE}`,
    reactQueryConfig: { ...options, invalidateKeys: () => [['COACHES']] },
  });
}

const useAddCoachesInvites = (_, options = {}) => {
  const context = usePost({
    url: `${COACHES_BASE}/invites`,
    reactQueryConfig: {
      ...options,
      invalidateKeys: () => [['COACHES']],
    },
  });
  return {
    ...context,
    error: formatQueryError(context),
  };
};

function useUpdateCoachInfo(_, options = {}) {
  return usePatch({
    url: `${COACHES_BASE}/update-coach-info`,
    reactQueryConfig: {
      ...options,
      invalidateKeys: () => [['COACHES']],
    },
  });
}

function useUpdateCoach(coachId, options = {}) {
  return usePatch({
    url: `${COACHES_BASE}/${coachId}/update/`,
    reactQueryConfig: {
      ...options,
      invalidateKeys: () => [['COACHES']],
    },
  });
}

function useGetCoachDetails({ coachId }, options = {}) {
  const context = useFetch({
    key: ['COACHES', { coachId }],
    url: `${COACHES_BASE}/${coachId}/details`,
    reactQueryConfig: options,
  });
  return {
    ...context,
    coach: context.data,
    error: formatQueryError(context),
  };
}

function useGetCoachesCount({ searchValue = '' }, options = {}) {
  const context = useFetch({
    key: ['COACHES_COUNT', { searchValue }],
    url: `${COACHES_BASE}/count`,
    axiosParams: { search: searchValue },
    reactQueryConfig: options,
  });
  return {
    ...context,
    coachesCount: context.data,
    error: formatQueryError(context),
  };
}

function useGetRecruitmentFormAnswers({ coachId }, options = {}) {
  const context = useFetch({
    key: [`RECRUITMENT_FORM_ANSWERS`, { coachId }],
    url: `${COACHES_BASE}/${coachId}/recruitment-form-answers`,
    reactQueryConfig: options,
  });
  return {
    ...context,
    error: formatQueryError(context),
  };
}

function useGetCoaches({ params }, options = {}) {
  const context = useFetch({
    key: ['COACHES', params],
    url: `${COACHES_BASE}/`,
    axiosParams: params,
    reactQueryConfig: options,
  });

  return {
    ...context,
    coaches: context.data,
    error: formatQueryError(context),
  };
}

function useUpdateCoachDBS(_, options = {}) {
  return usePatch({
    url: `${COACHES_BASE}/dbs`,
    reactQueryConfig: {
      ...options,
      invalidateKeys: () => [['COACHES']],
    },
  });
}

function useGetCoachNotes({ coachId }, options = {}) {
  const context = useFetch({
    key: [`COACH_NOTES`, { coachId }],
    url: `${COACHES_BASE}/notes/`,
    axiosParams: { coachId },
    reactQueryConfig: options,
  });
  return {
    ...context,
    notes: context.data,
    error: formatQueryError(context),
  };
}

function useAddCoachNote(_, options = {}) {
  return usePost({
    url: `${COACHES_BASE}/notes`,
    reactQueryConfig: {
      ...options,
      invalidateKeys: ({ coachId }) => [[`COACH_NOTES`, { coachId }]],
    },
  });
}

function useUpdateCoachApprovalStatus(_, options = {}) {
  return usePatch({
    getUrl: ({ coachId }) => `${COACHES_BASE}/${coachId}/approval-status`,
    reactQueryConfig: {
      ...options,
      invalidateKeys: () => [['COACHES']],
    },
  });
}

function useUpdateCoachCompletedTrainingDay(_, options = {}) {
  return usePatch({
    getUrl: ({ coachId }) => `${COACHES_BASE}/${coachId}/update`,
    reactQueryConfig: {
      ...options,
      invalidateKeys: () => [['COACHES']],
    },
  });
}

const useGetCoachStuckAtLearners = (_, options = {}) => {
  const context = useFetch({
    key: [`STUCK_AT_LEARNERS`],
    url: `${COACHES_BASE}/stuck-at-learners`,
    reactQueryConfig: options,
  });

  return {
    ...context,
    learnersStuckAt: context.data,
    error: formatQueryError(context),
  };
};

const useSignup = (_, options = {}) => {
  const context = usePost({
    url: `${COACHES_BASE}/signup`,
    reactQueryConfig: options,
  });

  return {
    ...context,
    error: formatQueryError(context),
  };
};

const useGetCoachBySignupToken = ({ token }, options = {}) => {
  const context = useFetch({
    key: [`COACH_SIGNUP_TOKEN`, { token }],
    url: `${COACHES_BASE}/signup-token/${token}`,
    reactQueryConfig: options,
  });

  return {
    ...context,
    error: formatQueryError(context),
  };
};

const useGetCompletedTrainingStepById = ({ id }, options = {}) => {
  const context = useFetch({
    key: [`GET_COMPLETED_TRAINING_STEP_BY_ID`, { id }],
    url: `${COACHES_BASE}/${id}/training-step`,
    reactQueryConfig: options,
  });

  return {
    ...context,
    error: formatQueryError(context),
  };
};

const useAddCoachForPrison = (_, options = {}) => {
  const context = usePost({
    url: `${COACHES_BASE}/for-prison`,
    reactQueryConfig: {
      ...options,
      invalidateKeys: () => [['COACHES']],
    },
  });
  return {
    ...context,
    error: formatQueryError(context),
  };
};

const useUpdateCoachPassword = (_, options = {}) => {
  const context = usePost({
    url: `${COACHES_BASE}/update-password`,
    reactQueryConfig: options,
  });
  return { ...context, error: formatQueryError(context) };
};

export {
  useAddCoaches,
  useAddCoachesInvites,
  useUpdateCoachInfo,
  useUpdateCoach,
  useGetCoachDetails,
  useGetCoachesCount,
  useGetRecruitmentFormAnswers,
  useGetCoaches,
  useUpdateCoachDBS,
  useGetCoachNotes,
  useAddCoachNote,
  useUpdateCoachApprovalStatus,
  useUpdateCoachCompletedTrainingDay,
  useGetCoachStuckAtLearners,
  useSignup,
  useGetCoachBySignupToken,
  useGetCompletedTrainingStepById,
  useAddCoachForPrison,
  useUpdateCoachPassword,
};
