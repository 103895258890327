import { useRef } from 'react';
import { BasicInput, Dropdown } from '../../../../../components/Inputs';
import { MediaKeyInput } from '../../../../components/MediaKeyInput';
import { mediaTypes, wheelOperationTypes } from '../../../../../constants';
import { useStepForm } from 'CMS/Providers/StepFormProvider';
import { getDropdownObjectOptions } from 'CMS/utils';
import { convertSnakeCaseToSpaces } from 'helpers';
import { Checkbox } from 'antd';
import * as T from '../../../../../components/Typography';

export { validationSchema } from './validation';

const initialData = {
  textAudio: '',
  textAudioKey: '',
  operationType: wheelOperationTypes.MULTIPLICATION,
  centerNumber: 0,
  innerNumbers: [],
  hideInnerNumbers: false,
  outerNumbers: [],
  hideOuterNumbers: true,
};

const Wheel = () => {
  const initialRef = useRef(initialData);
  const { state, updateDataFields, updateFormMetaData } = useStepForm(
    initialRef.current
  );
  const { data = initialData, validationErrs } = state;

  const handleNumbersChange = ({ innerNumbers, outerNumbers }) => {
    if (innerNumbers.length !== outerNumbers.length) {
      updateFormMetaData({
        validationErrs: {
          ...validationErrs,
          innerNumbers: 'Inner and outer numbers must have the same length',
          outerNumbers: 'Inner and outer numbers must have the same length',
        },
      });
      updateDataFields({ innerNumbers, outerNumbers });

      return;
    }

    updateDataFields({
      innerNumbers,
      outerNumbers,
    });
    updateFormMetaData({
      validationErrs: {
        ...validationErrs,
        innerNumbers: '',
        outerNumbers: '',
      },
    });
  };

  return (
    <div>
      <BasicInput
        label="Explainer Text"
        value={data.textAudio}
        handleChange={(textAudio) => updateDataFields({ textAudio })}
        m={{ mt: 8 }}
        error={validationErrs?.textAudio}
      />
      <MediaKeyInput
        type={mediaTypes.TEXT_AUDIO}
        label="Explainer Text file Key"
        value={data.textAudioKey}
        handleChange={(textAudioKey) => updateDataFields({ textAudioKey })}
        m={{ mt: 5 }}
        error={validationErrs?.textAudioKey}
      />

      <Dropdown
        label="Choose a formula type"
        options={getDropdownObjectOptions(wheelOperationTypes)}
        selected={
          data.operationType
            ? {
                value: data.operationType,
                label: convertSnakeCaseToSpaces(data.operationType),
              }
            : initialData.operationType
        }
        handleChange={(operationType) => updateDataFields({ operationType })}
        error={validationErrs?.operationType}
        m={{ mt: 5 }}
      />

      <BasicInput
        label="Center Number"
        value={data.centerNumber}
        handleChange={(centerNumber) => updateDataFields({ centerNumber })}
        m={{ mt: 8 }}
        type="number"
        error={validationErrs.centerNumber}
      />

      <BasicInput
        label="Inner Numbers"
        value={data.innerNumbers?.join(',') ?? ''}
        handleChange={(innerNumbers) =>
          handleNumbersChange({
            innerNumbers: innerNumbers.split(','),
            outerNumbers: data.outerNumbers,
          })
        }
        m={{ mt: 8, mb: 2 }}
        error={validationErrs.innerNumbers}
        helper={`Add the numbers to the inner circle separated by "," i.e 1,2,3,4`}
      />

      <Checkbox
        checked={data.hideInnerNumbers}
        onChange={(e) =>
          updateDataFields({ hideInnerNumbers: e.target.checked })
        }
        disabled={data.hideOuterNumbers}
      >
        hide
      </Checkbox>

      <BasicInput
        label="Outer Numbers"
        value={data.outerNumbers?.join(',') ?? ''}
        handleChange={(outerNumbers) =>
          handleNumbersChange({
            outerNumbers: outerNumbers.split(','),
            innerNumbers: data.innerNumbers,
          })
        }
        m={{ mt: 8, mb: 2 }}
        error={validationErrs.outerNumbers}
        helper={`Add the numbers to the inner circle separated by "," i.e 1,2,3,4`}
      />

      <Checkbox
        checked={data.hideOuterNumbers}
        onChange={(e) =>
          updateDataFields({ hideOuterNumbers: e.target.checked })
        }
        disabled={data.hideInnerNumbers}
      >
        hide
      </Checkbox>

      {validationErrs?.hideOuterNumbers &&
        typeof validationErrs.hideOuterNumbers === 'string' && (
          <T.P mb={2} color="error">
            {validationErrs.hideOuterNumbers}
          </T.P>
        )}
    </div>
  );
};

export default Wheel;
