import { useReducer, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { Typography as T, Inputs as I, Button } from '../../../components';
import * as S from './style';
import validate from '../../../validation/schemas/verify-2fa-code';
import { Users } from '../../../api-calls';
import { navRoutes as R, userRoles } from '../../../constants';
import { useAuth } from '../../../context/auth';
import ReactGA from 'react-ga4';
import { useSiteSettings } from 'context/site-settings';

const initialState = {
  code: '',
  timer: 60,
  validationErrs: {},
};

function reducer(state, newState) {
  return { ...state, ...newState };
}

const TowFactorAuth = () => {
  const [state, setState] = useReducer(reducer, initialState);
  const { code, timer, validationErrs } = state;
  const navigate = useNavigate();
  const { setUser } = useAuth();
  const {
    mutateAsync: verify2FACode,
    error: verifyErr,
    isLoading: verifyLoading,
  } = Users.useVerify2FACode();
  const {
    mutateAsync: resend2FACode,
    error: resendErr,
    isLoading: resendLoading,
  } = Users.useResend2FACode();
  const { loaded: siteSettingsLoaded, isWorkingInPrisonEnvironment } =
    useSiteSettings();

  useEffect(() => {
    if (code) {
      validateForm();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  const validateForm = () => {
    try {
      validate({
        code,
      });
      setState({ validationErrs: {} });
      return true;
    } catch (error) {
      if (error.name === 'ValidationError') {
        setState({ validationErrs: error.inner });
      }
      return false;
    }
  };

  const handleSubmit = async (code, reToken) => {
    verify2FACode(
      { reToken, code },
      {
        onSuccess: (data) => {
          setUser(data);

          if (data.role !== userRoles.ADMIN && ReactGA.isInitialized) {
            ReactGA.event({
              category: 'login',
              action: 'login',
            });
            ReactGA.set({
              ...data,
            });
          }

          let redirectPath = '';
          let routeState = {};
          const isMultiOneRole =
            !data.role && data?.organisationsRoles?.length === 1;

          if (isMultiOneRole || data?.organisationsRoles?.length > 1) {
            redirectPath = R.COMMON.SELECT_ORGANISATION;
          } else if (data.role === userRoles.CONTENT_EDITOR) {
            redirectPath = R.CMS.BASE;
          } else if (data.role === userRoles.ADMIN) {
            redirectPath = R.ADMIN.DASHBOARD;
          } else if (data.role === userRoles.ORGANISATION_ADMIN) {
            redirectPath = R.ORGANISATION_ADMIN.DASHBOARD;
          } else {
            // COACH or FACILITATOR
            redirectPath = R.COMMON.SPLASH_SCREEN;
            routeState = {
              role: data.role,
            };
          }

          navigate(redirectPath, { state: routeState });
        },
      }
    );
  };

  const submit = () => {
    const inNotPrisonEnvironment =
      siteSettingsLoaded && !isWorkingInPrisonEnvironment;
    if (inNotPrisonEnvironment && process.env.NODE_ENV === 'production') {
      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, {
            action: 'verify_2fa_code',
          })
          .then((reToken) => {
            handleSubmit(code, reToken);
          });
      });
    } else {
      handleSubmit(code);
    }
  };

  const onContinue = (e) => {
    e.preventDefault();
    const isValid = validateForm();

    if (isValid) {
      submit();
    }
  };

  const requestNewCode = async () => {
    setState({ loading: true });

    const inNotPrisonEnvironment =
      siteSettingsLoaded && !isWorkingInPrisonEnvironment;
    if (inNotPrisonEnvironment && process.env.NODE_ENV === 'production') {
      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, {
            action: 'request_new_code',
          })
          .then(async (reToken) => {
            await resend2FACode({ reToken });
            setState({ loading: false, timer: 60 });
          });
      });
    } else {
      await resend2FACode({});
      setState({ loading: false, timer: 60 });
    }
  };

  // update the timer every second
  const timerRef = useRef();
  useEffect(() => {
    if (timer > 0) {
      timerRef.current = setTimeout(() => {
        setState({ timer: timer - 1 });
      }, 1000);
    } else {
      clearTimeout(timerRef.current);
    }

    return () => clearTimeout(timerRef.current);
  }, [timer]);

  const loading = verifyLoading || resendLoading;
  const httpError = verifyErr || resendErr;

  return (
    <S.Form onSubmit={onContinue}>
      <T.H1 weight="bold" color="neutralMain" mb={6}>
        Two-Factor Authentication
      </T.H1>

      <T.P mt="2" mb="6">
        Your account is protected with two-factor authentication. Please enter
        the code sent to your email.
      </T.P>

      <I.BasicInput
        id="2fa-code"
        label="Two-Factor Code"
        placeholder="Enter your 6-digit code"
        type="text"
        value={code}
        autoFocus
        handleChange={(input) => setState({ code: input })}
        error={validationErrs.code}
      />

      <S.ButtonsWrapper mt="7" mtT="6">
        {httpError && (
          <T.P mb="2" color="error">
            {httpError.message}
          </T.P>
        )}
        <Button.BasicButton
          variant="primary"
          disabled={false}
          loading={loading}
          type="submit"
          id="create-new-password"
        >
          Continue
        </Button.BasicButton>
      </S.ButtonsWrapper>
      <T.P mt="4">
        If you didn't receive a code, please check your spam folder or{' '}
        <S.LinkButton
          onClick={requestNewCode}
          disabled={timer > 0}
          type="reset"
        >
          request a new one
        </S.LinkButton>
        {timer > 0 && (
          <T.P color="neutralMain" display="inline" ml="1">
            ({timer}s)
          </T.P>
        )}
      </T.P>
    </S.Form>
  );
};

export default TowFactorAuth;
