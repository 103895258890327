// COMMON INPUT STYLES
import styled from '@emotion/styled';
import setMargin from '../../helpers/set-margin';

export const commonStyle = `
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 150%;
`;

export const commonBorderStyle = ({ theme, error, borderColor }) => `
  border: solid 1px ${
    error
      ? theme.colors.error
      : !borderColor
      ? theme.colors.neutral50
      : theme.colors[borderColor] || borderColor
  };
  border-radius: ${theme.borders.radius};
`;

export const placeholderStyle = ({ theme, placeholderColor }) => `

  ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    ${commonStyle};
    font-family: 'BasicCommercial', sans-serif;
    color: ${
      placeholderColor
        ? theme.colors[placeholderColor] || placeholderColor
        : theme.colors.neutral80
    };
  }
  ::-moz-placeholder { /* Firefox 19+ */
    ${commonStyle};
    font-family: 'BasicCommercial', sans-serif;
    color: ${
      placeholderColor
        ? theme.colors[placeholderColor] || placeholderColor
        : theme.colors.neutral80
    };
  }
  :-ms-input-placeholder { /* IE 10+ */
    ${commonStyle};
    font-family: 'BasicCommercial', sans-serif;
    color: ${
      placeholderColor
        ? theme.colors[placeholderColor] || placeholderColor
        : theme.colors.neutral80
    };
  }
  :-moz-placeholder { /* Firefox 18- */
    ${commonStyle};
    font-family: 'BasicCommercial', sans-serif;
    color:${
      placeholderColor
        ? theme.colors[placeholderColor] || placeholderColor
        : theme.colors.neutral80
    };
  }
`;

export const Field = styled.div`
  ${setMargin};
  width: ${({ w }) => w || '100%'};
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
`;

export const Label = styled.label`
  ${setMargin};
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-left: 8px;
`;

export const OptionalTag = styled.span`
  ${setMargin};
  display: inline-block;
  font-weight: 400 !important;
  color: ${({ theme }) => theme.colors.neutral80};
`;

export const RadioGroupWrapper = styled.div`
  padding-left: ${({ theme }) => theme.spacings[2]};
`;
