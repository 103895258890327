import { useEffect, useState } from 'react';

import * as T from '../../../../components/Typography';
import { PlayButton } from '../../../../components/Button';
import { useLocation } from 'react-router-dom';
import StepFooterButtons from '../StepFooterButtons';

import * as S from './style';
import * as SharedStyles from '../shared/style';
import { pictogramVariations } from '../../../../constants';
import Tally from './Tally';
import {
  checkIsValidSelection,
  getCorrectShape,
  getSelectedShapes,
} from './utils';

const Pictogram = ({
  data: {
    variation,
    textAudio,
    textAudioUrl,
    keyValue,
    keyName,
    numberOfShapes,
    shape,
    shapes: _shapes = [],
    total,
    tally,
    tallyOptions,
  } = {},
  handleNext,
  stuckAtReview,
  disabled,
  preview,
}) => {
  const [enableNext, setEnableNext] = useState(false);
  const [shapes, setShapes] = useState(_shapes);
  const [correctTotal, setCorrectTotal] = useState(0);
  const [selected, setSelected] = useState({
    id: 0,
    value: 0,
    isCorrect: null,
  });

  const location = useLocation();
  useEffect(() => {
    setEnableNext(false);
  }, [location]);

  useEffect(() => {
    if (
      variation === pictogramVariations.variations.FILL_IN_THE_TOTAL ||
      variation === pictogramVariations.variations.SELECT_TALLY
    ) {
      const totalFromTallyOption =
        tallyOptions.find((to) => to.isCorrect)?.value ?? 0;
      const totalToSelect =
        variation === pictogramVariations.variations.FILL_IN_THE_TOTAL
          ? total
          : totalFromTallyOption;
      setShapes((prevShapes) =>
        getSelectedShapes({
          numberOfShapesToSelect: totalToSelect,
          shapes: prevShapes,
          keyValue,
        })
      );
    }
  }, [keyValue, tallyOptions, total, variation]);

  useEffect(() => {
    if (variation === pictogramVariations.variations.FILL_IN_SHAPES) {
      setCorrectTotal(total);
    }
  }, [total, variation]);

  // set enabled
  useEffect(() => {
    if (variation === pictogramVariations.variations.FILL_IN_SHAPES) {
      const isValidSelection = checkIsValidSelection({
        numberOfShapesToSelect: total,
        shapes,
        keyValue,
      });
      setEnableNext(isValidSelection);
    }
    if (
      variation === pictogramVariations.variations.FILL_IN_SHAPES_FROM_TALLY
    ) {
      const isValidSelection = checkIsValidSelection({
        numberOfShapesToSelect: tally,
        shapes,
        keyValue,
      });
      setEnableNext(isValidSelection);
    }
    if (variation === pictogramVariations.variations.FILL_IN_THE_TOTAL) {
      setEnableNext(total === correctTotal);
    }
    if (variation === pictogramVariations.variations.SELECT_TALLY) {
      setEnableNext(selected.isCorrect);
    }
  }, [
    correctTotal,
    keyValue,
    selected.isCorrect,
    shapes,
    tally,
    total,
    variation,
  ]);

  const onShapeClick = (shape) => {
    const newCircles = shapes.map((s) =>
      s.id === shape.id ? { ...s, isSelected: !s.isSelected } : s
    );

    setShapes(newCircles);
  };

  const ShapeComponent = getCorrectShape(shape);
  const isShapeDisabled =
    variation === pictogramVariations.variations.FILL_IN_THE_TOTAL ||
    variation === pictogramVariations.variations.SELECT_TALLY;

  return (
    <SharedStyles.Wrapper>
      <SharedStyles.ContentWrapper mx={7}>
        {!!textAudio && (
          <SharedStyles.TextAudioWrapper mt="2" mb="4">
            {textAudioUrl ? (
              <>
                <PlayButton
                  audioUrl={textAudioUrl}
                  width="56px"
                  height="56px"
                  iconProps={{ width: '32px', height: '32px' }}
                  fallbackText={textAudio}
                />
                <T.P ml={4} weight="regular" size="med">
                  {textAudio}
                </T.P>
              </>
            ) : (
              <T.P weight="regular" size="regular">
                {textAudio}
              </T.P>
            )}
          </SharedStyles.TextAudioWrapper>
        )}

        <S.KeyWrapper>
          <PlayButton
            width="56px"
            height="56px"
            iconProps={{ width: '32px', height: '32px' }}
            fallbackText={`Key: ${keyValue} ${keyName}`}
            variant="onlyIcon"
          />
          <T.P size="med">Key:</T.P>
          <ShapeComponent w="24px" h="24px" isSelected />
          <T.P size="med">=</T.P>
          <T.P size="med">{`${keyValue} ${keyName}`}</T.P>
        </S.KeyWrapper>

        <S.CirclesWrapper numberOfShapes={numberOfShapes}>
          {shapes.map((s) => (
            <ShapeComponent
              key={s.id}
              isSelected={s.isSelected}
              onClick={() => !isShapeDisabled && onShapeClick(s)}
              disabled={isShapeDisabled}
            />
          ))}
        </S.CirclesWrapper>

        {(variation === pictogramVariations.variations.FILL_IN_SHAPES ||
          variation === pictogramVariations.variations.FILL_IN_THE_TOTAL) && (
          <S.QuestionWrapper>
            <T.P size="large">Total</T.P>
            <T.P size="large">=</T.P>
            <SharedStyles.TypeInput
              id={`pictogram-answer`}
              name={`pictogram-answer`}
              type="number"
              aria-labelledby={`pictogram-answer`}
              value={correctTotal}
              onChange={(e) => setCorrectTotal(Number(e.target.value))}
              readOnly={
                variation === pictogramVariations.variations.FILL_IN_SHAPES
              }
              isFullWidth
            />
          </S.QuestionWrapper>
        )}
        {variation ===
          pictogramVariations.variations.FILL_IN_SHAPES_FROM_TALLY && (
          <S.QuestionWrapper flexDir="column" ai="start" jc="flex-start">
            <T.P size="large">Tally</T.P>
            <Tally count={tally} />
          </S.QuestionWrapper>
        )}

        {variation === pictogramVariations.variations.SELECT_TALLY && (
          <S.QuestionWrapper>
            <SharedStyles.OptionsWrapper applyNewStyle>
              {tallyOptions?.map((to, i) => (
                <S.TallyOption
                  key={to.id}
                  grid={tallyOptions.length > 3}
                  onClick={() => setSelected(to)}
                  selected={selected.value}
                  option={to.value}
                  variant="small"
                  mb={3}
                  p={4}
                >
                  <Tally
                    count={to.value}
                    bgColor={to.value === selected.value ? '#fff' : '#000'}
                  />
                </S.TallyOption>
              ))}
            </SharedStyles.OptionsWrapper>
          </S.QuestionWrapper>
        )}
      </SharedStyles.ContentWrapper>

      <StepFooterButtons
        stuckAtReview={stuckAtReview}
        handleNext={handleNext}
        preview={preview}
        disabled={disabled}
        enableNext={enableNext}
      />
    </SharedStyles.Wrapper>
  );
};

export default Pictogram;
