import { fields, createSchema, validate as _validate } from '..';

const { firstName, lastName, email, password, shouldBeTrue, requiredBoolean } =
  fields;

const defaultSchema = createSchema({
  firstName,
  lastName,
  email,
  password,
  giveConsent: shouldBeTrue,
  shareData: shouldBeTrue,
});

const prisonSchema = createSchema({
  firstName,
  lastName,
  password,
  giveConsent: shouldBeTrue,
  alreadyTrained: requiredBoolean,
});

const validate = (data) => {
  if (data.isWorkingInPrisonEnvironment) {
    return _validate(prisonSchema, data);
  }
  return _validate(defaultSchema, data);
};

export default validate;
