// Function to check if the user has accepted the necessary cookie categories
const initializeGoogleAnalytics = (ReactGA) => {
  if (window?.Cookiebot?.consent) {
    const consent = window.Cookiebot.consent;

    if (consent.statistics) {
      ReactGA.initialize(process.env.REACT_APP_ANALYTICS_ID);
      ReactGA.send({
        hitType: 'pageview',
        page: window.location.pathname + window.location.search,
      });
    }
  }
};

const googleAnalyticsInit = (ReactGA) => {
  try {
    window.addEventListener('CookiebotOnAccept', function () {
      initializeGoogleAnalytics(ReactGA);
    });
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error initializing Google Analytics:', e);
  }
};

export default googleAnalyticsInit;
