import { useCallback, useEffect, useState } from 'react';

import { PlayButton } from '../../../../components/Button';
import { useLocation } from 'react-router-dom';
import StepFooterButtons from '../StepFooterButtons';

import * as SharedStyles from '../shared/style';
import * as S from './style';
import { Markdown } from 'components';
import { createH1 } from 'utils/markdown';

const BarChart = ({
  data: {
    textAudio,
    textAudioUrl,
    topNumber,
    bottomNumbers: originalBottomNumbers = [],
    operation,
    correctAnswer: originalCorrectAnswer,
  } = {},
  handleNext,
  stuckAtReview,
  disabled,
  preview,
}) => {
  const [enableNext, setEnableNext] = useState(false);
  const [bottomNumbers, setBottomNumbers] = useState(
    originalBottomNumbers.map((n) => ({ ...n, value: undefined }))
  );
  const [correctAnswer, setCorrectAnswer] = useState();
  const location = useLocation();

  useEffect(() => {
    setEnableNext(false);
  }, [location]);

  useEffect(() => {
    if (bottomNumbers.length) {
      const allBottomNumbersCorrect = bottomNumbers.every(
        (n, i) => n.value === originalBottomNumbers[i].value
      );

      setEnableNext(
        allBottomNumbersCorrect && correctAnswer === originalCorrectAnswer
      );
    }
  }, [
    bottomNumbers,
    correctAnswer,
    originalBottomNumbers,
    originalCorrectAnswer,
  ]);

  const handleBottomNumbersChange = useCallback((value, numberId) => {
    setBottomNumbers((prev) =>
      prev.map((n) => {
        if (n.id !== numberId) return n;
        return { ...n, value };
      })
    );
  }, []);

  return (
    <SharedStyles.Wrapper>
      <SharedStyles.ContentWrapper mx={7}>
        {!!textAudio && (
          <SharedStyles.TextAudioWrapper mt="2" mb="4">
            {textAudioUrl ? (
              <>
                <PlayButton
                  audioUrl={textAudioUrl}
                  width="56px"
                  height="56px"
                  iconProps={{ width: '32px', height: '32px' }}
                  fallbackText={textAudio}
                />
                <Markdown
                  text={createH1(textAudio)}
                  customStyles={{
                    h1: {
                      size: 'regular',
                      weight: 'regular',
                      ml: 4,
                    },
                  }}
                />
              </>
            ) : (
              <Markdown
                text={createH1(textAudio)}
                customStyles={{
                  h1: {
                    size: 'regular',
                    weight: 'regular',
                    ml: 4,
                  },
                }}
              />
            )}
          </SharedStyles.TextAudioWrapper>
        )}

        <S.InputsWrapper>
          <SharedStyles.TypeInputBox
            id="top-number"
            name="top-number"
            type="text"
            aria-labelledby="top-number"
            showValue
            value={topNumber}
            disabled
          />

          <S.BottomNumbersWrapper>
            {bottomNumbers.map((n, numberIndex) => {
              return (
                <SharedStyles.TypeInputBox
                  key={`${n.id}-${numberIndex}`}
                  id={`answer-${n.id}`}
                  name={`answer-${n.id}`}
                  type="number"
                  aria-labelledby={`answer-${n.id}`}
                  showValue={false}
                  value={n.value}
                  onChange={(e) => {
                    handleBottomNumbersChange(Number(e.target.value), n.id);
                  }}
                  isCorrect={
                    bottomNumbers[numberIndex].value ===
                    originalBottomNumbers[numberIndex].value
                  }
                />
              );
            })}
          </S.BottomNumbersWrapper>
        </S.InputsWrapper>

        <S.OperationWrapper>
          {operation && (
            <Markdown
              text={operation}
              customStyles={{
                p: {
                  size: 'large',
                  weight: 'regular',
                  ml: 4,
                },
              }}
              w="100%"
            />
          )}

          <SharedStyles.TypeInput
            id={`correct-answer`}
            name={`correct-answer`}
            type="number"
            aria-labelledby={`correct-answer`}
            value={correctAnswer}
            onChange={(e) => setCorrectAnswer(Number(e.target.value))}
            isCorrect={correctAnswer === originalCorrectAnswer}
            isFullWidth
          />
        </S.OperationWrapper>
      </SharedStyles.ContentWrapper>

      <StepFooterButtons
        stuckAtReview={stuckAtReview}
        handleNext={handleNext}
        preview={preview}
        disabled={disabled}
        enableNext={enableNext}
      />
    </SharedStyles.Wrapper>
  );
};

export default BarChart;
