import styled from '@emotion/styled';
import setMargin from './../../helpers/set-margin';

export const Container = styled.div`
  ${setMargin};
  display: flex;
  align-items: center;
  width: ${({ needsRowFormat }) => (needsRowFormat ? '90%' : '150px')};
  height: auto;
`;

export const PlayButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ innerText }) => (innerText ? '100%' : '56px')};
  height: ${({ needsRowFormat }) => (needsRowFormat ? 'auto' : '56px')};
  background: ${({ theme }) => theme.colors.neutralLight};
  border: none;
  border-radius: 16px;
  padding: ${({ theme }) => `${theme.spacings[3]} ${theme.spacings[2]}`};
  box-shadow: ${({ theme }) => theme.shadows.elevation1};
  /* clicking style */
  :active {
    opacity: 0.9;
    transform: translateY(1px) scale(0.99);
  }

  /* for disabled style */
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  :hover {
    color: ${({ theme }) => theme.colors.primaryMain};
  }

  :active {
    color: ${({ theme }) => theme.colors.white};
    opacity: 0.9;
    background-color: ${({ theme }) => theme.colors.neutralMain};
  }

  :focus {
    box-shadow: inset 0px 0px 0px 2px rgba(5, 23, 48, 1);
    outline-style: solid;
    outline-width: 2px;
    outline-color: ${({ theme }) => theme.colors.primaryMain};
    outline-offset: 2px;
  }
`;

export const PlayText = styled.div`
  max-width: 100%;
  background: ${({ theme }) => theme.colors.neutralLight};
  height: ${({ needsRowFormat }) => (needsRowFormat ? 'auto' : '56px')};
  ${({ needsRowFormat }) => (needsRowFormat ? 'min-height: 56px;' : '')}
  padding: ${({ needsRowFormat, theme }) =>
    needsRowFormat && theme.spacings[2]};
  display: flex;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 16px;
  flex: 1;

  margin-left: ${({ theme }) => theme.spacings[4]};
`;

export const InnerPlayText = styled.div`
  margin-left: ${({ theme }) => theme.spacings[3]};
`;
