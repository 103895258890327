import styled from '@emotion/styled';

export const InputsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(2, auto);
  gap: 8px;
  & > * {
    max-width: 75px;
  }

  & input {
    width: 75;
    height: 75;
    padding-right: ${({ theme }) => theme.spacings[3]};
  }
`;
